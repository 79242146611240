/* eslint no-undef: "off"*/
import React, { PureComponent } from 'react';
import '../styles.scss';
import TncImage from '../tncTable.png';

export default class TermsConditionsComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <main className="dashboard-layout-content termsconditions__wrapper">
        <div className="wysinwyg-editor">
          <h1 className="text-center">UVNV Terms & Conditions</h1>
          <p>
            These Terms and Conditions (“T&amp;Cs”) are part of your service
            agreement with UVNV, Inc., including its affiliates, assigns, and
            agents (“Ultra Mobile,” “we,” “us, or “our”) and constitute a
            contract under which we provide you Service under terms and
            conditions that you accept. Your service agreement (“Agreement”)
            with us includes (i) these T&amp;Cs, (ii) our Privacy Policy, (iii)
            our Acceptable Use Policy, (iv) any subscriber agreement or
            transaction materials that you sign or accept, (v) the service
            plan(s) that you choose as set forth in our written services and
            transaction materials that we provide or refer you to during the
            sales transaction (if your service plan is not specifically set
            forth in any printed materials, the requirements and terms set forth
            in the current written services and transaction materials apply,
            excluding plan charges and number of minutes included in your
            service plan), (vi) any confirmation materials that we may provide
            to you, (vii) the terms set forth in any applicable coverage map
            brochures, and (viii) any other supplemental terms and conditions
            that we provide or otherwise make available to you.  In the event of
            a conflict between these T&amp;Cs and any other materials that
            makeup the Agreement, these T&amp;Cs shall govern to the extent
            necessary to resolve the conflict.
          </p>
          <p>
            “Fixed Wireless Internet Service” or “Fixed Wireless Internet”
            refers to a wireless high-speed broadband internet access service
            that offers you a capability for acquiring or retrieving information
            from; generating, storing, transforming, processing, or utilizing
            information on; or making available information to other Internet
            end points connected directly or indirectly via a fixed wireless
            connection to the Ultra Mobile network.”
          </p>
          <p>
            <strong>Please read these T&amp;Cs carefully.</strong> They cover
            important information about our services provided to you
            (“Service”); your approved modem or wireless router, SIM card, or
            other approved equipment or approved third party device used with
            our Service (“Device”); and any access and usage charges, taxes,
            fees, assessments, and other charges we bill you or that were
            accepted or processed through your Device (“Charges”).
            <strong>
              These T&amp;Cs include terms regarding service plan changes and
              late payments, limitations of liability, privacy and resolution of
              disputes by arbitration instead of in court.
            </strong>
          </p>
          <p>
            You and any authorized users on your account will have access to
            account information and may be able to make changes to the account.
            <strong>
              If you give your personal account validation information to
              someone, they can access and make changes to your account.
            </strong>
            Those changes will be binding on you. Authorized changes may require
            your agreement to new T&amp;Cs.
          </p>
          <ol>
            <li>
              <strong>
                YOUR AGREEMENT WITH ULTRA MOBILE STARTS WHEN YOU ACCEPT.
              </strong>
              You represent that you are at least eighteen (18) years old
              (twenty-one (21) years old or legally emancipated if you are a
              Puerto Rico customer) and you are legally authorized to enter into
              this Agreement. You accept your Agreement by doing any of the
              following: (a) giving us a written or electronic signature, or
              telling us orally that you accept; (b) activating Service; (c)
              using your Service after you make a change or addition; or (d)
              paying for the Service or an “Ultra Mobile Device” (a Device
              purchased from Ultra Mobile, an Ultra Mobile dealer, or other
              Ultra Mobile authorized retailer (“Dealer”)).&nbsp;
              <strong>
                IF YOU DO NOT WANT TO ACCEPT, DO NOT DO ANY OF THESE THINGS.
              </strong>
            </li>
            <li>
              Dispute Resolution and Arbitration. ULTRA MOBILE AND YOU EACH
              AGREE THAT, EXCEPT AS PROVIDED BELOW (AND EXCEPT AS TO PUERTO RICO
              CUSTOMERS), ANY AND ALL CLAIMS OR DISPUTES IN ANY WAY RELATED TO
              OR CONCERNING THE AGREEMENT, OUR SERVICE, MARKETING PRACTICES,
              DEVICES OR PRODUCTS, WILL BE RESOLVED BY BINDING ARBITRATION ON AN
              INDIVIDUAL, NON-REPRESENTATIVE, BASIS. This includes any claims
              against other parties relating to Service or Devices provided or
              billed to you (such as our suppliers, Dealers or third party
              vendors) whenever you also assert claims against us in the same
              proceeding. This also includes any claims brought after your
              service agreement with Ultra Mobile terminates. For the avoidance
              of doubt, claims under the Telephone Consumer Protection Act are
              subject to this Dispute Resolution and Arbitration Clause. Ultra
              Mobile and you each also agree that the Agreement affects
              interstate commerce so that the Federal Arbitration Act and
              federal arbitration law apply (despite the choice of law provision
              in Section 22). THERE IS NO JUDGE OR JURY IN ARBITRATION, AND
              COURT REVIEW OF AN ARBITRATION AWARD IS LIMITED. THE ARBITRATOR
              MUST FOLLOW THIS AGREEMENT AND CAN AWARD THE SAME DAMAGES AND
              RELIEF AS A COURT (INCLUDING ATTORNEYS’ FEES).
            </li>
          </ol>
          <p>
            For all disputes (except for Puerto Rico customers), you must first
            give us an opportunity to resolve your claim by sending a written
            description and supporting documentation of your claim to the
            address set forth in Section 12 below. Ultra Mobile and you each
            agree to negotiate your claim in good faith. If Ultra Mobile and you
            are unable to resolve the claim within sixty (60) days after Ultra
            Mobile receives your claim description and supporting documentation,
            you may pursue your claim in arbitration.
          </p>
          <p>
            As an alternative to arbitration, Ultra Mobile may choose to resolve
            billing disputes in small claims court in the county of your most
            recent billing address.  Ultra Mobile and you each agree that if you
            fail to timely pay amounts due, Ultra Mobile may assign your account
            for collection, and the collection agency may pursue, in small
            claims court, claims limited strictly to the collection of the past
            due amounts and any interest or cost of collection permitted by law
            or the Agreement.
          </p>
          <p>
            <strong>Either you or we may start arbitration proceedings.</strong>
            You must send a letter requesting arbitration and describing your
            claim to our registered agent (see Section 12) to begin arbitration.
            The American Arbitration Association (“AAA”) will arbitrate all
            disputes. For claims of less than Seventy-five Thousand Dollars
            ($75,000.00), the AAA’s Consumer Arbitration Rules will apply; for
            claims over Seventy-five Thousand Dollars ($75,000.00), the AAA’s
            Commercial Arbitration Rules will apply. The AAA rules are available
            at <u>www.adr.org</u>. Upon filing of the arbitration demand, Ultra
            Mobile and you will share equally all filing, administration and
            arbitrator fees for claims that total less than Seventy-five
            Thousand Dollars ($75,000.00). For claims that total more than
            Seventy-five Thousand Dollars ($75,000.00), the payment of filing,
            administration and arbitrator fees will be governed by the AAA
            Commercial Arbitration Rules. An arbitrator may award on an
            individual basis any relief that would be available in a court,
            including injunctive or declaratory relief and attorneys’ fees.
            <strong>Puerto Rico</strong> <strong>customers:</strong> Refer to
            Section 11 for details of the Puerto Rico Telecommunications Dispute
            Procedure.
          </p>
          <p>
            <strong>
              CLASS ACTION WAIVER. ULTRA MOBILE AND YOU EACH AGREE THAT ANY
              PROCEEDINGS, WHETHER IN ARBITRATION OR COURT, WILL BE CONDUCTED
              ONLY ON AN INDIVIDUAL BASIS AND NOT IN A CLASS OR REPRESENTATIVE
              ACTION OR AS A MEMBER IN A CLASS, CONSOLIDATED OR REPRESENTATIVE
              ACTION. Neither you, nor any other customer, can be a class
              representative, class member, or otherwise participate in a class,
              consolidated, or representative proceeding against Ultra Mobile.
            </strong>
          </p>
          <p>
            <strong>JURY TRIAL WAIVER.</strong> If a claim proceeds in court
            rather than through arbitration,
            <strong>
              ULTRA MOBILE AND YOU EACH WAIVE ANY RIGHT TO A JURY TRIAL.
            </strong>
          </p>
          <ol start="3">
            <li>
              <strong>
                No Refunds for Your Cancellation of Service Plans.
              </strong>
              You may cancel any Service plan that you pay for in advance at any
              time; provided that, except as otherwise required by applicable
              law, you will not receive any refund of amounts paid to us in
              connection with such Service plan (regardless of whether such plan
              is a 30 day Service plan or a multi-month Service plan).
            </li>
            <li>
              <strong>Our Rights to Make Changes.</strong>This provision is
              subject to requirements and limitations imposed by applicable law,
              and will not be enforced to the extent prohibited by law. Your
              Service is subject to our business policies, practices, and
              procedures, which we can change without notice.
              <strong>
                WE RESERVE THE RIGHT TO CHANGE OR MODIFY THESE T&amp;Cs, OUR
                SERVICES, DISCOUNTS, TECHNOLOGIES (INCLUDING CHANGES TO OUR
                NETWORK THAT MAY IMPACT YOUR DEVICE’S COMPATIBILITY), COVERAGE,
                OR ANY OTHER TERMS IN THE AGREEMENT AT ANY TIME AND YOU AGREE TO
                BE BOUND BY ALL SUCH CHANGES OR MODIFICATIONS ON THE EARLIER OF
                WHEN WE (i) POST THEM ON OUR INTERNET WEBSITE, OR (ii) SEND THEM
                TO YOU (ELECTRONICALLY OR OTHERWISE). BECAUSE THESE T&amp;Cs ARE
                SUBJECT TO CHANGE AT ANY TIME, YOU SHOULD ALWAYS CHECK OUR
                WEBSITE FOR THE MOST CURRENT VERSION. IF WE CHANGE THE SERVICE
                FEE APPLICABLE TO YOUR SERVICE PLAN, YOU WILL HAVE 5 DAYS FROM
                THE DATE WE POST OR NOTIFY YOU OF THE REVISED SERVICE FEE TO
                TERMINATE YOUR SERVICE PLAN BY (i) contacting our customer
                service department at legal@ultra.me, (ii) calling (888)
                777-0446, or (iii) managing your account preferences on the
                Ultra Mobile website.  IF YOU FAIL TO CANCEL YOUR SERVICE PLAN
                WITHIN THE 5 DAY PERIOD, YOU WILL BE DEEMED TO HAVE ACCEPTED THE
                RATE INCREASE.  ANY INCREASE IN TAXES, REGULATORY FEES OR
                ASSESSMENTS WILL NOT BE CONSIDERED AN INCREASE IN ANY SERVICE
                FEE AND WILL NOT ENTITLE YOU TO EXERCISE THE FOREGOING
                CANCELLATION RIGHT. WE ARE NOT LIABLE TO YOU FOR CHANGES IN
                OPERATION, EQUIPMENT, OR TECHNOLOGY (INCLUDING THE NETWORK) THAT
                CAUSE YOUR DEVICE TO BE RENDERED OBSOLETE OR REQUIRE
                MODIFICATION.
              </strong>
            </li>
            <li>
              <strong>Compatible Devices.</strong>You must use a supported,
              compatible Device to access the Ultra Mobile network Only certain
              Devices are supported. Additionally, your ability to connect to
              the Ultra Mobile network through a connecting device (e.g., phone,
              tablet, computer, TV, or other device) requires the use of a
              supported, compatible connecting device within an appropriate
              distance of the Device. Please be advised that wireless routers
              are not compatible with analog systems. Ultra Mobile will provide
              you with a list of approved supported Devices upon your request.
              Modems, bypass equipment, gateways, automated relay devices and
              any other devices (including Devices) used for commercial or
              re-direction purposes are not supported Devices and violate our
              Acceptable Use Policy. Failure to use a supported, compatible
              Device when accessing our network will result in immediate
              termination of your Service.
            </li>
            <li>
              <strong>Service Availability.</strong>Coverage maps only
              approximate our anticipated wireless coverage area outdoors;
              actual Service area, coverage and quality may vary and change
              without notice depending on a variety of factors including network
              capacity, terrain and weather. Outages and interruptions in
              Service may occur, and speed of Service varies. You agree we are
              not liable for problems relating to Service availability or
              quality.
            </li>
            <li>
              <strong>Important Emergency and 9-</strong>1-1 Information. The
              Service provides only broadband Internet access and is neither
              required to provide, nor provides, 911 calling services.  We shall
              not be responsible or liable for any voice application or service,
              including without limitation such application’s or service’s
              provision of 911 calling, unless such application or service is
              provided by Ultra Mobile and Ultra Mobile agrees to such
              responsibility and liability in writing.
            </li>
            <li>
              <strong>Service Plans.</strong>
            </li>
          </ol>
          <p>
            8.1 <em>General. </em>Your Service will operate only after you have
            purchased and redeemed an Ultra Mobile Service plan.  Please visit
            our website at www.ultramobile.com for the latest information
            regarding our Service plans.
          </p>
          <p>
            8.2 <em>Charges. </em>
            <strong>
              You agree to pay all charges we bill you or that were accepted or
              processed through your Device or on such other Devices connected
              with your account and as designated in your Service plan.
            </strong>
            For disputed charges, see Section 11. You agree to provide us with
            accurate and complete billing information and to immediately report
            to us all changes to your billing information.
          </p>
          <p>
            8.3 <em>Data Usage.</em> Ultra Mobile Service plans include a data
            plan (“Data Plan”) as part of your Service.  Data usage is rounded
            up to the next full-kilobyte increment at the end of each data
            session.    Your data usage includes, among other things, text, web
            browsing, instant or picture messages, and email whether read or
            unread, sent or received, solicited or unsolicited, while using a
            connecting device to connect to the wireless network on your Device.
            We use filters to block spam messages, but we do not guarantee that
            you will not receive spam or other unsolicited messages, and we are
            not liable for such messages.  All data purchased with an Ultra
            Mobile Service plan (both data that comes with a particular Ultra
            Mobile Service plan and any additional data you purchase as an
            add-on), will expire immediately upon termination of your Ultra
            Mobile Service plan for any reason.
          </p>
          <p>
            8.4 <em>Permissible and Prohibited Uses.</em> Your Data Plan is
            intended for Web browsing, messaging, and similar activities while
            connected to the wireless network on your Device at the Device
            location (physical address) you provided to us when signing up for
            your Data Plan. You are prohibited from attempting to connect to the
            Device’s wireless network, at any time, in any other location than
            the Device location (physical location) you provided to us when
            signing up for your Data Plan. If you move or relocate, you must
            provide us with five (5) days’ notice before attempting to connect
            to the Device at the new Device location. We may suspend or
            terminate service if you do not provide us with advance notice of a
            new Device location, or if you otherwise attempt to connect to the
            Device in any other physical location. We reserve the right to
            suspend or terminate service based on any relocation (including any
            requested or attempted relocation) of the Device, including but not
            limited to suspension or termination for excessive location changes
            to the Device. Additional examples of prohibited uses can be found
            in Section 13 of these T&amp;Cs and in our Acceptable Use Policy.
          </p>
          <p>
            8.5 <em>Data Usage Level. </em>Ultra Mobile has set a 30-day 5G
            and/or 4G LTE data usage level (“Usage Level”) for each of its
            Service plans.   Our network supplier measures your upload and
            download data usage (“Actual Usage”) to determine if your total
            Actual Usage, as aggregated over the applicable 30-day period
            (“Usage Total”), exceeds the 30-day Usage Level for the Service plan
            you selected. Partial megabytes are rounded up. Please note that
            “Actual Usage” includes all of your requests to upload or download
            data, whether or not such data is actually uploaded or downloaded,
            as well as network overhead. For example, if you request that an
            image be downloaded while using a connecting device to connect to
            the wireless network on your Device, but travel outside of your
            network coverage area before the download is complete, such request
            will be included in your Actual Usage even though the image was
            never downloaded. If you exceed your Service plan’s 30-day Usage
            Level, Ultra Mobile will reduce the speed at which you can send and
            receive data over the Ultra Mobile network until the end of the
            applicable 30-day period. The initial reduction in your data speed
            depends upon your Service plan and can be found in our FAQs, but in
            no instances will be faster than 128 kbps. Ultra Mobile may also
            reduce speeds during times of network congestion. If you continue to
            send and/or receive a substantial amount of data (as determined by
            Ultra Mobile in its sole discretion) after our initial reduction of
            your data speed, we may further reduce your data speed.  Once you
            begin a new 30-day period in your Service plan, your Service plan
            Usage Level will be reset and your upload and download speeds will
            be restored. For example, if a customer purchases a Service plan
            that includes up to 1 gigabyte of high speed data at 5G and/or 4G
            LTE speeds during a given 30-day period, then the first gigabyte of
            data requested by that customer in that 30-day period will be
            provided at up to 5G and/or 4G LTE speeds (subject to 5G and 4G LTE
            network availability, a capable device and SIM within an appropriate
            coverage area, and the actual network speeds of Ultra Mobile’s
            network supplier).  Any data uploaded or downloaded by that customer
            in excess of 1 gigabyte will be provided at reduced speeds at a max
            of 128 kbps. Please be aware that your Usage Level is much more
            likely to exceed your Service plan’s 30-day Usage Level if you use
            streaming video, or if you download significant quantities of music
            files, movies, software applications, or engage in other
            high-bandwidth activities.
          </p>
          <p>
            8.6 <em>Protective Measures.</em> To provide a good experience for
            the majority of our customers and minimize capacity issues and
            degradation in network performance, we may take measures including
            temporarily reducing data throughput for a subset of customers who
            use a disproportionate amount of bandwidth. If you use your Data
            Plan in a manner that could interfere with other customers’ service,
            affect our ability to allocate network capacity among customers, or
            degrade service quality for other customers, we may suspend,
            terminate, or restrict your data session, or switch you to a more
            appropriate Data Plan which may result in an increased cost. We also
            manage our network to facilitate the proper functioning of services
            that require consistent high speeds, such as video calling, which
            may, particularly at times and in areas of network congestion,
            result in reduced speeds for other services. Additionally, we may
            implement other network management practices, such as caching less
            data, using less capacity, sizing video more appropriately for a
            Device to transmit data files more efficiently, and deploying
            streaming video optimization technology, which may affect the
            performance and download times of data-heavy activities such as
            video-streaming on our unlimited plans. Streaming video optimization
            technology is intended to manage data usage on the network, reduce
            the risk of streaming video stalling and buffering, and reduce the
            amount of high-speed data consumption used for streaming video.
            Streaming video optimization improves streaming video reliability as
            well as makes room for other users to enjoy higher browsing speeds.
            Detectable video may typically stream at DVD quality or Standard
            Definition. The streaming video optimization process is agnostic as
            to the streaming video content itself and to the website that
            provides it. While most changes to streaming video files are likely
            to be indiscernible, the optimization process may minimally impact
            the appearance of the streaming video as displayed on your Device.
            These practices operate without regard to the content itself or the
            source of the content, and do not discriminate against offerings
            that might compete against those offered by us on the basis of such
            competition.
          </p>
          <p>
            8.7 <em>Downloadable Content and Applications.</em> Content or
            applications (e.g., downloadable or networked applications, and
            productivity tools) (collectively, “Content &amp; Apps”) that you
            can purchase with your connecting device (e.g., television, laptop,
            tablet, phone) while connected to the Device are not sold by Ultra
            Mobile. Ultra Mobile is not responsible for the Content &amp; Apps,
            including download, installation, use, transmission failure,
            interruption, or delay, or any content or website you may be able to
            access through the Content &amp; Apps. Unless otherwise stated, any
            support questions for these Content &amp; Apps may be directed to
            the third party seller. When you use, download or install Content
            &amp; Apps sold by a third party seller, you may be subject to
            license terms, terms of use, a privacy policy and/or other policies
            between you and that third party. Content &amp; Apps you purchase
            from third parties are licensed for personal, lawful, non-commercial
            use on your Device only. You may not transfer, copy, or reverse
            engineer any Content &amp; Apps, or alter, disable or circumvent any
            digital rights management security features embedded in the Content
            &amp; Apps. Content &amp; Apps may not be transferable from one
            Device to another Device. Some Devices may continue to have contact
            with our network without your knowledge, which may result in
            additional charges Software on your Device may automatically shut
            down or limit the use of Content &amp; Apps or other features or
            Services without warning. Ultra Mobile is not responsible for any
            third party content, advertisements, or websites you may be able to
            access using your Device.
          </p>
          <p>
            8.8 <em>Use of Information.</em> If you visit any third party
            website or app store, or download or use any Content &amp; Apps, the
            third party may access, collect, use or disclose your personal
            information or require the network carrier to disclose your
            information, including location information (when applicable) to the
            Content &amp; Apps provider or some other third party.  If you
            access or use any Content &amp; Apps through Ultra Mobile’s Service,
            you agree and authorize Ultra Mobile and its network carrier to
            provide information related to such use. You understand that your
            use of a third party app is subject to the third party’s terms and
            conditions and policies, including its privacy policy. Please refer
            to the Content &amp; Apps creator/owner’s privacy policy for
            information regarding their use of information collected when you
            download, install, or use any third party Content &amp; Apps. We are
            not responsible for any transmission failure, interruption, or delay
            related to Content &amp; Apps, or any content or website you may be
            able to access through the Content &amp; Apps.
          </p>
          <p>
            8.9 <em>Suspension and Cancellation</em>. If your account is
            deactivated due to nonpayment, your account will be in a suspended
            status for 60 days. If your account is not reactivated within the
            60-day period, it will be cancelled. If your account is cancelled,
            your remaining balance will be forfeited.
          </p>
          <ol start="9">
            <li>
              <strong>Taxes, Fees and Surcharges.</strong>You are responsible
              for, and shall pay, the taxes, fees and surcharges set forth in
              Sections 10.1 – 10.5 below (“Taxes, Fees and Surcharges”) in
              connection with your Service account. Such Taxes, Fees and
              Surcharges will be included in your Service account charges (a) at
              the time you order the Service and (b) each time there are any
              further charges to your Service account. Payment of Taxes, Fees
              and Surcharges is in addition to payment for the Service and will
              be billed to your credit card.
            </li>
          </ol>
          <p>
            <em>Sales and Use Taxes.</em> Sales and use taxes are assessed to
            allow Ultra Mobile to recover the cost of all federal, state,
            municipal, local or other governmental sales and use taxes now in
            force or enacted in the future, that arise as a result of your
            subscription to, use of, or payment for Ultra Mobile Service. To
            determine sales and use taxes, we will use the street address you
            identified as your Place of Primary Use (“PPU”). The PPU for Puerto
            Rico must be in Puerto Rico. If you did not identify the correct
            PPU, or if you provided an address (such as a PO box) that is not a
            recognized street address, does not identify the applicable taxing
            jurisdictions or does not reflect the Service area associated with
            your telephone number, you may be assigned a default location for
            tax purposes. In the event of a disputed tax jurisdiction location
            being assigned, any tax refund must be requested within sixty (60)
            days of our notification to you that the tax has been assessed.
          </p>
          <ol start="10">
            <li>
              <strong> Your Right to Dispute Charges.</strong>
            </li>
          </ol>
          <p>
            10.1 Unless otherwise provided by law, you agree to notify us of any
            dispute regarding your bill or charges to your account within sixty
            (60) days (twenty (20) days for Puerto Rico customers) after the
            date you first receive the disputed bill or charge. If you do not
            notify us of your dispute in writing with supporting documentation
            within this time period, you may not pursue a claim in arbitration
            or in court. Except for Puerto Rico customers and unless otherwise
            provided by law, you must pay disputed charges until the dispute is
            resolved. If you accept a credit, refund or other compensation or
            benefit to resolve a disputed bill or charge, you agree that the
            issue is fully and finally resolved. For unresolved disputes see
            Section 2 above. To contact or notify us, see Section 12. This
            paragraph is notice to <strong>Puerto Rico</strong> customers that
            payment of undisputed charges is due when stated on your bill,
            regardless of any dispute.
          </p>
          <p>
            10.2 <em>Puerto Rico Customers.</em> We will provide you with a
            determination regarding any dispute that is presented to us in
            accordance with this Section 11 within fifteen (15) days after we
            receive it. You may appeal our determination to the
            Telecommunications Board of the Commonwealth of Puerto Rico
            (“Telecommunications Board”) by filing a petition for review up to
            thirty (30) days after the date of our determination. Your petition
            for review shall be made through the filing of a document containing
            the following information: (i) your name and address; (ii) our
            company name; (iii) the pertinent facts; (iv) any applicable legal
            provisions that you are aware of; and (v) the remedy you are
            requesting. The document may be filed handwritten or typewritten and
            must be signed by you. You must send us a copy of your document to
            the following address: UVNV, Inc., 1550 Scenic Avenue, Suite 100,
            Costa Mesa, California 92626, Attn: Customer Service. You must send
            your petition for review to the Telecommunications Board at the
            following address: 500 Ave. Roberto H. Todd (Pda. 18 – Santurce),
            San Juan, Puerto Rico 00907-3941. The Telecommunications Board will
            review our determination only on appeal. You are advised of the
            provisions regarding suspension of Service that appear in Law 33 of
            July 7, 1985, Law 213 of September 12, 1996 and Regulation 5940
            promulgated by the Telecommunications Board. You are also advised of
            Regulation 5939 of March 12, 1999 promulgated by the
            Telecommunications Board regarding the procedures for resolution of
            customer disputes.
          </p>
          <ol start="11">
            <li>
              <strong> Notices and Customer Communications.</strong>
            </li>
          </ol>
          <p>
            11.1 <em>Receipt of Communications.</em>  You expressly consent to
            receive all communications, agreements, documents, notices and
            disclosures (“Notices”) from us, at the telephone number you
            provided to us, or the physical or electronic address you provide to
            us. Notices from us to you are considered delivered when we deliver
            them to the phone number you provided us, by text message or voice,
            or by email to any email address you provide to us, or three (3)
            days after mailing to your billing address.
          </p>
          <p>
            11.2 <em>Autodialed or Prerecorded Messages. </em>We may at times
            contact you using autodialed or prerecorded message calls or text
            messages at the telephone number you provided to us. We may place
            such calls or texts to (i) provide notices regarding your Ultra
            Mobile account or account activity, (ii) investigate or prevent
            fraud, (iii) inform you about products or services that may be of
            interest to you, or (iv) collect a debt owed to us. You agree that
            we and our service providers may contact you using autodialed or
            prerecorded message calls and text messages to carry out the
            purposes we have identified above. We may also share your phone
            number with service providers with whom we contract to assist us in
            pursuing these interests. Standard telephone minute and text charges
            may apply.
          </p>
          <p>
            11.3 <em>Revocation of Consent.</em>  You do not have to consent to
            receive autodialed or prerecorded message calls or texts in order to
            use Ultra Mobile Service. Where Ultra Mobile is required to obtain
            your consent for such communications, you may choose to revoke your
            consent by any reasonable means, including by (i) contacting our
            customer service department at legal@ultra.me     , (ii) calling
            (888) 777-0446, (iii) writing to: UVNV, Inc., 1550 Scenic Avenue,
            Suite 100, Costa Mesa, California 92626, Attn: Customer Service, or
            (iv) managing your account preferences on the Ultra Mobile website.
            Written notices from you to us are considered delivered when you
            send an email or three (3) days after mailing to the address above.
          </p>
          <p>
            To begin arbitration or other legal proceeding, you must serve our
            registered agent. Our registered agent is UVNV, Inc., and can be
            contacted at <a href="mailto:legal@ultra.me">legal@ultra.me</a> or
            by telephone at (888) 777-0446.
          </p>
          <ol start="12">
            <li>
              <strong> Lost or Stolen Devices.</strong>You agree to notify us if
              your Device is lost or stolen. Once you notify us, we will suspend
              your Service. After your Service is suspended, you will not be
              responsible for additional usage charges incurred in excess of
              your Service plan charges, and applicable Taxes and Surcharges. If
              you request that we not suspend your Service, you will remain
              responsible for all usage and charges incurred and applicable
              Taxes and Surcharges. We may prevent a lost or stolen Device from
              registering on any network.
              <strong>
                <u>California customers</u>:
              </strong>
              For charges incurred before you notify us, you are not liable for
              charges you did not authorize, but the fact that your Device or
              account was used is some evidence of authorization. You may
              request that we investigate charges you believe were unauthorized.
              We may ask you to provide information and you may submit
              information to support your request. If we determine the charges
              were unauthorized, we will credit your account. If we determine
              the charges were authorized, we will inform you within thirty (30)
              days and you will remain responsible for the charges.
            </li>
            <li>
              <strong> Misuse of Service or Device.</strong>By activating or
              renewing Service with Ultra Mobile, you agree that you do so
              because you want Service from Ultra Mobile and not for any other
              purposes. Ultra Mobile Service plans may only be used for Internet
              browsing and ordinary content downloads or related activities (and
              your use of Ultra Mobile Service must be initiated from your
              supported, compatible Device), only while connecting to the Device
              at the Device location (physical address) you provided to us while
              signing up for your Data Plan. You agree not to use (or attempt to
              use) any portion of the Device with any <em>other </em>device. You
              further agree not to misuse the Service or Device, including but
              not limited to: (a) reselling or rebilling our Service; (b) using
              the Service or Device to engage in unlawful activity, or in
              conduct that adversely affects our customers, employees, business,
              or any other person(s), or that interferes with our operations,
              network, reputation, or ability to provide quality service,
              including but not limited to the generation or dissemination of
              viruses, malware or “denial of service” attacks; ; ((e) tampering
              with or modifying your Device; (f) “spamming” or engaging in other
              abusive or unsolicited communications; (g) reselling Devices for
              profit, or tampering with, reprogramming or altering Devices for
              the purpose of reselling the Device; (i) accessing, or attempting
              to access without authority, the information, accounts or devices
              of others, or to penetrate, or attempt to penetrate, Ultra
              Mobile’s or another entity’s network or systems; (k) connecting,
              or attempting to connect, to the Device, at any time, in any other
              location than the Device location (physical address) you provided
              to us when signing up for your Data Plan (or at a new Device
              location of which you provided us 5 days’ advance notice before
              attempting to connect to the Device) or (l) assisting or
              facilitating anyone else in any of the above activities.
            </li>
            <li>
              <strong>
                Our Rights to Limit or End Service or the Agreement.
              </strong>
            </li>
          </ol>
          <p>
            14.1 WE MAY LIMIT, THROTTLE, SUSPEND OR TERMINATE YOUR SERVICE OR
            AGREEMENT WITHOUT NOTICE AT ANY TIME AND FOR ANY REASON, INCLUDING,
            WITHOUT LIMITATION, if you, any user of your Device, or any user on
            your account: (a) breaches the Agreement; (b) transfer(s) Service to
            another person without our consent; (c) becomes insolvent, goes
            bankrupt or threatens bankruptcy (except as prohibited by law); (d)
            misuses your Service or Device as described in Section 14 above; (e)
            uses your Service or Device in a manner that is excessive, unusually
            burdensome, or unprofitable to us; or (f) are on a Service plan that
            we determine is no longer available to you. We may impose credit,
            usage or other limits to your Service, suspend your Service, or
            block certain types of calls, messages or sessions (such as
            international, 10XXX, 0+/0-, chat services, 900 or 976 calls), in
            our sole discretion and without notice. “Unlimited data”
            single-month and multi-month plan subscribers will experience
            reduced speeds and increased latency after using 40GB of data within
            a given monthly period.
          </p>
          <p>
            Ultra Mobile reserves the right to cancel or deactivate Service or
            reduce data throughput speeds in order to protect its supplier’s
            network from harm or to protect itself from financial or other harm
            due to any cause including, without limitation, the excessive and/or
            unauthorized use of Ultra Mobile Service. Ultra Mobile reserves the
            right to limit throughput or the amount of data transferred and to
            deny or terminate Service to anyone Ultra Mobile believes is using
            their Service in an unauthorized manner or whose usage, in Ultra
            Mobile’s sole discretion, adversely impacts its supplier’s network
            or customer service levels. Ultra Mobile will presume you are
            engaging in an unauthorized use in violation of these T&amp;Cs, if
            in Ultra Mobile’s sole discretion, you are using an abnormally high
            volume of data or your data usage is otherwise harmful or disruptive
            to Ultra Mobile’s supplier’s network or service levels or to Ultra
            Mobile’s financial or other interests. If we determine, in our sole
            discretion, that you are using your Service in violation of these
            T&amp;Cs or in any other manner that we deem to be unreasonable or
            excessive, we may terminate individual calls or data connections,
            terminate or reduce data throughput or terminate your Service,
            decline to renew your Service, or offer you a different Service plan
            without an unlimited usage component which may result in an
            increased cost to you.
          </p>
          <p>
            Ultra Mobile may discontinue providing Service to you, discontinue
            your account, terminate data connections and/or reduce data
            throughput speeds if your usage, in the sole judgment of Ultra
            Mobile: (i) appears likely to generate abnormally high call volumes
            or data usage and/or abnormally long average call lengths or data
            usage as compared to the usage of other Ultra Mobile customers; or
            (ii) may be harmful or disruptive to, or interfere with, Ultra
            Mobile’s supplier’s network, Ultra Mobile’s service or the ability
            to provide quality service to other customers. By initiating Service
            and placing or receiving calls, you acknowledge and agree to Ultra
            Mobile’s right to terminate your Service under these circumstances.
          </p>
          <p>
            If we limit, suspend or terminate your Service and later reinstate
            your Service, you may be charged a reinstatement fee.
          </p>
          <p>
            This paragraph constitutes notice to <strong>Puerto Rico</strong>
            customers that your Service may be suspended or cancelled if you
            engage in any of the foregoing actions in Sections 14 and 15 in
            accordance with the Puerto Rico Suspension Regulation 5940 of March
            12, 1999, promulgated by the Telecommunications Board where
            applicable, or in Ultra Mobile’s sole discretion. If your Service or
            account is limited, suspended or terminated and then reinstated, you
            may be charged a reactivation fee.
          </p>
          <p>
            14.2 <em>Refund Policy.  </em>If you have purchased one of our
            30-day Service plans and we terminate or suspend your Service for
            any reason specified in Section 15.1 or otherwise, you will not
            receive any refund of amounts paid to us in connection with such
            Service plan.  If you have purchased one of our pre-paid,
            multi-month Service plans and we suspend or terminate your Service
            for any reason specified in Section 15.1 or otherwise, we will
            refund a pro rata portion of your Service fee for any then-remaining
            30-day Service period(s) within your Service plan, not including the
            30-day Service period in which we suspend or terminate your Service.
          </p>
          <p>
            14.3 <em>Device Refunds and Restocking Fees.</em> For Ultra
            Mobile-provided Devices, SIM Kits and accessory returns and
            exchanges, see the applicable return policy, which is available in
            the Device, SIM Kit or accessory box or packaging. Some Ultra
            Mobile-provided Devices and accessories may not be refunded or
            exchanged, and you may be required to pay a restocking fee.  Devices
            offered to Ultra Mobile customers, if any, will comply with the
            FCC’s regulations concerning hearing aid compatability.
          </p>
          <p>
            14.4 <em>No Future Service.  </em>In the event Ultra Mobile
            terminates your Service for any reason specified in Section 15.1 or
            otherwise, we reserve the right to prohibit you from using  any
            Ultra Mobile Service plan in the future by blocking your device from
            our network, prohibiting you from using a new Ultra Mobile Service
            account that you purchase, or through other means.
          </p>
          <ol start="15">
            <li>
              <strong> Intellectual Property.</strong>You agree not to infringe,
              misappropriate, dilute or otherwise violate the intellectual
              property rights of Ultra Mobile or any third party. Except for a
              limited license to use the Services, your purchase of Services and
              Ultra Mobile Devices does not grant you any license to copy,
              modify, reverse engineer, download, redistribute, or resell the
              intellectual property of Ultra Mobile or others related to the
              Services and Ultra Mobile Devices; this intellectual property may
              be used only with Ultra Mobile Service unless expressly authorized
              by Ultra Mobile. You agree that a violation of this section harms
              Ultra Mobile, which cannot be fully redressed by money damages,
              and that Ultra Mobile shall be entitled to seek immediate
              injunctive relief in addition to all other remedies available.
            </li>
            <li>
              <strong> Privacy Information.</strong>Our Privacy Policy governs
              how we collect and use information related to your use of our
              Service and is available online at
              www.ultramobile.com/privacy-policy. We may change our Privacy
              Policy without notice; however, if we change our policy to allow
              use or disclosure of personal information in a way that, in our
              sole determination, is materially different from that stated in
              the policy at the time the data was collected, we will post notice
              in advance of the change. Data on your Device may automatically be
              stored on your SIM card, Device or our network. Your data may
              remain on the Device even if your SIM card is removed; the data
              left on your Device will be accessible to others who use your
              Device, and may be deleted, altered, or transferred to our or our
              supplier’s network servers.
            </li>
            <li>
              <strong> Disclaimer of Warranties.</strong>EXCEPT TO THE EXTENT
              OTHERWISE EXPRESSLY PROVIDED IN WRITING, AND TO THE EXTENT
              PERMITTED BY LAW, THE SERVICES AND DEVICES ARE PROVIDED ON AN “AS
              IS,” “AS AVAILABLE” AND “WITH ALL FAULTS” BASIS AND WITHOUT
              WARRANTIES OF ANY KIND. WE MAKE NO REPRESENTATIONS OR WARRANTIES,
              EXPRESS OR IMPLIED, INCLUDING ANY IMPLIED WARRANTY OF
              MERCHANTABILITY, NON-INFRINGEMENT OF THE RIGHTS OF THIRD PARTIES,
              OR FITNESS FOR A PARTICULAR PURPOSE CONCERNING YOUR SERVICE OR
              YOUR DEVICE. WE DO NOT PROMISE UNINTERRUPTED OR ERROR-FREE SERVICE
              AND WE DO NOT AUTHORIZE ANYONE TO MAKE ANY REPRESENTATIONS OR
              WARRANTIES ON OUR BEHALF. WE DO NOT GUARANTEE THAT YOUR
              COMMUNICATIONS WILL BE PRIVATE OR SECURE.
            </li>
            <li>
              <strong>
                Waivers and Limitations of Liability. UNLESS PROHIBITED BY LAW,
                OUR LIABILITY FOR DAMAGES OR OTHER MONETARY RELIEF FOR ANY
                CLAIMS YOU MAY HAVE AGAINST US IS STRICTLY LIMITED TO NO MORE
                THAN THE AMOUNTS ACTUALLY PAID BY YOU TO US FOR THE SERVICE FROM
                WHICH THE DAMAGES OR OTHER LIABILITY AROSE IN THE THREE (3)
                MONTHS IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO THE CLAIM.
                YOU EXPRESSLY AGREE THAT UNDER NO CIRCUMSTANCES ARE WE LIABLE
                FOR ANY INDIRECT, SPECIAL, CONSEQUENTIAL, TREBLE, EXEMPLARY OR
                PUNITIVE DAMAGES ARISING OUT OF OUR SERVICE (INCLUDING THE
                PROVISION OF OR FAILURE TO PROVIDE SAME), ANY DEVICE, OR
                OTHERWISE IN CONNECTION WITH THIS AGREEMENT OR THE SUBJECT
                MATTER HEREOF, REGARDLESS OF THE FORM OF ACTION AND WHETHER OR
                NOT WE HAVE BEEN INFORMED OF, OR OTHERWISE MIGHT HAVE
                ANTICIPATED, THE POSSIBILITY OF SUCH DAMAGES. THIS LIMITATION
                AND WAIVER ALSO APPLIES TO ANY CLAIMS YOU MAY BRING AGAINST ANY
                OTHER PARTY TO THE EXTENT THAT WE WOULD BE REQUIRED TO INDEMNIFY
                THAT PARTY FOR SUCH CLAIM. YOU AGREE WE ARE NOT LIABLE FOR
                PROBLEMS CAUSED BY YOU OR A THIRD PARTY. EXCEPT TO THE EXTENT
                PROHIBITED BY LAW, ALL CLAIMS MUST BE BROUGHT WITHIN TWO (2)
                YEARS OF THE DATE THE CLAIM ARISES.
              </strong>
            </li>
            <li>
              <strong> Indemnification.</strong>You agree to defend, indemnify,
              and hold us harmless from and against any and all losses, claims,
              liabilities, costs and expenses (including taxes, fees, fines,
              penalties, interest, expenses of investigation and attorneys’ fees
              and disbursements) as incurred, arising out of or relating to use
              of the Service or Devices, breach of the Agreement, or violation
              of any laws or regulations or the rights of any third party by you
              or any person on your account or that you allow to use your
              Service or Device.
            </li>
            <li>
              <strong> Enforceability and Assignment.</strong>A waiver of any
              part of the Agreement in one instance is not a waiver of any other
              part or any other instance and must be expressly provided in
              writing. If we do not enforce our rights under any provisions of
              the Agreement, we may still require strict compliance in the
              future. Except as provided in Section 2, if any part of the
              Agreement is held invalid that part may be severed from the
              Agreement. You cannot assign the Agreement or any of your rights
              or duties under it without our written consent. We may assign all
              or part of the Agreement or your debts to us without notice. The
              Agreement is the entire agreement between Ultra Mobile and you and
              defines all of the rights you have with respect to your Service or
              Ultra Mobile Device, except as provided by law, and you cannot
              rely on any other documents or statements by any sales or service
              representatives or other agents. If you purchase a Device,
              services or content from a third party, you may have a separate
              agreement with the third party; Ultra Mobile is not a party to
              that agreement. Any determination made by us pursuant to this
              Agreement, shall be in our sole reasonable discretion. Sections
              2-3, 5, 8-14, and 16-22 of this Agreement continue after
              termination of our Agreement with you.
            </li>
            <li>
              <strong> Choice of Law.</strong>This Agreement is governed by the
              Federal Arbitration Act, applicable federal law, and the laws of
              the state in which your billing address in our records is located,
              without regard to the conflicts of laws rules of that state.
              Foreign laws (except for Puerto Rico) do not apply. Arbitration or
              court proceedings must be in: (a) the county and state in which
              your billing address in our records is located, but not outside
              the U.S.; or (b) in Puerto Rico if your billing address is in
              Puerto Rico. If any provision of the Agreement is invalid under
              the law of a particular jurisdiction, that provision will not
              apply in that jurisdiction.
            </li>
          </ol>
        </div>

        <div className="wysinwyg-editor">
          <h1 className="text-center">ACCEPTABLE USE POLICY</h1>
          <p>
            The Acceptable Use Policy <strong>(“Acceptable Use Policy”)</strong>
            is incorporated by reference into the Solutions Distributor Fixed
            Wireless Master Services Agreement (“<strong>Agreement</strong>”)
            and binding on Distributor and its Customers. Distributor will and
            will cause its Customers to enter into terms of service which are at
            least as restrictive as this Acceptable Use Policy.
          </p>
          <p>
            Examples of permitted and prohibited uses of the Service (as defined
            below) and Your Device <br /> <br />
            Permitted uses include:
          </p>

          <ul>
            <li>IP-based messaging;</li>
            <li>IP-based calling applications;</li>
            <li>Web browsing;</li>
            <li>Email;</li>
            <li>Streaming music;</li>
            <li>
              Uploading and downloading applications and content to and from the
              Internet or third party stores;
            </li>
            <li>
              Using applications and content without excessively contributing to
              network congestion; and
            </li>
            <li>
              Tethering your Device to other non-harmful devices pursuant to the
              terms and conditions and allotments of your Data Plan.
            </li>
          </ul>
          <p>
            Except as may be specifically authorized by UVNV in Distributor’s
            approved Service Application or unless explicitly permitted by your
            Rate Plan or Data Plan, you are not permitted to use your Device or
            the Services in a way that we determine:
          </p>
          <ul>
            <li>
              Uses a repeater or signal booster other than one we provide to
              you;
            </li>
            <li>
              Uses the Service for Machine to Machine based remote monitoring to
              provide life-sustaining medical care for any individual, including
              without limitation, in health care and assisted living
              environments;
            </li>
            <li>
              Uses the Service for the sale of any type of calling card product
              or service, including, but not limited to, any ‘grab-and-go’ or
              rechargeable product with a pin code that allows the End User or
              its Customer to purchase and/or refill minutes, internet access
              features or service;
            </li>
            <li>
              Uses, or attempts to use, the Service for voice communications
            </li>
            <li>9other than VoIP);</li>
            <li>
              Uses the Service for monitoring third parties without their
              permission, including, but not limited to, prisoner and parolee
              monitoring;
            </li>
            <li>
              Modifies the Service in any manner, including, but not limited to,
              combining to include any Distributor or third-party products,
              services, features or functionalities that are not included in the
              Service standing alone (<strong>“Bundled Service”</strong>)
              without prior written approval from a UVNV Vice President;
            </li>
            <li>
              Resells or Distributes the Service or Devices to any government
              End User Customer or subscriber, including, but not limited to
              federal, state, and local governmental entities;
            </li>
            <li>
              Resells the Service or Devices by promoting any specific feature
              that may be included with the UVNV Rate Plan other than or
              internet access Service usage allotments that are provided for
              domestic use with each rate plan.
            </li>
            <li>
              Compromises Network security or capacity, degrades Network
              performance, uses malicious software or “malware”, hinders other
              customers’ access to the Network, or otherwise adversely impacts
              Network service levels or legitimate data flows;
            </li>
            <li>
              Uses applications which automatically consume unreasonable amounts
              of available Network capacity;
            </li>
            <li>
              Uses applications which are designed for unattended use, automatic
              data feeds, automated machine-to-machine connections, or
              applications that are used in a way that degrades Network capacity
              or functionality;
            </li>
            <li>
              Misuses the Service, including “spamming” or sending abusive,
              unsolicited, or other mass automated communications;
            </li>
            <li>Accesses the accounts of others without authority;</li>
            <li>
              Results in any of your data usage being Off-Net (i.e., connected
              to another provider’s Network) for any period of time;
            </li>
            <li>
              Resells the Service without authorization, either alone or as part
              of any other good or service;
            </li>
            <li>
              Tampers with, reprograms, alters, or otherwise modifies your
              Device to circumvent any of our policies or violate anyone’s
              intellectual property rights;
            </li>
            <li>
              Causes harm or adversely affects us, the Network, our customers,
              employees, business, or any other person;
            </li>
            <li>Conflicts with applicable law;</li>
            <li>Is not in accordance with the UVNV T&amp;Cs;</li>
            <li>
              Attempts or assists or facilitates anyone else in any of the above
              activities; or
            </li>
            <li>In other ways as we may describe from time to time.</li>
          </ul>
        </div>

        <div className="wysinwyg-editor">
          <h1 className="text-center">PRIVACY POLICY</h1>
          <p>
            The following sets forth the Privacy Policy (
            <strong>“Privacy</strong> <strong>Policy”</strong>) of UVNVand is
            incorporated into and made a part of the Solutions Distributor Fixed
            Wireless Master Services Agreement (the <strong>“Agreement”</strong>
            ).
          </p>

          <h2>1. Purpose of Privacy Policy</h2>
          <p>
            Your privacy is important to UVNV. Accordingly, UVNV provides this
            Privacy Policy so you understand how UVNV collects, uses and, in
            certain circumstances, discloses the information that you provide
            and/or that is automatically provided to UVNV as a result of your
            visiting and using the UVNV . Fixed Wireless web site, the IoT
            Porta,, or any other website or portal owned and operated by UVNV
            (collectively, the <strong>“Sites”</strong>), or purchasing or using
            the Services.  This Privacy Policy also explains your options
            regarding the collection, use and disclosure of your personal
            information. UVNV is not responsible for the content or privacy
            practices on any third-party web site that may be linked to or from
            the Sites. If you do not agree with the policies outlined in this
            Privacy Policy, do not visit our Sites our use our Services. By
            using our Sites or Services, you agree to UVNV’s data handling
            practices outlined in this Privacy Policy.
          </p>
          <p>
            Any questions regarding this Privacy Policy should be directed to:
          </p>

          <p>
            Attn: Privacy Policy Officer <br />
            UVNV, Inc. <br />
            PO Box 28673 <br />
            Santa Ana, CA 92799 <br />
            Email:&nbsp;
            <span>
              <a href="mailto:info@ultra.me">info@ultra.me</a>
            </span>
          </p>

          <h2>2. Collection and Use of Personal Information</h2>
          <p>
            Personal information is information that identifies or is associated
            with a specific individual, such as a name, address, email address,
            or telephone number. UVNV collects personal information about
            Distributors, Customers, and other individuals. The ways in which we
            collect personal information about you include, but are not limited
            to, the following:
          </p>
          <ul>
            <li>
              Registration and Ordering. Before using certain parts of the Sites
              or purchasing Services, you must complete an online registration
              form or provide certain information to UVNV’s representatives.
              During registration (whether via the Sites or otherwise), you will
              be prompted to provide to UVNV certain personal information, which
              may include, but is not limited to, your name, billing
              address(es), phone number, port-in phone number, email address,
              and credit card number. This personal information is used for
              billing purposes, fulfilling your orders, communicating with you
              about your order or the services, and for marketing purposes.
            </li>
            <li>
              User Submissions. Certain locations in the Sites may permit you to
              enter your email address and other contact information to receive
              further information about UVNV or for other purposes. Your
              submissions for these purposes are completely voluntary, so you
              have a choice whether to participate and disclose information to
              UVNV. Should you choose to submit information in response to these
              requests, UVNV may collect and use your name, email address,
              geographic location and other information submitted on the Sites
              to market UVNV’s (including Affiliates’) products and
            </li>
            <li>
              Automatically Generated and Collected Information. In addition to
              information that you volunteer as described above, the Sites may
              utilize technologies to collect information and track your user
              experience. UVNV will use this information to improve the Sites
              and user experience. In addition, the Sites automatically
              recognizes the Internet URL from which you access the Sites and
              may also log your Internet protocol (<strong>“IP”</strong>)
              address (which may indicate the location of your computer on the
              Internet), Internet service provider, and date/time stamp for
              system administration, order verification, marketing, and system
              troubleshooting purposes. In addition, all wireless communication
              systems know when your Device is turned on and approximately where
              the device is physically located.
            </li>
            <li>
              Information from Third Parties. We may obtain information about
              you and combine it with your account information. For example, we
              may receive information from the retailer from whom you purchased
              an Ultra Mobile product prior to your commencing service with us.
              In addition, we use a third-party service provider to serve ads
              and/or collect data on our behalf across the Internet and
              sometimes on this Web site. Some of these service providers may
              collect information about your visits to our Web site, and your
              interaction with our products and services to tailor marketing
              messages on this Web site and other sites or to trigger real time
              interaction, customize this Web site or enhance your profile. Some
              of these service providers may be able to collect personal
              information that you share with this site via a web form
              automatically and prior to your pressing a submit button; and they
              may be able to use information from your visits to this site to
              send marketing messages to you in a way that may personally
              identify you. This information is collected through the use of a
              cookie, a javascript tag and/or pixel, which is industry standard
              technology used by most major web sites.  The information
              collected by these service providers may include your device’s IP
              address, user agent, email addresses (where hashed or otherwise
              rendered pseudonymous) and other user and device level
              pseudonymous information. Please keep in mind that your browser
              settings may not permit you to control the technologies utilized
              by these third-party companies. If you would like more information
              about these practices, please click:
              http://optout.aboutads.info/#!/.The Service provides only
              broadband Internet access and is neither required to provide, nor
              provides, 911 calling services.  We shall not be responsible or
              liable for any voice application or service, including without
              limitation such application’s or service’s provision of 911
              calling, unless such application or service is provided by Ultra
              Mobile and Ultra Mobile agrees to such responsibility and
              liability in writing.\
            </li>
            <li>
              Generally, UVNV collects the above information for the following
              purposes: (i) to improve your experience on the Sites; (ii) to
              improve the products and services that UVNV sells; (iii) to
              communicate with you regarding the Sites; (iv) to market and sell
              products and services to you and send you information about
              special offers; (v) to contact you about products or services you
              have purchased from UVNV; (vi) to respond to your inquiries; (vii)
              to manage and develop our business and operations, administer
              accounts, and collect and process payments; and (viii) if you
              authorize text messaging, we will use your mobile number to
              communicate with you about your account and to send marketing text
              messages to you. If you decide that you no longer wish to receive
              emails or text messages from Ultra Mobile that you had authorized,
              you may opt-out of receiving emails and text messages by managing
              your account preferences on the Web site or by any other
              reasonable opt-out method available to you. Please note that Ultra
              Mobile may have to communicate with you about product or service
              orders that you choose to place. You cannot opt-out of receiving
              product or service-related emails.
            </li>
          </ul>
          <p>
            UVNV will not sell, rent, trade, license or otherwise disclose your
            specific personal information or financial information to third
            parties, except that: (1) UVNV uses third parties to perform
            specific functions on its behalf and it must disclose information to
            help those parties perform their services (for example, UVNV would
            disclose your name, address and phone number to a commercial carrier
            such as Federal Express or UPS in order to deliver a physical
            product that you order); (2) UVNV must disclose your name and credit
            card number and information to financial services and credit card
            processing companies in order to process your orders; (3) UVNV may
            be required to disclose such information in response to a legal
            process, order or investigation (for example, a subpoena or court
            order); (4) UVNV may disclose personal information where disclosure
            is necessary to protect its legal rights or to protect itself or
            others (for example, in order to reduce the risk of fraud or illegal
            activities); (5) UVNV may share such information amongst the
            Affiliates and third parties for the purpose of improving our (or
            the Affiliates’) ability to market products and services to you; (6)
            UVNV may provide identity verification services to third parties and
            your personal information may be included in the data transferred to
            third parties in connection with those services; and (7) UVNV may
            acquire, merge with or be acquired by another company or it might
            sell some or all of its assets, and, in such a case, your personal
            information may be included in the transferred business assets.
            Also, in the event of our bankruptcy, insolvency, reorganization,
            receivership, or assignment for the benefit of creditors, we may not
            be able to control how your personal information is treated,
            transferred, or used and your personal information may be included
            in the transferred assets. UVNV may sell, rent, trade, license, use
            and disclose all non- personal information and aggregate
            information, so long as such information does not personally
            identify you or any user, to any third party for any purpose,
            including, but not limited to, research and analytical purposes.
          </p>
          <h2>3. Cookies</h2>
          <p>
            <strong>“Cookies”</strong> are pieces of information that may be
            placed on your computer by a Sites for the purpose of facilitating
            and enhancing your communications and interaction with that Sites.
            Cookies may possibly be used for such things as: (1) to customize
            your visitation and shopping experience; (2) to deliver content
            consistent with your stated interests; (3) to provide you with
            custom-tailored content; or (4) to save your password in
            password-protected areas. The use of cookies is an industry standard
            — you will find them at most major Sites. Most browsers are
            initially set to accept cookies. If you would prefer, you can set
            yours to refuse cookies or to alert you when cookies are being sent.
            However, it is possible that some parts of the Sites will not
            function properly if you do so. Cookies, by themselves, do not tell
            us your email address or other personal information unless you
            choose to provide this information to us. However, once you choose
            to furnish the Sites with personal information, this information may
            be linked to the data stored in the cookie. We use cookies to
            understand usage of the Sites and to improve the content and
            offerings on our Sites. In addition, we may utilize web beacons to
            recognize you when you visit the Sites. We may also use other
            technologies, currently available or which may become available in
            the future, to provide us with other important anonymous information
            regarding the use of our Sites.
          </p>

          <h2>4. Children’s Online Privacy Protection Act</h2>

          <p>
            Our Sites are not intended for children under 13 years of age. No
            one under age 13 may provide any information, personal or otherwise,
            to or on any of our Sites. We do not knowingly collect personal
            information from children under 13. If you are under 13, do not use
            or provide any information on the Sites or on or through any of its
            features, make any purchases, use any of the interactive features,
            or provide any information about yourself to us, including your
            name, address, telephone number, email address, or any screen name
            or user name you may use. If we learn we have collected or received
            personal information from a child under 13 without verification of
            parental consent, we will delete that information. If you believe we
            might have any information from or about a child under 13, please
            contact us at the email address above.
          </p>

          <h2>5. Advertisements and Links</h2>

          <p>
            The Sites may display advertisements and links for third-party
            websites, products and services. Information you provide directly to
            third parties and information collected from you by third parties in
            connection with your use of their websites, products or services
            will be subject to such third parties’ privacy policies and not this
            Privacy Policy.
          </p>

          <h2>6. YOUR CALIFORNIA PRIVACY RIGHTS</h2>

          <p>
            California Civil Code Section 1798 entitles California customers to
            request information concerning a business’s sharing of personal
            information to any third parties for the third parties’ direct
            marketing purposes. Ultra Mobile will not knowingly share your
            personal information with non-affiliated third parties for their
            direct marketing uses without your opt-in consent. California
            customers who wish to request further information about our
            compliance with this law or have questions or concerns about our
            privacy practices and policies may contact us as specified in the
            “Contact Us” section below. Do Not Track (DNT) Disclosure: This Site
            does not currently respond to “Do Not Track” signals of web
            browsers. However, should the industry establish uniform
            technological standards for recognizing and implementing “Do Not
            Track” signals, Ultra Mobile would be open to following such
            industry standards.
          </p>

          <h2>7. California Subscriber Rights</h2>

          <p>
            As of January 1, 2021 if you are a California resident, as defined
            in the California Code of Regulations, you have rights under the
            California Consumer Privacy Act of 2018 (the <strong>“CCPA”</strong>
            ). A description of your rights and disclosures about how we process
            your personal information are below.
          </p>
          <p>
            The CCPA will give California residents certain rights regarding
            their data, including:
          </p>

          <ul>
            <li>
              The right to know the categories of personal information we’ve
              collected and the categories of sources from which we got the
              information (see Information collected by UVNV and the chart just
              below)
            </li>
            <li>
              The right to know the business purposes for sharing personal
              information (see Why UVNV uses your information)
            </li>
            <li>
              The right to know the categories of third parties with whom we’ve
              shared personal information (see Sharing your information)
            </li>
            <li>
              The right to access the specific pieces of personal information
              we’ve collected and the right to delete your information (see
              Privacy tools and choices)
            </li>
          </ul>
          <p>
            California residents will also have the right to not be
            discriminated against if they exercise their rights under the CCPA.
          </p>
          <p>
            Before we honor your rights to request information or delete
            information, we must verify your or your authorized agent’s
            identity. In certain cases, we may require you to provide additional
            information in order to verify your request.
          </p>
          <p>
            UVNV does not sell your personal information, but we may disclose
            your personal information to service providers who support our
            operations.
          </p>
          <p>
            <i>
              Categories of Personal Information under the California Consumer
              Privacy Act
            </i>
          </p>
          <div style={{ textAlign: 'center' }}>
            <img
              className="terms__img"
              src={TncImage}
              alt="Terms & Conditions table-"
              width="800"
              height="400"
            />
          </div>

          <p>
            Privacy tools and choices <br />
            For CCPA related requests, please submit a request by <br />
            Online Form <br />
            Or <br />
            Call 1.888.777.0446 <br />
            What to expect once you submit a request?
            <br />
            Ultra Mobile will confirm receipt of the request within 10 days via
            email address you provided.
          </p>

          <h2>8. Revisions</h2>

          <p>
            From time to time, we may revise the Privacy Policy. Your use of the
            Sites or Services following the posting of any revised Privacy
            Policy shall be deemed acceptance of the revised policy, so we
            recommend that you check the Privacy Policy periodically. If you
            disagree with the terms of the Privacy Policy at any time, your sole
            remedy is to terminate your use of the Sites and Services. Continued
            use of the Sites or Services constitutes your agreement to any
            Privacy Policy as in effect.
          </p>

          <h2>9. Severability</h2>

          <p>
            If any part of the Privacy Policy is determined to be invalid or
            unenforceable by any arbitrator, court of competent jurisdiction or
            applicable law, the parties intend that such provision be amended
            and construed in a manner designed to effectuate the purposes of the
            provision to the fullest extent permitted by law.
          </p>
        </div>
      </main>
    );
  }
}
