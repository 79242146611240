import fetch from 'isomorphic-fetch';
// import Cookies from 'universal-cookie'

// import Config from '../config'

// const cookies = new Cookies()

const handleHTTPErrors = (res) => {
  if (res.ok) return res;
  return res.json().then((err) => {
    throw err;
  });
};

// eslint-disable-next-line
export default (url, options) => {
  const jwtToken =
    localStorage.getItem('Authorization') ||
    localStorage.getItem('t_Authorization');
  if (jwtToken) {
    let authAddedOptions = options;
    if (typeof options !== 'object') {
      authAddedOptions = {};
    }
    if (typeof authAddedOptions.headers !== 'object') {
      authAddedOptions.headers = {};
    }

    let adminAuthToken = localStorage.getItem('i_Authorization');
    if (
      adminAuthToken !== undefined &&
      adminAuthToken !== null &&
      adminAuthToken !== ''
    ) {
      authAddedOptions.headers.admin = adminAuthToken;
    }

    authAddedOptions.headers = {
      ...authAddedOptions.headers,
      Authorization: jwtToken,
    };
    return fetch(url, authAddedOptions).then(handleHTTPErrors);
  } else {
    return fetch(url, options).then(handleHTTPErrors);
  }
};
