/* eslint no-undef: "off"*/
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import ReactModal from 'react-modal';
import TopNavigation from '../TopNavigation/container';
import SidebarNavigation from '../SidebarNavigation/component';
import QR from 'react-qr-code';

import {
  BackArrowIcon,
  CloseIcon,
  SimsColorIcon,
  SuccessIcon,
  FailedIcon,
  CalenderIcon,
} from '../../components/icons';
import {
  isSPRPartner,
  checkPermission,
  isSubUser,
} from '../../constants/common';
import InputMask from 'react-text-mask';
import { DebounceInput } from 'react-debounce-input';
import { Formik, ErrorMessage } from 'formik';
import { wifiCallingSchema } from '../../utils/validations';
import PlacesAutocomplete from '../../components/PlaceSearchBox';
import AddIldConfirmationModal from './partials/addIldConfirmationModal';
import AddIrConfirmationModal from './partials/addIrConfirmationModal';
import EligiblePlansModal from './partials/eligiblePlans';
import CancelRecurringPlanModal from './partials/cancelRecurringPlan';
import { message, DatePicker, notification } from 'antd';
import _ from 'lodash';
import moment from 'moment-timezone';
import { Select, Drawer, Switch } from 'antd';
import socket from '../../utils/socket';
import './styles.scss';
import { get } from 'immutable';
import ActivityLogs from './partials/activityLogs';
import UsageLogs from './partials/UsageLogs';
import { handleEsimPdfDownload } from '../../utils/helpers';

const DownloadIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="24"
      fill="none"
      viewBox="0 0 17 24"
      {...props}
    >
      <path
        fill="#673594"
        fillRule="evenodd"
        d="M9.258 17.505l5.92-6.003c1.042-1.045-.521-2.613-1.564-1.568l-3.484 3.495c-.291.292-.524.196-.524-.211V1.108C9.606.497 9.11 0 8.5 0 7.89 0 7.395.496 7.395 1.11v12.109c0 .406-.235.501-.525.211L3.386 9.934c-1.043-1.045-2.606.523-1.564 1.568l5.881 6c.428.437 1.125.439 1.555.003zm-8.15 4.022a1.109 1.109 0 000 2.218h14.783a1.109 1.109 0 000-2.218H1.11z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

// eslint-disable-next-line
const eSimSuccessIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
      {...props}
    >
      <g>
        <circle cx="11" cy="11" r="11" fill="#58A770"></circle>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M10.074 14.64l-3.79-3.87L7.51 9.517l2.564 2.619L15.101 7l1.226 1.253-6.253 6.388z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

const ErrorIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
      {...props}
    >
      <path
        fill="#F04533"
        d="M22 11c0 6.075-4.925 11-11 11S0 17.075 0 11 4.925 0 11 0s11 4.925 11 11z"
      ></path>
      <path
        fill="#F8F8FC"
        d="M12.476 11.314l3.016-3.016L14.1 6.906l-3.016 3.016-3.016-3.016-1.392 1.392 3.016 3.016-3.017 3.017 1.392 1.392 3.017-3.017 3.016 3.017 1.392-1.392-3.016-3.017z"
      ></path>
    </svg>
  );
};

let interval;
// For tags options
const { Option } = Select;
const phoneNumberMask = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
const iccidMask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export default class SIMDetailComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      iAuthorization: localStorage.getItem('i_Authorization'),
      isLoading: true,
      isUsageLoading: true,
      userData: null,
      editSimModalOpen: false,
      reactivateSimModalOpen: false,
      reactivateSimToDeactivateModalOpen: false,
      editTagsModalOpen: false,
      wifiCallingModalOpen: false,
      wificallingInitialValues: {
        addressline1: '',
        addressline2: '',
        city: '',
        address: '',
        state: '',
        zipcode: '',
      },
      address: '',
      errorMessage: '',
      portInRequestStatus: '',
      activePortInReqData: {},
      updatePortInRequestModal: false,
      cancelPortInRequestModal: false,
      refreshPortIn: false,
      updatePortInLoading: false,
      phoneNumberPortIn: '',
      carrierPortIn: '',
      carrierACNumberPortIn: '',
      carrierAssignPasswordportIn: '',
      name: '',
      nameError: false,
      zipcodePortIn: '',
      alternateContactPortIn: '',
      plansDataPortIn: [],
      selectedSimPlanPortIn: {},
      requirePortInPhoneNumber: false,
      requireCarrierACNumber: false,
      requireCarrierAssignPassword: false,
      requireZipcodePortIn: false,
      requireAlternateContactPortIn: false,
      planError: false,
      simDetailData: {},
      irPlans: [],
      ildPlans: [],
      ildBalanceInfo: null,
      addIRBalance: 0,
      addIRBalanceConfirm: false,
      addIldMinutes: 0,
      addIldMinsPayType: 'recurring',
      addIrPayType: 'recurring',
      irBalanceInfo: null,
      plansData: [],
      usage: {},
      planCycle: {},
      selectedPlan: {},
      editSimError: {},
      newSimName: '',
      selectedAction: '',
      isActionModal: false,
      updateLoading: false,
      updateEsimLoading: false,
      isUpdateSimModal: false,
      changePhoneNumberModal: false,
      newNumberZipcode: '',
      changePhoneNumberError: '',
      changePhoneNumberLoading: false,
      changePhoneNumberSuccessModal: false,
      adminOverrrideModal: false,
      adminOverrideSuccess: false,
      overwrittenSimDetails: null,
      iccidNew: '',
      msisdnNew: '',
      usernameNew: '',
      passwordNew: '',
      planIdNew: '0',
      simStatusNew: '',
      portInStatusNew: '',
      datePickerValue: '',
      filterTags: [],
      searchFilterTags: [],
      selectedListTags: [],
      originalSelectedTags: [],
      isApplyButton: false,
      isCreateTagModal: false,
      newTag: [],
      requireName: false,
      titleText: '',
      descriptionText: '',
      accountNumber: '',
      newSimNameSize: false,
      newTagSize: false,
      voicemailStatusUpdateModal: false,
      voicemailLoading: false,
      hidePlanCycle: true,
      isTagChanged: false,
      viewApnDrawer: false,
      viewPortInDrawer: false,
      nextChangeDate: '',
      changePlanError: '',
      isDisabledPlanUpdate: false,
      samePlanError: false,
      swapSimModalOpen: false,
      AddMinutesModal: false,
      AddBalanceModal: false,
      searchVal: '',
      searchData: [],
      showSearchDropdown: false,
      swapSimError: '',
      swapESimError: '',
      isCantPauseModal: false,
      reconnectErrorMessage: '',
      isReconnectLoading: false,
      reconnectTime: 0,
      reconnectNetworkDisabled: false,
      showTimer: false,
      time: '',
      reconnectWaitTime: 60,
      selectedSimPlan: null,
      adminScope: '',
      orderrideError: {
        iccidNew: '',
        msisdnNew: '',
        usernameNew: '',
        passwordNew: '',
        planIdNew: '',
        simStatusNew: '',
        portInStatusNew: '',
        datePickerValue: '',
      },
      isUpdateZipcode: false,
      newZipcode: '',
      addIldConfirmationModal: false,
      addIrConfirmationModal: false,
      modalText: '',
      sureBtnDisabled: true,
      addOnPlansUsage: null,
      remainingIRBalance: 0,
      recurringInfo: {},
      dataUsageFromUltra: {},
      cancelRecurringPlanModal: false,
      recurringType: '',
      voiceMailStatus: false,
      wifiCallingData: {},
      irIldDetails: { isILD: false, isIR: false },
      planChangedCount: 0,
      zipcodeErrorMessage: '',
      isUnpauseModal: false,
      isIldIrEnabled: false,
      isILDEnabled: false,
      isIREnabled: false,
      ildPlanType: 'recurring',
      ildPlanValue: 0,
      ildPlanTypeRequired: false,
      ildPlanValueRequired: false,
      irPlanType: 'recurring',
      irPlanValue: 0,
      irPlanTypeRequired: false,
      irPlanValueRequired: false,
      useInterval: false,
      pukCodes: null,
      changePhoneNumberErrorModal: false,
      portInDisabled: false,
      isCheckCoverage: false,
      isCheckDeviceCompatibility: false,
      isSelectPlan: false,
      isInstalleSIM: false,
      plansArray: [],
      eSIMCompatible: false,
      zipCodeApiError: '',
      zipCodeSuccess: '',
      zipcode: '',
      finalZip: '',
      zipcodeLoding: false,
      imeiNumber: '',
      modelArray: [],
      model: '',
      deviceApiError: '',
      imeiLoding: false,
      brandLoading: false,
      activateLoading: false,
      deviceName: '',
      brandsArray: [],
      brand: '',
      proceed: false,
      enableEsummary: false,
      activateeSim: false,
      enableQRCodeModal: false,
      eSimObj: {},
      eSimAvailability: false,
      disableButton: false,
      activationErrorMsg: '',
      plan: '',
      isEsimAvailable: false,
      isImeiSection: false,
      isCheckByBrandModal: false,
      Inprogress: false,
      eSimFilter: false,
      pSimFilter: false,
      fieldName: '',
      portInFailsPopup: false,
      error: '',
      isVMApiResponse: false,
      isSimDetailLoading: true,
      isAccountNumberLoading: true,
      isPukLoading: true,
      isPlanCycleLoading: true,
      eSimProfileState: '',
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount = async () => {
    document.title = 'Sim Detail | UltraIoT';
    try {
      socket.on('notifications', (data) => {
        if (data) {
          this.getSimDetails();
        }
      });

      let orderType = 'spr';
      if (localStorage.getItem('prevTab') === 'fixed') {
        orderType = 'fixed';
      }

      this.getBrands();
      const [
        {
          value: { success, user },
        },
        {
          value: { scope },
        },
        {
          value: { total },
        },
      ] = await Promise.all([
        this.props.fetchMe(),
        this.props.fetchScope(),
        this.props.fetchSimCounts({ orderType }),
      ]);
      if (success && user) {
        if (scope && scope !== '') {
          this.setState({ adminScope: scope });
        }
        this.props.setUser(user);
        this.props.setCount(total);
        this.setState({ userData: user });

        this.getSimDetails();
        this.getWifiCallingDetails();
        this.getAccountNumber();
        this.ildIrBalanceInfo();
      }
    } catch (err) {
      this.setState({
        error: err,
      });
    }
  };

  getBrands = async () => {
    try {
      const {
        value: { success, data },
      } = await this.props.getBrands();
      if (success) {
        const sortedBrands = _.orderBy(
          _.get(data, 'brands', []),
          ['name'],
          ['asc']
        );
        this.setState({ brandsArray: sortedBrands });
      }
    } catch (err) {
      this.setState({
        error: err,
      });
    }
  };

  checkImeiNumber = async () => {
    try {
      this.setState({ Inprogress: true });
      this.setState({ isCheckByBrandModal: false });
      this.setState({ isImeiSection: false });
      this.setState({ isCheckCoverage: true });
      this.setState({ isCheckDeviceCompatibility: true });
      this.setState({ isSelectPlan: false });
      this.setState({ isInstalleSIM: false });

      const obj = {};
      obj.imei = this.state.imeiNumber;
      this.setState({ imeiNumber: this.state.imeiNumber });
      this.setState({ imeiLoding: true });
      const simType =
        parseInt(_.get(this.props, 'user.lobId', 1)) === 3 ? 'fixed' : 'spr';
      obj.simType = simType;

      const {
        value: { success, data, statusCode },
      } = await this.props.getDeviceCompatibility(obj);
      this.setState({ imeiLoding: false });
      this.setState({ brandLoading: false });
      this.setState({ eSIMCompatible: false });

      this.setState({ proceed: false });
      this.setState({ deviceName: '' });

      if (success) {
        this.setState({ isImeiSection: true, deviceApiError: '' });
        this.setState({ deviceName: data.name });
        if (data.eSIMCompatible) {
          this.setState({ Inprogress: false });

          this.setState({ eSIMCompatible: true });
        } else {
          this.setState({ activateeSim: false });
        }
      } else if (statusCode === 400 || statusCode === 500) {
        this.setState({ deviceApiError: 'Please enter valid IMEI number.' });
      } else if (statusCode === 404) {
        this.setState({ deviceApiError: 'Invalid IMEI number.' });
      } else {
        this.setState({ deviceApiError: 'Error while processing request.' });
      }
    } catch (error) {
      this.setState({ deviceApiError: 'Error while processing request.' });
    }
  };

  submitDeviceDetail = async () => {
    try {
      this.setState({ Inprogress: true });
      this.setState({ isImeiSection: false });

      this.setState({ isCheckByBrandModal: false });
      this.setState({ isCheckCoverage: true });
      this.setState({ isCheckDeviceCompatibility: true });
      this.setState({ isSelectPlan: false });
      this.setState({ isInstalleSIM: false });

      const obj = {};
      this.setState({ brandLoading: true });
      const filterFinalData = _.filter(
        this.state.modelArray,
        (m) => _.get(m, 'name', '') === this.state.model
      );
      if (filterFinalData && filterFinalData.length > 0) {
        const finalObj = filterFinalData[0];
        let tacNumber = _.get(finalObj, 'tac', '');
        tacNumber = `${_.get(finalObj, 'tac', '')}0000000`;
        obj.imei = tacNumber;
      }

      const simType =
        parseInt(_.get(this.props, 'user.lobId', 1)) === 3 ? 'fixed' : 'spr';
      obj.simType = simType;

      const {
        value: { success, data },
      } = await this.props.getDeviceCompatibility(obj);
      this.setState({ imeiLoding: false });
      this.setState({ brandLoading: false });
      this.setState({ eSIMCompatible: false });

      this.setState({ deviceName: '' });
      this.setState({ proceed: false });

      if (success) {
        this.setState({ isCheckByBrandModal: true, deviceApiError: '' });
        this.setState({ deviceName: data.name });
        if (data.eSIMCompatible) {
          this.setState({ Inprogress: false });
          this.setState({ eSIMCompatible: true });
        } else {
          this.setState({ activateeSim: false });
        }
      }
    } catch (error) {
      this.setState({ deviceApiError: 'Error while processing request.' });
    }
  };

  getVoiceMailStatus = async (simId) => {
    if (simId) {
      const {
        value: { success, data },
      } = await this.props.getVoiceMailStatus({ id: simId });
      if (success) {
        this.setState({
          voiceMailStatus: _.get(data, 'enabled', false),
          isVMApiResponse: true,
        });
      }
    }
  };

  getWifiCallingDetails = async () => {
    let path = _.get(window, 'location.pathname', false);
    let iccid = path.split('/detail/');
    iccid = iccid[1];
    if (iccid) {
      const {
        value: { success, wifiCallingResp },
      } = await this.props.getWifiCallingDetails({ iccid });
      if (success) {
        this.setState({
          wifiCallingData: wifiCallingResp,
          wificallingInitialValues: {
            addressline1: wifiCallingResp.e911Address.street1 || '',
            addressline2: wifiCallingResp.e911Address.street2 || '',
            city: wifiCallingResp.e911Address.city || '',
            address: wifiCallingResp.e911Address.street1 || '',
            state: wifiCallingResp.e911Address.state || '',
            zipcode: wifiCallingResp.e911Address.zip || '',
          },
          address: wifiCallingResp.e911Address.street1 || '',
        });
      }
    }
  };

  getUsageDailyLogs = async () => {
    this.setState({ isUsageLoading: true });
    try {
      let path = _.get(window, 'location.pathname', '');

      if (path && path !== '') {
        let iccid = path.split('/detail/');
        const postData = {
          planStartDate: _.get(
            this.state.planCycle,
            'startOfCycle',
            moment(new Date()).format('YYYY-MM-DD')
          ),
        };
        if (iccid.length > 1) {
          const {
            value: { success, results },
          } = await this.props.getUsageDailySummary(iccid[1], postData);
          if (success) {
            this.setState({ rediisUsage: results, isUsageLoading: false });
          }
        }
      }
    } catch (error) {
      this.setState({ isUsageLoading: false, rediisUsage: [] });
    }
  };

  apiCallFunction = (iccid) => {
    this.addOnPlans(iccid);
    this.hidePlanCycle(iccid);
    this.irIldDetails(iccid);
    this.remainingIRBalance(iccid);
    this.recurringInfo(iccid);
    this.planCycle(iccid);
    this.planChangeCount(iccid);
    this.addOnPlansUsage(iccid);
    this.activePortInData(iccid);
    this.getAccountNumber();
    this.getPukCodes();
    this.getAdditionalEsimInfo(iccid);
  };

  activePortInData = async (iccid) => {
    try {
      const {
        value: { data },
      } = await this.props.ActivePortinData(iccid);
      this.setState({ activePortInReqData: data });
      if (data !== undefined && data !== null) {
        this.setState({
          portInRequestStatus: data.status,
          phoneNumberPortIn: data.msisdn,
          carrierPortIn: data.carrier,
          carrierACNumberPortIn: data.accountNumber,
          carrierAssignPasswordportIn: data.accountPassword,
          street1: data.street1,
          street2: data.street2,
          city: data.city,
          state: data.state,
          zip: data.zip,
          zip4: data.zip4,
          zipcodePortIn: data.zipcode,
          alternateContactPortIn: data.alternateContact,
          planIdPortIn: data.planId,
          selectedSimPlanPortIn: data.planId,
          portInStatusNew: data.status,
          name: data.simName,
        });
      }
    } catch (error) {
      //error occurred here
    }
  };

  planChangeCount = async (iccid) => {
    try {
      const {
        value: { data },
      } = await this.props.planChangeCount(iccid);
      this.setState({ planChangedCount: data });
    } catch (error) {
      //error occurred here
    }
  };

  addOnPlansUsage = async (iccid) => {
    try {
      const {
        value: { data },
      } = await this.props.addOnPlansUsage(iccid);
      this.setState({ addOnPlansUsage: data });
    } catch (error) {
      //error occurred here
    }
  };

  planCycle = async (iccid) => {
    try {
      const {
        value: { data },
      } = await this.props.planCycle(iccid);
      this.setState({ planCycle: data, isPlanCycleLoading: false });
      this.getUsageDailyLogs();
    } catch (error) {
      this.setState({ isPlanCycleLoading: false });
      //error occurred here
    }
  };

  recurringInfo = async (iccid) => {
    try {
      const {
        value: { data },
      } = await this.props.recurringInfo(iccid);
      this.setState({ recurringInfo: data });
    } catch (error) {
      //error occurred here
    }
  };

  remainingIRBalance = async (iccid) => {
    try {
      const {
        value: { data },
      } = await this.props.remainingIRBalance(iccid);
      this.setState({ remainingIRBalance: data });
    } catch (error) {
      //error occurred here
    }
  };

  getAccountNumber = async () => {
    try {
      let path = _.get(window, 'location.pathname', false);
      let iccid = path.split('/detail/');
      iccid = iccid[1];
      const {
        value: { data },
      } = await this.props.accountNumber(iccid);
      this.setState({ accountNumber: data, isAccountNumberLoading: false });
    } catch (error) {
      this.setState({ isAccountNumberLoading: false });
    }
  };

  getPukCodes = async () => {
    try {
      let path = _.get(window, 'location.pathname', false);
      let iccid = path.split('/detail/');
      iccid = iccid[1];
      const {
        value: { data },
      } = await this.props.pukCodes(iccid);
      this.setState({ pukCodes: data, isPukLoading: false });
    } catch (error) {
      this.setState({ isPukLoading: false });
    }
  };

  addOnPlans = async (iccid) => {
    try {
      const {
        value: { data: addOnPlans },
      } = await this.props.addOnPlans(iccid);
      const irPlans = addOnPlans
        .filter((plan) => plan.type === 'ir')
        .sort((a, b) => a.titleValue - b.titleValue);
      const ildPlans = addOnPlans.filter((plan) => plan.type === 'ild');
      this.setState({ irPlans: irPlans, ildPlans: ildPlans });
    } catch (error) {
      //error occurred here
    }
  };
  hidePlanCycle = async (iccid) => {
    try {
      const {
        value: { data },
      } = await this.props.hidePlanCycle(iccid);
      this.setState({ hidePlanCycle: data });
    } catch (error) {
      //error occurred here
    }
  };
  irIldDetails = async (iccid) => {
    try {
      const {
        value: { data },
      } = await this.props.irIldDetails(iccid);
      this.setState({ irIldDetails: data });
    } catch (error) {
      //error occurred here
    }
  };

  reconnectWaitTime = async (simData) => {
    try {
      const {
        value: { data: reconnectWaitTime },
      } = await this.props.reconnectWaitTime(simData.simnumber);
      if (parseInt(simData.reconnectTime) <= parseInt(reconnectWaitTime)) {
        const diff =
          parseInt(reconnectWaitTime) - parseInt(simData.reconnectTime);
        this.setState({ reconnectNetworkDisabled: true });
        this.waitForReconnect(diff);
      }
    } catch (error) {
      //error occurred here
    }
  };

  getPlans = async (iccid, simData) => {
    try {
      let {
        value: { data: plans },
      } = await this.props.planDetails(iccid);
      let filteredPlans = [];
      if (simData && simData.simtype && simData.simtype === 'fixed') {
        filteredPlans = plans.filter((plan) => plan.category === 'Fixed');
      } else {
        filteredPlans = plans.filter((plan) => plan.category !== 'Fixed');
      }
      plans = filteredPlans;
      if (!_.isEmpty(simData) && _.size(plans) > 0) {
        let planIndex = plans.findIndex((item) => item.title === simData.title);
        if (planIndex > -1) {
          let selectedPlan = plans[planIndex];
          this.setState({ selectedPlan }, () => {
            if (
              selectedPlan &&
              (selectedPlan.title.toLowerCase().includes('just data') ||
                selectedPlan.title.toLowerCase().includes('everything'))
            ) {
              this.setState({ isPlanDetail: true });
            }
          });
        }
        this.setState({ plansDataPortIn: plans, plansData: plans });
      }
    } catch (error) {}
  };

  simcardDetail = async () => {
    try {
      let path = _.get(window, 'location.pathname', false);
      let iccid = path.split('/detail/');
      const {
        value: { data, success },
      } = await this.props.simcardDetail(iccid[1]);
      if (success) {
        const simData = data;
        if (simData && simData.id) {
          this.getVoiceMailStatus(simData.id);
        }
        this.reconnectWaitTime(simData);
        this.getPlans(iccid[1], simData);
        let newSimName = simData.simname ? simData.simname : '';
        this.setState({
          newSimName,
          simStatusNew: simData.status,
          addIldConfirmationModal: false,
          addIrConfirmationModal: false,
          updateLoading: false,
          simDetailData: simData,
          isLoading: false,
          isSimDetailLoading: false,
        });
        if (simData.tags && simData.tags !== '') {
          let tagsArr = simData.tags.split(',');
          let filterTags = this.state.filterTags;
          filterTags.push(...tagsArr);
          filterTags = filterTags.filter(
            (item, pos) => filterTags.indexOf(item) === pos
          );
          this.setState({ filterTags });
        }
      }
    } catch (error) {
      this.setState({
        updateLoading: false,
        isLoading: false,
        isSimDetailLoading: false,
      });
    }
  };
  ildIrBalanceInfo = async () => {
    const ildBalanceInfo = {
      used: 100,
      limit: 1000,
      remaining: 900,
    };
    const irBalanceInfo = {
      voice: {
        used: 0,
        remaining: 0,
        limit: 0,
      },
      sms: {
        used: 0,
        remaining: 0,
        limit: 0,
      },
      data: {
        full: {
          used: 0,
          remaining: 0,
          limit: 0,
        },
        wallet: {
          used: 50,
          remaining: 950,
          limit: 1000,
        },
      },
    };
    this.setState({ irBalanceInfo, ildBalanceInfo });
  };

  getSimDetails = async () => {
    try {
      let path = _.get(window, 'location.pathname', false);
      if (path) {
        let iccid = path.split('/detail/');
        if (iccid.length > 1) {
          this.apiCallFunction(iccid[1]);
          this.simcardDetail();
        }
      }
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  updateSimDetail = () => {
    this.getSimDetails();
  };

  openModal() {
    const { simDetailData, plansData } = this.state;
    let planIndex = plansData.findIndex(
      (item) => item.title === simDetailData.title
    );
    if (planIndex > -1) {
      let selectedPlan = plansData[planIndex];
      this.setState({ selectedPlan }, () => {
        if (
          selectedPlan &&
          (selectedPlan.title.toLowerCase().includes('just data') ||
            selectedPlan.title.toLowerCase().includes('everything'))
        ) {
          this.setState({ isPlanDetail: true });
        }
      });
    }
    let newSimName = simDetailData.simname ? simDetailData.simname : '';
    let nextChangeDate = this.getNextPlanDate();
    this.setState({
      editSimModalOpen: true,
      nextChangeDate,
      newSimName,
      newSimNameSize: false,
      requireName: false,
      planError: false,
    });
  }

  closeModal() {
    this.setState({
      isEsimAvailable: false,
      convertesimModal: false,
      isUpdateSimModal: false,
      editSimModalOpen: false,
      eSIMCompatible: true,
      deviceName: '',
      imeiNumber: '',
      brand: '',
      model: '',
      swapESimError: '',
      reactivateSimModalOpen: false,
      reactivateSimToDeactivateModalOpen: false,
      editTagsModalOpen: false,
      selectedAction: '',
      requireName: false,
      deleteModal: false,
      isCreateTagModal: false,
      isActionModal: false,
      isActivateModal: false,
      newSimName: '',
      changePlanError: '',
      samePlanError: false,
      swapSimModalOpen: false,
      swapSimConfirmingModalOpen: false,
      eSimFilter: false,
      pSimFilter: false,
      swapSimError: '',
      searchVal: '',
      isCantPauseModal: false,
      updatePortInRequestModal: false,
      cancelPortInRequest: false,
      cancelPortInRequestModal: false,
      cancelPortInLoader: false,
      wifiCallingModalOpen: false,
      errorMessage: '',
      reconnectNetworkDisabled: false,
      failedModalOpen: false,
      successModalOpen: false,
      changePhoneNumberModal: false,
      newNumberZipcode: '',
      changePhoneNumberError: '',
      changePhoneNumberLoading: false,
      changePhoneNumberSuccessModal: false,
      adminOverrrideModal: false,
      adminOverrideSuccess: false,
      overwrittenSimDetails: null,
      iccidNew: '',
      msisdnNew: '',
      usernameNew: '',
      passwordNew: '',
      planIdNew: '0',
      simStatusNew: '',
      orderrideError: null,
      datePickerValue: '',
      AddMinutesModal: false,
      voicemailStatusUpdateModal: false,
      voicemailLoading: false,
      addIldMinutes: 0,
      addIldMinutesConfirm: false,
      AddBalanceModal: false,
      addIRBalanceConfirm: false,
      isUpdateZipcode: false,
      addIldConfirmationModal: false,
      addIrConfirmationModal: false,
      sureBtnDisabled: true,
      eligiblePlansOpen: false,
      cancelRecurringPlanModal: false,
      newZipcode: '',
      isUnpauseModal: false,
      isILDEnabled: false,
      isIREnabled: false,
      changePhoneNumberErrorModal: false,
      portInFailsPopup: false,
      updateLoading: false,
      irPlanValueRequired: false,
      ildPlanValueRequired: false,
    });
  }

  openAddMinutesModel = () => {
    this.setState({ AddMinutesModal: true });
  };
  openAddBalanceModel = () => {
    this.setState({ AddBalanceModal: true });
  };

  handlePlanChange = (e) => {
    const { plansData } = this.state;
    let selectedPlan = plansData[e.target.value];
    if (!_.isEmpty(selectedPlan)) {
      this.setState(
        {
          selectedPlan,
          planError: false,
          samePlanError: false,
          isIldIrEnabled: false,
          isILDEnabled: false,
          isIREnabled: false,
        },
        () => {
          const { selectedPlan } = this.state;
          if (
            selectedPlan &&
            (selectedPlan.title.toLowerCase().includes('just data') ||
              selectedPlan.title.toLowerCase().includes('everything'))
          ) {
            this.setState({ isPlanDetail: true });
          } else {
            this.setState({ isPlanDetail: false });
          }
        }
      );
    } else {
      this.setState({ planError: true, isPlanDetail: false, selectedPlan: {} });
    }
  };

  handleImeiChange = (e) => {
    this.setState({
      isCheckByBrandModal: false,
      eSIMCompatible: false,
      deviceName: '',
    });
    this.setState({
      swapESimError: '',
    });
    this.setState({ deviceApiError: '' });
    const nospecial = /^[0-9]*$/;
    const number = e.target.value;
    if (number.length > 17) {
      this.setState({ deviceApiError: 'IMEI number must be of 15 digits.' });
    } else if (number.match(nospecial)) {
      this.setState({ imeiNumber: e.target.value });
      this.setState({ deviceApiError: '' });
    } else {
      this.setState({ deviceApiError: 'Please enter valid IMEI number.' });
    }
  };

  handleChangeBrand = (value) => {
    if (value === '') {
      this.setState({
        brand: '',
      });
    }
    this.setState({
      isImeiSection: false,
      eSIMCompatible: false,
      deviceName: '',
      swapESimError: '',
    });
    const brandName = value;
    const filterModel = _.filter(
      this.state.brandsArray,
      (v) => _.get(v, 'name', '') === brandName
    );
    if (filterModel && filterModel.length > 0) {
      this.setState({ modelArray: _.get(filterModel[0], 'models', []) });
    }
    this.setState({ brand: brandName, model: '' });
  };

  handleChangeModel = (e) => {
    if (e.target.value === '') {
      this.setState({
        model: '',
      });
    }
    this.setState({
      eSIMCompatible: false,
      deviceName: '',
      swapESimError: '',
    });
    const modelName = e.target.value;
    this.setState({ model: modelName });
  };

  handleErrorBox = (e) => {
    this.setState({ eSIMCompatible: true, Inprogress: false });
    this.setState({ proceed: true });
  };

  activateSimPlan = async () => {
    const {
      selectedPlan,
      simDetailData,
      newSimName,
      editSimModalOpen,
      isILDEnabled,
      isIREnabled,
      ildPlans,
      irPlans,
      isIldIrEnabled,
      ildPlanType,
      ildPlanValue,
      irPlanType,
      irPlanValue,
    } = this.state;
    const { user } = this.props;
    let error = false;
    if (_.isEmpty(selectedPlan)) {
      error = true;
      this.setState({ planError: true });
    }

    if (!isILDEnabled && !isIREnabled) {
      if (
        editSimModalOpen &&
        parseInt(simDetailData.planid) ===
          (parseInt(selectedPlan.id) || parseInt(selectedPlan.offerId)) &&
        simDetailData.ultraplan === 'IOTDVS'
      ) {
        error = true;
        this.setState({ samePlanError: true });
      }
    }
    if (isILDEnabled) {
      if (!ildPlanType) {
        error = true;
        this.setState({ ildPlanTypeRequired: true, samePlanError: false });
      }
      if (!ildPlanValue) {
        error = true;
        this.setState({ ildPlanValueRequired: true, samePlanError: false });
      }
    }
    if (isIREnabled) {
      if (!irPlanType) {
        error = true;
        this.setState({ irPlanTypeRequired: true, samePlanError: false });
      }
      if (!irPlanValue) {
        error = true;
        this.setState({
          irPlanValueRequired: true,
          remainingIRBalanceError: false,
          samePlanError: false,
        });
      }
    }
    if (!error) {
      this.setState({ updateLoading: true, samePlanError: false });
      try {
        let obj = {};
        obj.id = simDetailData.id;
        obj.simname = editSimModalOpen
          ? newSimName
          : simDetailData.simname
          ? simDetailData.simname
          : '';
        if (_.get(user, 'consumeOfferAPI', false)) {
          obj.offerId = selectedPlan.offerId;
          obj.title = selectedPlan.title;
          obj.isILDEnabled = isILDEnabled;
          obj.isIREnabled = isIREnabled;
          obj.isIldIrEnabled = isIldIrEnabled;
          obj.ildPlanType = ildPlanType;
          obj.ildPlanValue = ildPlanValue;
          obj.irPlanType = irPlanType;
          obj.irPlanValue = irPlanValue;
        } else {
          obj.plan = selectedPlan.id;
        }
        if (_.get(user, 'consumeOfferAPI', false)) {
          if (isILDEnabled) {
            const filterP = ildPlans.filter((el) => {
              return parseInt(el.titleValue) === parseInt(ildPlanValue);
            });
            if (filterP && filterP.length > 0) {
              obj.ildAddOnplanOfferId = filterP[0].offerId;
            }
          }
          if (isIREnabled) {
            const filterP = irPlans.filter((el) => {
              return parseInt(el.titleValue) === parseInt(irPlanValue);
            });
            if (filterP && filterP.length > 0) {
              obj.irAddOnplanOfferId = filterP[0].offerId;
            }
          }
        }
        const {
          value: { success },
        } = await this.props.reActivateSimByPlan(obj);
        if (success) {
          let titleText = 'Thank You';
          let descriptionText =
            'Your SIM card(s) are processing. Check the notifications icon for updates.';
          this.setState(
            {
              titleText,
              descriptionText,
              updateLoading: false,
              changePlanError: '',
              editSimModalOpen: false,
              reactivateSimModalOpen: false,
              selectedAction: '',
              isPlanDetail: false,
              selectedPlan: {},
              irPlanValue: 0,
              ildPlanValue: 0,
              isILDEnabled: false,
              isIREnabled: false,
            },
            () => {
              this.setState({ openThankYouModal: true });
            }
          );
        }
      } catch (error) {
        const err = _.get(error, 'data', error);
        this.setState({
          updateLoading: false,
          changePlanError: err.message,
          selectedAction: '',
          samePlanError: false,
        });
      }
    }
  };

  reactivateSim = async () => {
    const { selectedPlan, simDetailData, editSimModalOpen } = this.state;
    const { user } = this.props;
    if (_.isEmpty(selectedPlan)) {
      this.setState({ planError: true });
    } else if (
      editSimModalOpen &&
      parseInt(simDetailData.planid) === parseInt(selectedPlan.id) &&
      simDetailData.ultraplan === 'IOTDVS'
    ) {
      this.setState({ samePlanError: true });
    } else {
      this.setState({ updateLoading: true, samePlanError: false });
      try {
        let obj = {};
        obj.sims = [simDetailData.id];

        if (_.get(user, 'consumeOfferAPI', false)) {
          obj.offerId = selectedPlan.offerId;
          obj.title = selectedPlan.title;
        } else {
          obj.planid = selectedPlan.id;
        }
        const {
          value: { success, message },
        } = await this.props.reActivateSim(obj);
        if (success) {
          let titleText = 'Thank You';
          let descriptionText =
            'Your SIM card(s) are processing. Check the notifications icon for updates.';
          this.setState(
            {
              titleText,
              descriptionText,
              updateLoading: false,
              reactivateSimToDeactivateModalOpen: false,
              selectedAction: '',
              isPlanDetail: false,
              reactiveErrorMessage: '',
              selectedPlan: {},
            },
            () => {
              this.setState({ openThankYouModal: true });
            }
          );
        } else {
          this.setState({
            reactiveErrorMessage: message,
          });
        }
      } catch (error) {
        const err = _.get(error, 'data', error);
        this.setState({
          reactiveErrorMessage: err.message,
          updateLoading: false,
          changePlanError: err.message,
          selectedAction: '',
          samePlanError: false,
        });
      }
    }
  };

  UpdateVoicemailStatus = async () => {
    const { simDetailData } = this.state;
    this.setState({
      voicemailLoading: true,
    });
    try {
      let payload = {
        id: simDetailData.id,
        value: !this.state.voiceMailStatus,
      };
      const {
        value: { success, message },
      } = await this.props.UpdateVoicemailStatus(payload);
      if (success) {
        this.setState(
          {
            isVMApiResponse: false,
            voicemailStatusUpdateModal: false,
            voicemailLoading: false,
          },
          () => {
            this.getVoiceMailStatus(payload.id);
          }
        );
      }
      this.setState({
        voicemailLoading: false,
        errorMessage: message,
      });
    } catch (err) {
      this.setState({
        voicemailLoading: false,
        errorMessage: err.message,
      });
    }
  };

  formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }

  getNextPlanDate = () => {
    const { simDetailData, planChangedCount } = this.state;
    let nextChangeDate = _.get(simDetailData, 'nextChangeDate', false);
    let today = moment();
    if (nextChangeDate) {
      if (today < moment(nextChangeDate)) {
        nextChangeDate = moment(nextChangeDate).format('MMMM DD, YYYY');
        if (planChangedCount >= 3) {
          this.setState({ isDisabledPlanUpdate: true });
        } else {
          this.setState({ isDisabledPlanUpdate: false });
        }
      } else {
        let newNextChangeDate = moment(today).add(30, 'days').calendar();
        nextChangeDate = moment(newNextChangeDate).format('MMMM DD, YYYY');
        this.setState({ isDisabledPlanUpdate: false });
      }
    } else {
      let newNextChangeDate = moment(today).add(30, 'days').calendar();
      nextChangeDate = moment(newNextChangeDate).format('MMMM DD, YYYY');
      this.setState({ isDisabledPlanUpdate: false });
    }
    return nextChangeDate;
  };

  selectActionHandle = (e) => {
    const { simDetailData, plansData } = this.state;
    let selectedAction = e.target.value;
    let nextChangeDate = this.getNextPlanDate();
    this.setState({ selectedAction, nextChangeDate }, () => {
      if (selectedAction === 'pause') {
        this.setState({ isActionModal: true });
      } else if (selectedAction === 'inactive') {
        this.setState({ isActionModal: true });
      } else if (selectedAction === 'activate') {
        let error = false;
        let titleText = '';
        let descriptionText = '';
        let newSimName = simDetailData.simname ? simDetailData.simname : '';
        if (
          simDetailData.zipcode &&
          (simDetailData.zipcode === '' || simDetailData.zipcode.length !== 5)
        ) {
          error = true;
        }
        if (error) {
          titleText = 'Activate Info';
          descriptionText =
            'Zipcode is required to activate sim. Please enter zipcode for all selected SIMs.';
        }
        if (!error) {
          let planIndex = plansData.findIndex(
            (item) => item.title === simDetailData.title
          );
          if (planIndex > -1) {
            let selectedPlan = plansData[planIndex];
            this.setState({ selectedPlan }, () => {
              if (
                selectedPlan &&
                (selectedPlan.title.toLowerCase().includes('just data') ||
                  selectedPlan.title.toLowerCase().includes('everything'))
              ) {
                this.setState({ isPlanDetail: true });
              }
            });
          }
          this.setState({
            reactivateSimModalOpen: true,
            newSimName,
            planError: false,
          });
        } else {
          this.setState({
            titleText,
            descriptionText,
            selectedPlan: {},
            planError: false,
            selectedAction: '',
            openThankYouModal: true,
            newSimName,
          });
        }
      } else if (selectedAction === 'setsimname') {
        this.setState({
          isUpdateSimModal: true,
          newSimName: '',
          newSimNameSize: false,
          requireName: false,
        });
      } else if (selectedAction === 'adminOverride') {
        this.setState({ adminOverrrideModal: true, orderrideError: null });
      } else if (selectedAction === 'setZipcode') {
        this.setState({ isUpdateZipcode: true, orderrideError: null });
      } else if (selectedAction === 'reactivate') {
        let newSimName = simDetailData.simname ? simDetailData.simname : '';
        this.setState({
          reactivateSimToDeactivateModalOpen: true,
          newSimName,
          planError: false,
        });
      } else if (selectedAction === 'unpause') {
        const { simDetailData, plansData } = this.state;
        let planIndex = plansData.findIndex(
          (item) => item.title === simDetailData.title
        );
        this.setState({
          isUnpauseModal: true,
        });
        if (planIndex > -1) {
          let selectedPlan = plansData[planIndex];
          this.setState({ selectedPlan });
        }
      }
    });
  };

  updateSimName = async (fieldName, fieldName2) => {
    const { simDetailData } = this.state;
    if (`${this.state[fieldName2]}`.trim().length === 0) {
      this.setState({ requireName: true });
    } else if (`${this.state[fieldName2]}`.trim().length < 3) {
      this.setState({ newSimNameSize: true });
    } else {
      this.setState({ updateLoading: true });
      let obj = {};
      obj[fieldName] = this.state[fieldName2];
      obj.ids = [simDetailData.id];
      //sim details being update for change plan??? checkcase1
      try {
        const {
          value: { success },
        } = await this.props.updateSimName(obj);
        if (success) {
          simDetailData[fieldName] = this.state[fieldName2];
          this.setState(
            {
              simDetailData,
              updateLoading: false,
              isUpdateSimModal: false,
              newSimName: '',
              selectedAction: '',
              editSimModalOpen: false,
              isUpdateZipcode: false,
              newZipcode: '',
              errorMessage: '',
            },
            () => {
              this.getSimDetails();
            }
          );
        }
      } catch (err) {
        const error = _.get(err, 'data', err);
        this.setState({
          updateLoading: false,
          isUpdateSimModal: false,
          selectedAction: '',
          errorMessage: _.get(error, 'message', 'Some thing went wrong...'),
        });
      }
    }
  };

  updateIldIrInfo = async (key, type) => {
    this.setState({ updateLoading: true });
    const { simDetailData } = this.state;
    const obj = {
      ids: [simDetailData.id],
      value: !simDetailData[key],
      type,
    };
    try {
      const {
        value: { success, message },
      } = await this.props.bulkManageAddOns(obj);
      if (success) {
        this.getSimDetails();
      } else {
        this.setState({
          updateLoading: false,
          addIldConfirmationModal: false,
          addIrConfirmationModal: false,
        });
        notification['error']({
          message: message,
        });
      }
    } catch (err) {
      this.setState({
        updateLoading: false,
        addIldConfirmationModal: false,
        addIrConfirmationModal: false,
        errorMessage: _.get(err, 'message', 'Some thing went wrong...'),
      });
    }
  };

  onChangeSwitch(key) {
    if (key === 'isIldIrEnabled') {
      this.setState({ isIldIrEnabled: !this.state[key] }, () => {});
    }
    if (key === 'isILDEnabled') {
      this.setState({
        ildPlanValue: 0,
        [key]: !this.state[key],
        isIldIrEnabled: false,
        ildPlanValueRequired: false,
      });
    }
    if (key === 'isIREnabled') {
      this.setState({
        irPlanValue: 0,
        [key]: !this.state[key],
        isIldIrEnabled: false,
        irPlanValueRequired: false,
        remainingIRBalanceError: false,
      });
    } else {
      this.setState({
        [key]: !this.state[key],
        isIldIrEnabled: false,
      });
    }
  }

  handleTagChange = (tags) => {
    this.setState({ isTagChanged: true });
    if (tags.length > 0) {
      let tagSizeError = false;
      _.forEach(tags, (tag) => {
        if (tag.trim().length < 3) {
          tagSizeError = true;
        }
      });
      if (tagSizeError) {
        this.setState({ newTagSize: true });
      } else {
        this.setState({ newTagSize: false, newTag: tags });
      }
    } else {
      this.setState({ newTag: [] });
    }
  };

  createTagsModal = () => {
    const { simDetailData } = this.state;
    if (!_.isEmpty(simDetailData)) {
      let tagsArr = [];
      if (simDetailData.tags && simDetailData.tags !== '') {
        let arr = simDetailData.tags.split(',');
        tagsArr.push(...arr);
      }
      let uniquesTags = tagsArr.filter((v, i, a) => a.indexOf(v) === i);
      let originalTags = tagsArr.filter((v, i, a) => a.indexOf(v) === i);
      this.setState({
        selectedListTags: uniquesTags,
        newTagSize: false,
        newTag: [],
        originalSelectedTags: originalTags,
        isApplyButton: false,
      });
    } else {
      this.setState({
        selectedListTags: [],
        originalSelectedTags: [],
        newTag: [],
        isApplyButton: false,
      });
    }
    this.setState({ isCreateTagModal: true });
  };

  saveTag = async () => {
    const { newTag, simDetailData } = this.state;
    this.setState({ updateLoading: true });
    let applyTags = newTag ? newTag.join(',') : '';
    let obj = {};
    obj.sims = [];
    obj.sims = [{ id: simDetailData.id, tags: applyTags }];
    try {
      const {
        value: { success },
      } = await this.props.updateTag(obj);
      if (success) {
        this.setState(
          {
            isCreateTagModal: false,
            isTagChanged: false,
            newTag: [],
            selectedListTags: [],
            updateLoading: false,
          },
          () => {
            this.updateTagsLocal(applyTags);
          }
        );
      }
    } catch (err) {
      const error = _.get(err, 'data', err);
      this.setState({
        updateLoading: false,
        errorMessage: _.get(error, 'message', 'Some thing went wrong...'),
      });
    }
  };

  updateTagsLocal = (applyTags) => {
    let selectedSims = this.state.simDetailData;
    selectedSims.tags = applyTags;
    this.setState({ simDetailData: selectedSims }, () => {
      this.forceUpdate();
    });
  };

  // Action Popup Methods
  pauseSim = async () => {
    const { simDetailData } = this.state;
    this.setState({ updateLoading: true });
    let obj = {
      action: 'pause',
      sims: [simDetailData.id],
    };
    try {
      const {
        value: { success },
      } = await this.props.pauseSims(obj);
      if (success) {
        this.simcardDetail();
        this.setState({
          isActionModal: false,
          selectedAction: '',
          updateLoading: false,
        });
      }
    } catch (err) {
      const error = _.get(err, 'data', err);
      this.setState({
        isActionModal: false,
        selectedAction: '',
        updateLoading: false,
        errorMessage: _.get(error, 'message', 'Some thing went wrong...'),
      });
    }
  };

  deactivateSim = async () => {
    const { simDetailData } = this.state;
    this.setState({ updateLoading: true });
    let obj = {};
    obj.action = 'deactivate';
    obj.sims = [simDetailData.id];
    try {
      const {
        value: { success },
      } = await this.props.deactivateSims(obj);
      if (success) {
        this.setState({
          isActionModal: false,
          selectedAction: '',
          updateLoading: false,
        });
      }
    } catch (err) {
      const error = _.get(err, 'data', err);
      this.setState({
        isActionModal: false,
        selectedAction: '',
        updateLoading: false,
        errorMessage: _.get(error, 'message', 'Some thing went wrong...'),
      });
    }
  };

  viewDetailedUsage = (item) => {
    const { simDetailData } = this.state;
    if (simDetailData) {
      let date = item.month + '-' + item.year;
      this.props.history.push(`/usage/${simDetailData.simnumber}/${date}`);
    }
  };

  onClose = () => {
    document.body.style.overflow = 'visible';
    this.setState({
      viewApnDrawer: false,
      viewPortInDrawer: false,
    });
  };

  // Swap SIM Functionality
  swapSimModal = () => {
    this.setState({
      swapSimConfirmingModalOpen: true,
      searchVal: '',
      errorMessage: '',
    });
  };

  onSearch = async (event) => {
    this.setState({ searchVal: event.target.value.trim(), errorMessage: '' });
    if (
      event.target.value.trim() !== '' &&
      /^[0-9]+$/.test(event.target.value.trim())
    ) {
      let searchText = event.target.value.trim();
      this.setState({ swapSimError: '' });
      try {
        const {
          value: { success, data },
        } = await this.props.getSwapSimSearch(
          searchText,
          this.state.simDetailData.simtype,
          this.state.pSimFilter ? 0 : 1
        );
        if (success) {
          this.setState({ showSearchDropdown: true, searchData: data.data });
        }
      } catch (error) {
        message.error({
          key: 'onSearch',
          content: _.get(
            error,
            'data.message',
            'Error in processing the request!'
          ),
        });
      }
    } else {
      this.setState({ showSearchDropdown: false, searchData: [] });
    }
  };

  swapSim = async () => {
    const { simDetailData, searchVal } = this.state;
    if (searchVal.length === 0) {
      this.setState({
        errorMessage: '',
        swapSimError: 'Please enter new ICCID.',
      });
    } else {
      this.setState({ updateLoading: true, swapSimError: '' });
      try {
        let obj = {};
        obj.id = simDetailData.id;
        obj.simnumber = searchVal;
        const {
          value: { success },
        } = await this.props.swapSIM(obj);
        if (success) {
          this.setState({
            swapSimModalOpen: false,
            updateLoading: false,
            pSimFilter: false,
          });
          let querystring = `/detail/${searchVal}`;
          if (_.get(window, 'location.pathname', '') !== querystring) {
            if (window.history.pushState) {
              var newurl =
                window.location.protocol +
                '//' +
                window.location.host +
                querystring.toString();
              window.history.pushState({ path: newurl }, '', newurl);
              this.setState({ isLoading: true }, () => {
                this.getSimDetails();
              });
            }
          }
        }
      } catch (error) {
        const err = _.get(error, 'data', error);
        this.setState({
          updateLoading: false,
          errorMessage: err.message,
        });
      }
    }
  };
  swapESim = async () => {
    const { simDetailData } = this.state;
    this.setState({ updateEsimLoading: true, swapESimError: '' });
    try {
      let obj = {};
      obj.id = simDetailData.id;
      obj.simnumber = this.state.searchVal

      const {
        value: { success, eSim },
      } = await this.props.swapESIM(obj);
      if (success) {
        const {
          value: { success, data },
        } = await this.props.getEsimInfo(eSim);
        if (success) {
          this.setState({
            updateEsimLoading: false,
            eSimObj: data,
            enableQRCodeModal: true,
            eSimFilter: false,
          });
        } else {
          this.setState({
            updateEsimLoading: false,
            enableQRCodeModal: false,
            swapESimError: 'Error While processing the request.',
            eSimFilter: false,
          });
        }
      }
    } catch (error) {
      const err = _.get(error, 'data', error);
      this.setState({ updateEsimLoading: false, swapESimError: err.message });
    }
  };

  managePortInPlanChange = (e) => {
    this.setState({ selectedSimPlanPortIn: e.target.value });
  };

  updatePortIn = async () => {
    let simData = this.state.simDetailData;
    const {
      phoneNumberPortIn,
      carrierPortIn,
      carrierACNumberPortIn,
      carrierAssignPasswordportIn,
      name,
      street1,
      street2,
      city,
      state,
      zip,
      zip4,
      zipcodePortIn,
      alternateContactPortIn,
      selectedSimPlanPortIn,
    } = this.state;
    let noError = true;
    this.setState({ errorMessage: '' });
    if (phoneNumberPortIn === '') {
      noError = false;
      this.setState({ requirePortInPhoneNumber: true });
    }
    if (carrierACNumberPortIn === '') {
      noError = false;
      this.setState({ requireCarrierACNumber: true });
    }
    if (carrierAssignPasswordportIn === '') {
      noError = false;
      this.setState({ requireCarrierAssignPassword: true });
    }
    if (name.length > 25) {
      noError = false;
      this.setState({ nameError: true });
    }
    if (zipcodePortIn === '') {
      noError = false;
      this.setState({ requireZipcodePortIn: true });
    }
    if (alternateContactPortIn === '') {
      noError = false;
      this.setState({ requireAlternateContactPortIn: true });
    }
    if (selectedSimPlanPortIn === 0 || selectedSimPlanPortIn === '0') {
      noError = false;
    }
    if (noError) {
      this.setState({ updatePortInLoading: true, portInDisabled: true });
      let obj = {
        msisdn: phoneNumberPortIn.replace(/([^0-9]+)/gi, ''),
        accountNumber: carrierACNumberPortIn,
        accountPassword: carrierAssignPasswordportIn,
        name,
        street1,
        street2,
        city,
        state,
        zip,
        zip4,
        zipcode: zipcodePortIn,
        simNumber: simData.simnumber,
        simType: simData.simtype,
        carrier: carrierPortIn,
        alternateContact: alternateContactPortIn.replace(/([^0-9]+)/gi, ''),
        planId: selectedSimPlanPortIn,
        isEsim: simData.isEsim,
      };
      try {
        const {
          value: { success, errorKey, message, portInFailError },
        } = await this.props.updateSimPortInRequest(obj);
        if (success) {
          this.setState(
            {
              updatePortInRequestModal: false,
              updatePortInLoading: false,
              portInDisabled: false,
            },
            () => {
              this.getSimDetails();
            }
          );
        } else {
          if (errorKey && errorKey === 'zipcode') {
            this.setState({
              updatePortInLoading: false,
              errorMessage: '',
              zipcodeErrorMessage: message
                ? message
                : 'Some thing went wrong...',
              portInDisabled: false,
            });
          } else if (simData.isEsim && portInFailError) {
            this.setState({
              updatePortInLoading: false,
              zipcodeErrorMessage: '',
              errorMessage: message ? message : 'Some thing went wrong...',
              portInDisabled: false,
              portInFailsPopup: true,
              updatePortInRequestModal: false,
            });
          } else {
            this.setState({
              updatePortInLoading: false,
              zipcodeErrorMessage: '',
              errorMessage: message ? message : 'Some thing went wrong...',
              portInDisabled: false,
            });
          }
        }
      } catch (err) {
        const error = _.get(err, 'data', err);
        this.setState({
          updatePortInLoading: false,
          zipcodeErrorMessage: '',
          errorMessage: _.get(error, 'message', 'Some thing went wrong...'),
          portInDisabled: false,
        });
      }
    }
  };

  openUpdatePortInModel = () => {
    const { activePortInReqData } = this.state;
    this.setState({
      updatePortInRequestModal: true,
      updatePortInLoading: false,
      phoneNumberPortIn: activePortInReqData ? activePortInReqData.msisdn : '',
      carrierACNumberPortIn: activePortInReqData
        ? activePortInReqData.accountNumber
        : '',
      carrierAssignPasswordportIn: activePortInReqData
        ? activePortInReqData.accountPassword
        : '',
      zipcodePortIn: activePortInReqData ? activePortInReqData.zipcode : '',
      requirePortInPhoneNumber: false,
      requireCarrierACNumber: false,
      requireCarrierAssignPassword: false,
      requireZipcodePortIn: false,
    });
  };

  openCancelPortInModel = () => {
    this.setState({ cancelPortInRequestModal: true });
  };

  cancelSimPortIn = async (item) => {
    try {
      this.setState({ cancelPortInLoader: true, portInDisabled: true });
      let simData = this.state.simDetailData;
      let obj = {
        simId: simData.id,
      };
      const {
        value: { success },
      } = await this.props.simPortInCancel(obj);
      if (success) {
        this.setState(
          {
            cancelPortInLoader: false,
            cancelPortInRequestModal: false,
            errorMessage: '',
            portInDisabled: true,
          },
          () => {
            this.getSimDetails();
          }
        );
      }
    } catch (err) {
      const error = _.get(err, 'data', err);
      this.setState({
        cancelPortInLoader: false,
        portInDisabled: false,
        errorMessage: _.get(error, 'message', 'Some thing went wrong...'),
      });
    }
  };

  checkOrderHistory = () => {
    const { simDetailData } = this.state;
    let orderId = _.get(simDetailData, 'orderid', '');
    if (orderId !== '') {
      this.props.history.push(`/order-detail/${orderId}`);
    }
  };

  handleSubmit = async (
    { address, addressline1, addressline2, ...rest },
    { setSubmitting, setErrors }
  ) => {
    this.setState({ errorMessage: '' });
    const simData = this.state.simDetailData;
    try {
      setSubmitting(true);
      let payload = {
        simId: simData.id,
        simNumber: simData.simnumber,
        addressLine1: address ? address : addressline1,
        addressLine2: addressline2,
        city: rest.city,
        state: rest.state,
        zipcode: rest.zipcode,
        simtype: simData.simtype,
      };
      const {
        value: { success },
      } = await this.props.enableWifiCalling(payload);
      if (success) {
        this.closeModal();
        this.setState({ wifiCallingModalOpen: false, errorMessage: '' });
        let titleText = 'Thank You';
        let descriptionText =
          'We have submitted the request and it will be updated momentarily.';
        this.setState(
          {
            titleText,
            descriptionText,
            wifiCallingModalOpen: false,
            errorMessage: '',
          },
          () => {
            this.setState({ openThankYouModal: true });
          }
        );
      }
    } catch (err) {
      const error = err.data;
      const errorMsg = error.error;
      setSubmitting(false);
      this.setState({
        errorMessage:
          errorMsg === 'INVALID_E911_ADDRESS'
            ? 'INVALID E911 ADDRESS'
            : 'Error in processing the request. Please try again later!',
      });
    }
  };

  refreshPortInStatus = async (portinData) => {
    try {
      this.setState({ refreshPortIn: true });
      let obj = {
        simId: portinData.simcardId,
      };
      const {
        value: { success },
      } = await this.props.refreshPortInStatus(obj);
      if (success) {
        this.setState({ refreshPortIn: false }, () => {
          this.getSimDetails();
        });
      } else {
        this.setState({ refreshPortIn: false });
      }
    } catch (err) {
      this.setState({ refreshPortIn: false });
      message.error({
        key: 'updatable',
        content: 'Error in processing the request!',
      });
    }
  };

  reconnectNetwork = async () => {
    const reconnectWaitTime = parseInt(this.state.reconnectWaitTime);
    try {
      const simData = this.state.simDetailData;
      this.setState({
        isReconnectLoading: true,
        reconnectNetworkDisabled: true,
      });
      let obj = {
        simIccid: simData.simnumber,
        simtype: simData.simtype,
      };
      const {
        value: { success },
      } = await this.props.reconnectNetwork(obj);
      if (success) {
        this.setState(
          {
            isReconnectLoading: false,
            successModalOpen: true,
            reconnectErrorMessage: '',
          },
          () => {
            this.waitForReconnect(reconnectWaitTime);
          }
        );
      } else {
        this.setState({ isReconnectLoading: false, failedModalOpen: true });
      }
    } catch (err) {
      const error = _.get(err, 'data', err);
      this.setState({
        isReconnectLoading: false,
        failedModalOpen: true,
        reconnectErrorMessage: error.message,
      });
      this.waitForReconnect(reconnectWaitTime);
    }
  };

  waitForReconnect = (sec) => {
    const self = this;
    const reconnectWaitTime = parseInt(this.state.reconnectWaitTime);
    let count = sec;
    interval = setInterval(function () {
      self.setState({
        time: count,
        showTimer: true,
        reconnectNetworkDisabled: true,
      });
      if (count === 0) {
        self.setState({
          time: reconnectWaitTime,
          showTimer: false,
          reconnectNetworkDisabled: false,
        });
        clearInterval(interval);
      }
      count--;
    }, 1000);
  };

  Capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  showStatus = (s) => {
    s = String(s).toLowerCase();
    let str = '';
    switch (s) {
      case 'denied':
        str = 'Denied';
        break;
      case 'inprogress':
        str = 'In-Progress';
        break;
      case 'pending':
        str = 'Pending';
        break;
      case 'resolutionrequired':
        str = 'Resolution Required';
        break;
      case 'cancelled':
        str = 'Cancelled';
        break;
      case 'complete':
        str = 'Complete';
        break;
      default:
        str = '';
        break;
    }
    return str;
  };

  cancelOverrideModal = async () => {
    this.setState({
      adminOverrrideModal: false,
      iccidNew: '',
      msisdnNew: '',
      usernameNew: '',
      passwordNew: '',
      simStatusNew: '',
      portInStatusNew: '',
    });
  };

  onChangeDate = (date) => {
    this.setState({ datePickerValue: date });
  };

  overrideFun = async () => {
    const simData = this.state.simDetailData;
    const error = {};
    const {
      iccidNew,
      msisdnNew,
      usernameNew,
      passwordNew,
      planIdNew,
      simStatusNew,
      portInStatusNew,
      datePickerValue,
    } = this.state;
    this.setState({ errorMessage: '', orderrideError: null });

    try {
      let phone = '';
      if (simStatusNew === '') {
        error.simStatusNew = 'This field is required';
      }
      if (parseInt(simStatusNew) === 10) {
        if (simData.msisdn === '' && msisdnNew === '') {
          error.msisdnNew = 'This field is required';
        }
        if (simData.username === '' && usernameNew === '') {
          error.usernameNew = 'This field is required';
        }
        if (simData.password === '' && passwordNew === '') {
          error.passwordNew = 'This field is required';
        }
        if (parseInt(simData.planid) === 0 && parseInt(planIdNew) === 0) {
          error.planIdNew = 'This field is required';
        }
        if (
          (simData.activationdate === null || simData.activationdate === '') &&
          datePickerValue === ''
        ) {
          error.datePickerValue = 'This field is required';
        }
      }
      if (parseInt(simStatusNew) === 11) {
        if (datePickerValue === '') {
          error.datePickerValue = 'This field is required';
        }
        if (simData.username === '' && usernameNew === '') {
          error.usernameNew = 'This field is required';
        }
        if (simData.password === '' && passwordNew === '') {
          error.passwordNew = 'This field is required';
        }
      }
      if (parseInt(simStatusNew) === 99) {
        if (datePickerValue === '') {
          error.datePickerValue = 'This field is required';
        }
        if (simData.username === '' && usernameNew === '') {
          error.usernameNew = 'This field is required';
        }
        if (simData.password === '' && passwordNew === '') {
          error.passwordNew = 'This field is required';
        }
      }
      if (iccidNew !== '' && iccidNew.length < 19) {
        error.iccidNew = 'Enter valid iccid';
      }
      if (msisdnNew !== '') {
        phone = msisdnNew.replace(/([^0-9]+)/gi, '');
        if (phone.length < 10) {
          error.msisdnNew = 'Enter valid msisdn number';
        }
      }
      this.setState({ orderrideError: error });
      if (Object.keys(error).length === 0) {
        this.setState({ updateLoading: true });
        phone = msisdnNew.replace(/([^0-9]+)/gi, '');
        let payload = {
          id: simData.id,
          status: parseInt(simStatusNew),
        };

        if (String(iccidNew) !== '') {
          payload.iccid = iccidNew;
        }
        if (String(phone) !== '') {
          payload.msisdn = String(phone);
        }
        if (String(usernameNew) !== '') {
          payload.username = String(usernameNew);
        }
        if (String(passwordNew) !== '') {
          payload.password = String(passwordNew);
        }
        if (parseInt(planIdNew) > 0) {
          payload.plan = parseInt(planIdNew);
        }
        if (portInStatusNew !== '') {
          payload.portInStatus = String(portInStatusNew);
        }
        if (datePickerValue !== '') {
          payload.datePickerValue =
            moment(datePickerValue).format('YYYY/MM/DD');
        }

        const {
          value: { success },
        } = await this.props.adminOverride(payload);
        if (success) {
          delete payload.id;
          if (parseInt(simData.status) === parseInt(simStatusNew)) {
            delete payload.status;
          }
          if (payload.datePickerValue && payload.datePickerValue !== '') {
            if (parseInt(simStatusNew) === 10) {
              payload.activationdate = payload.datePickerValue;
            }
            if (parseInt(simStatusNew) === 11) {
              payload.deactivationdate = payload.datePickerValue;
            }
            if (parseInt(simStatusNew) === 99) {
              payload.pausedate = payload.datePickerValue;
            }
          }
          delete payload.datePickerValue;
          this.setState(
            {
              adminOverrrideModal: false,
              adminOverrideSuccess: true,
              overwrittenSimDetails: payload,
              updateLoading: false,
              errorMessage: '',
            },
            () => {
              this.getSimDetails();
            }
          );
        }
      }
    } catch (err) {
      this.setState({ updateLoading: false, errorMessage: err.message });
    }
  };

  renderOverrideResult = (overwrittenSimDetails) => {
    let updatedValues = [];
    const { plansData } = this.state;
    const { user } = this.props;
    Object.keys(overwrittenSimDetails).forEach(function (key) {
      let prop = key;
      let propValue = overwrittenSimDetails[key];

      if (prop === 'status') {
        propValue =
          propValue === 10
            ? 'Active'
            : propValue === 0
            ? 'Available-Sims'
            : propValue === 99
            ? 'Paused'
            : propValue === 11
            ? 'Deactivated'
            : 'Activation-In-progress';
        prop = 'Status';
      } else if (prop === 'iccid') {
        prop = 'Iccid';
      } else if (prop === 'msisdn') {
        prop = 'Msisdn';
      } else if (prop === 'username') {
        prop = 'Username';
      } else if (prop === 'password') {
        prop = 'Password';
      } else if (prop === 'plan') {
        let plan = [];
        if (_.get(user, 'consumeOfferAPI', false)) {
          plan = _.filter(plansData, { offerId: propValue });
        } else {
          plan = _.filter(plansData, { id: propValue });
        }
        if (plan && plan.length > 0) {
          propValue = plan[0].title;
        }
        prop = 'Plan';
      } else if (prop === 'activationdate') {
        prop = 'Activation Date';
      } else if (prop === 'deactivationdate') {
        prop = 'Deactivation Date';
      } else if (prop === 'pausedate') {
        prop = 'Paused Date';
      } else if (prop === 'portInStatus') {
        prop = 'Port-In Status';
      }
      updatedValues.push({ key: prop, value: propValue });
    });
    return (
      _.size(updatedValues) > 0 &&
      updatedValues.map((object, index) => {
        return (
          <p className="btn-link">
            {object.key} : {object.value}
          </p>
        );
      })
    );
  };

  changePhoneNumber = async () => {
    const { simDetailData, newNumberZipcode } = this.state;
    this.setState({
      changePhoneNumberError: '',
      changePhoneNumberLoading: true,
    });
    try {
      let error = '';
      if (newNumberZipcode === '') {
        error = 'zipcode is required.';
        this.setState({
          changePhoneNumberError: error,
          changePhoneNumberLoading: false,
        });
      } else if (newNumberZipcode.length !== 5) {
        error = 'zipcode must be of 5 digits.';
        this.setState({
          changePhoneNumberError: error,
          changePhoneNumberLoading: false,
        });
      }
      if (error === '') {
        let payload = {
          id: simDetailData.id,
          zipcode: newNumberZipcode,
          simtype: simDetailData.simtype,
        };
        const {
          value: { success },
        } = await this.props.changePhoneNumber(payload);
        if (success) {
          this.setState(
            {
              changePhoneNumberModal: false,
              newNumberZipcode: '',
              changePhoneNumberError: '',
              changePhoneNumberLoading: false,
              changePhoneNumberSuccessModal: true,
            },
            () => {
              this.getSimDetails();
            }
          );
        }
      }
    } catch (err) {
      if (
        _.get(err, 'data.message', 'Error while processing the request.') ===
        'Invalid MSISDN.'
      ) {
        this.setState({
          changePhoneNumberModal: false,
          changePhoneNumberLoading: false,
          changePhoneNumberError: _.get(
            err,
            'data.message',
            'Error while processing the request.'
          ),
          changePhoneNumberErrorModal: true,
        });
      } else {
        this.setState({
          changePhoneNumberLoading: false,
          changePhoneNumberError: _.get(
            err,
            'data.message',
            'Error while processing the request.'
          ),
        });
      }
    }
  };

  componentWillUnmount() {
    clearInterval(interval);
  }
  // eslint-disable-next-line
  onChangeDate = (date) => {
    this.setState({
      datePickerValue: date,
      orderrideError: { ...this.state.orderrideError, datePickerValue: '' },
    });
  };

  getEsimQuantity = async () => {
    const {
      value: { isQuantityZero },
    } = await this.props.getEsimQuantity();
    this.setState({ updateLoading: false });
    if (isQuantityZero) {
      this.setState({
        isEsimAvailable: true,
        swapSimConfirmingModalOpen: false,
      });
    } else {
      this.setState({
        convertesimModal: true,
        swapSimConfirmingModalOpen: false,
      });
    }
  };

  onSideTabChange = (tab) => {
    if (tab === 'active') {
      this.props.history.push('/active-sims');
      document.title = 'Active SIMs | UltraIoT';
    } else if (tab === 'activationInProgress') {
      this.props.history.push('/activation-in-progress');
      document.title = 'Activation In Progress SIMs | UltraIoT';
    } else if (tab === 'inactive') {
      this.props.history.push('/available-sims');
      document.title = 'Available SIMs | UltraIoT';
    } else if (tab === 'availableeSim') {
      this.props.history.push('/available-esims');
      document.title = 'Available eSIMs | UltraIoT';
    } else if (tab === 'paused') {
      this.props.history.push('/paused-sims');
      document.title = 'Paused SIMs | UltraIoT';
    } else if (tab === 'deactive') {
      this.props.history.push('/deactivated-sims');
      document.title = 'Deactived SIMs | UltraIoT';
    } else if (tab === 'spr-orders') {
      this.props.history.push('/spr-orders');
      document.title = 'Orders | UltraIoT';
    } else if (tab === 'fixed-active') {
      this.props.history.push('/fixed-active-sims');
      document.title = 'Fixed Orders | UltraIoT';
    } else if (tab === 'fixed-activationInProgress') {
      this.props.history.push('/fixed-activation-in-progress');
      document.title = 'Fixed Activation In Progress SIMs | UltraIoT';
    } else if (tab === 'fixed-inactive') {
      this.props.history.push('/fixed-never-been-activated');
      document.title = 'Fixed Never Been Activated SIMs | UltraIoT';
    } else if (tab === 'fixed-paused') {
      this.props.history.push('/fixed-paused-sims');
      document.title = 'Fixed Paused SIMs | UltraIoT';
    } else if (tab === 'fixed-deactive') {
      this.props.history.push('/fixed-deactivated-sims');
      document.title = 'Fixed Deactived SIMs | UltraIoT';
    } else if (tab === 'fixed-orders') {
      this.props.history.push('/fixed-orders');
      document.title = 'Fixed Orders | UltraIoT';
    } else if (tab === 'eSIM-orders') {
      this.props.history.push('/eSIM-orders');
      document.title = 'eSIM Orders | UltraIoT';
    }
  };

  // function to handle ILD/IR addition for a particular simcard
  updateAddOnPlans = async (type) => {
    const { user } = this.props;
    if (type === 'ild') {
      const { simDetailData, addIldMinutes, useInterval, ildPlans } =
        this.state;
      this.setState({
        addIldMinutesConfirm: true,
      });

      if (addIldMinutes === 0) {
        this.setState({
          addIldMinutesConfirm: false,
          errorMessage: 'Please add at least 50 ILD minutes.',
        });
      } else {
        try {
          let payload = {
            type: type,
            id: simDetailData.id,
            addValue: addIldMinutes,
            paymentType: this.state.addIldMinsPayType,
            currentPlan: simDetailData.productid,
            offerId: simDetailData.offerId,
            useInterval,
          };
          if (_.get(user, 'consumeOfferAPI', false)) {
            const filterP = ildPlans.filter((el) => {
              return parseInt(el.titleValue) === parseInt(addIldMinutes);
            });
            if (filterP && filterP.length > 0) {
              payload.addOnplanOfferId = filterP[0].offerId;
            }
          }
          const {
            value: { success, statusCode },
          } = await this.props.updateAddOnPlans(payload);
          if (success && statusCode === 409) {
            this.setState({
              addIldMinutes: 0,
              sureBtnDisabled: true,
              addIldMinutesConfirm: false,
              AddMinutesModal: false,
              eligiblePlansOpen: true,
              useInterval: true,
            });
          } else {
            this.setState({
              sureBtnDisabled: true,
              addIldMinutes: 0,
              addIldMinutesConfirm: false,
              AddMinutesModal: false,
              openThankYouModal: true,
              descriptionText:
                'We have submitted the request and it will be updated momentarily',
              useInterval: true,
            });
          }
          setTimeout(() => {
            this.setState({ useInterval: false });
          }, 60000);
        } catch (err) {
          const error = _.get(err, 'data', err);
          this.setState({
            addIldMinutes: 0,
            addIldMinutesConfirm: false,
            errorMessage: error.message,
          });
        }
      }
    } else {
      const {
        simDetailData,
        addIRBalance,
        remainingIRBalance,
        useInterval,
        irPlans,
      } = this.state;
      this.setState({
        addIRBalanceConfirm: true,
      });

      if (addIRBalance === 0) {
        this.setState({
          addIRBalanceConfirm: false,
          errorMessage: 'Please add at least $5 for IR.',
        });
      } else {
        try {
          if (parseInt(addIRBalance) > remainingIRBalance) {
            const err = { message: 'You have insufficient balance' };
            throw err;
          }
          let payload = {
            type: type,
            id: simDetailData.id,
            addValue: addIRBalance,
            paymentType: this.state.addIrPayType,
            currentPlan: simDetailData.productid,
            offerId: simDetailData.offerId,
            useInterval,
          };
          if (_.get(user, 'consumeOfferAPI', false)) {
            const filterP = irPlans.filter((el) => {
              return parseInt(el.titleValue) === parseInt(addIRBalance);
            });
            if (filterP && filterP.length > 0) {
              payload.addOnplanOfferId = filterP[0].offerId;
            }
          }
          const {
            value: { success, statusCode },
          } = await this.props.updateAddOnPlans(payload);
          if (success && statusCode === 409) {
            this.setState({
              sureBtnDisabled: true,
              addIRBalance: 0,
              addIRBalanceConfirm: false,
              AddBalanceModal: false,
              eligiblePlansOpen: true,
              useInterval: true,
            });
          } else {
            this.setState({
              sureBtnDisabled: true,
              addIRBalance: 0,
              addIRBalanceConfirm: false,
              AddBalanceModal: false,
              openThankYouModal: true,
              descriptionText:
                'We have submitted the request and it will be updated momentarily',
              useInterval: true,
            });
          }
          setTimeout(() => {
            this.setState({ useInterval: false });
          }, 60000);
        } catch (err) {
          const error = _.get(err, 'data', err);
          this.setState({
            addIRBalance: 0,
            addIRBalanceConfirm: false,
            errorMessage: error.message,
          });
        }
      }
    }
  };

  // function too restrict user from entering special characters in acc.No & pass(for update portIn-request)
  restrictSpecialCharacters = (e) => {
    const nospecial = /^[A-Za-z0-9]*$/;
    const alphanumeric = /^[A-Za-z0-9]*$/;
    const textValue = e.target.value;
    const tagName = e.target.name;

    if (textValue.match(nospecial)) {
      if (tagName === 'carrierACNumber') {
        if (textValue.match(alphanumeric)) {
          this.setState({
            carrierACNumberPortIn: textValue,
            requireCarrierACNumber: false,
          });
        }
      } else {
        this.setState({
          carrierAssignPasswordportIn: textValue,
          requireCarrierAssignPassword: false,
        });
      }
    }
  };

  cancelRecurringPayment = async (type) => {
    const {
      simDetailData: { simnumber, productid },
      recurringType,
    } = this.state;
    const obj = {
      type: recurringType,
      currentPlan: productid,
      iccid: simnumber,
    };
    this.setState({ updateLoading: true });
    const {
      value: { success },
    } = await this.props.cancelRecurringPayment(obj);
    if (success) {
      this.setState(
        { updateLoading: false, cancelRecurringPlanModal: false },
        () => {
          this.getSimDetails();
        }
      );
    }
  };

  unPauseSim = async () => {
    const {
      simDetailData,
      selectedPlan,
      isILDEnabled,
      isIREnabled,
      isIldIrEnabled,
      ildPlanType,
      ildPlanValue,
      irPlanType,
      irPlanValue,
      ildPlans,
      irPlans,
    } = this.state;
    const { user } = this.props;
    if (_.isEmpty(selectedPlan)) {
      this.setState({ planError: true });
    } else {
      let required = false;
      if (isILDEnabled) {
        if (ildPlanType === '') {
          required = true;
          this.setState({ ildPlanTypeRequired: true });
        }
        if (ildPlanValue === 0) {
          required = true;
          this.setState({ ildPlanValueRequired: true });
        }
      }
      if (isIREnabled) {
        if (irPlanType === '') {
          required = true;
          this.setState({ irPlanTypeRequired: true });
        }
        if (irPlanValue === 0) {
          required = true;
          this.setState({ irPlanValueRequired: true });
        }
      }

      if (!required) {
        this.setState({ updateLoading: true, planError: false });
        try {
          let obj = {
            sims: [simDetailData.id],
            isILDEnabled: isILDEnabled,
            isIREnabled: isIREnabled,
            isIldIrEnabled: isIldIrEnabled,
            ildPlanType,
            ildPlanValue,
            irPlanType,
            irPlanValue,
          };

          if (_.get(user, 'consumeOfferAPI', false)) {
            obj.offerId = selectedPlan.offerId;
            obj.title = selectedPlan.title;
            if (isILDEnabled) {
              const filterP = ildPlans.filter((el) => {
                return parseInt(el.titleValue) === parseInt(ildPlanValue);
              });
              if (filterP && filterP.length > 0) {
                obj.ildAddOnplanOfferId = filterP[0].offerId;
              }
            }
            if (isIREnabled) {
              const filterP = irPlans.filter((el) => {
                return parseInt(el.titleValue) === parseInt(irPlanValue);
              });
              if (filterP && filterP.length > 0) {
                obj.irAddOnplanOfferId = filterP[0].offerId;
              }
            }
          } else {
            obj.planid = selectedPlan.id;
          }
          const {
            value: { success, message },
          } = await this.props.unPauseSim(obj);
          if (success) {
            let titleText = 'Thank You';
            let descriptionText =
              'Your SIM card(s) are processing. Check the notifications icon for updates.';
            this.setState(
              {
                titleText,
                descriptionText,
                updateLoading: false,
                selectedAction: '',
                isUnpauseModal: false,
                unPauseSimError: '',
                isIldIrEnabled: false,
                isILDEnabled: false,
                isIREnabled: false,
                ildPlanType: '',
                ildPlanValue: 0,
                irPlanType: '',
                irPlanValue: 0,
              },
              () => {
                this.setState({ openThankYouModal: true });
              }
            );
          } else {
            this.setState({
              unPauseSimError: message,
              updateLoading: false,
            });
          }
        } catch (error) {
          const err = _.get(error, 'data', error);
          this.setState({
            updateLoading: false,
            unPauseSimError: err.message,
          });
        }
      }
    }
  };

  onChangeSwitchForUnpause(key) {
    if (key === 'isIldIrEnabled') {
      this.setState({ isIldIrEnabled: !this.state[key] }, () => {});
    } else {
      this.setState({
        [key]: !this.state[key],
        isIldIrEnabled: false,
      });
    }
  }

  setSortByKeys = async (key, order) => {
    this.setState({ sortBy: key, sortOrder: order });
  };

  generatePDF = async (e, iccid) => {
    try {
      await this.props.generatePDF({ ICCID: iccid });
    } catch (e) {
      //error occurred here
      console.log('Error occured while regenerating pdf : ', e);
    }
  };

  getPreSignedUrls = async (e, iccid) => {
    e.preventDefault();
    try {
      const { simDetailData } = this.state;
      const ICCID = iccid ? iccid : simDetailData.simnumber;
      const {
        value: { success, url },
      } = await this.props.getPreSignedUrl({ ICCID });

      if (success) {
        window.open(url, '_blank');
      } else {
        await this.generatePDF(e, ICCID);
        message.error({
          key: 'Retrieving the PDF failed',
          content: 'Error occurred, try again later!',
        });
      }
    } catch (error) {
      message.error({
        key: 'getPreSignedUrls',
        content: _.get(
          error,
          'data.message',
          'Error in processing the request!'
        ),
      });
    }
  };

  onSwapSIMConfirmation = () => {
    if (this.state.fieldName === 'pSIM') {
      this.setState({
        swapSimModalOpen: true,
        swapSimConfirmingModalOpen: false,
      });
    } else {
      this.setState({ updateLoading: true });
      this.getEsimQuantity();
    }
  };

  checkIldIrForAdminUser = (userRole) => {
    const roles = ['superadmin', 'admin', 'sysops'];
    return roles.includes(userRole);
  };

  getAdditionalEsimInfo = async (iccid) => {
    const {
      value: { data, success },
    } = await this.props.getAdditionalEsimInfo({ICCID: iccid});
    if (success) {
      this.setState({
        eSimProfileState: data.profileState,
      });
    }
  };

  render() {
    const { user } = this.props;
    const {
      iAuthorization,
      plansData,
      simDetailData,
      ildBalanceInfo,
      irBalanceInfo,
      selectedPlan,
      selectedListTags,
      usage,
      rediisUsage,
      planCycle,
      accountNumber,
      hidePlanCycle,
      searchVal,
      searchData,
      portInRequestStatus,
      phoneNumberPortIn,
      carrierPortIn,
      name,
      street1,
      street2,
      city,
      state,
      zip,
      carrierACNumberPortIn,
      carrierAssignPasswordportIn,
      zipcodePortIn,
      alternateContactPortIn,
      plansDataPortIn,
      selectedSimPlanPortIn,
      cancelPortInLoader,
      wifiCallingModalOpen,
      wificallingInitialValues,
      errorMessage,
      activePortInReqData,
      refreshPortIn,
      isReconnectLoading,
      reconnectNetworkDisabled,
      showTimer,
      time,
      iccidNew,
      msisdnNew,
      usernameNew,
      passwordNew,
      planIdNew,
      simStatusNew,
      portInStatusNew,
      adminScope,
      updateLoading,
      orderrideError,
      datePickerValue,
      overwrittenSimDetails,
      addIRBalance,
      addIRBalanceConfirm,
      addIldMinutes,
      addIldMinutesConfirm,
      addIldMinsPayType,
      addIrPayType,
      ildPlans,
      irPlans,
      sureBtnDisabled,
      addOnPlansUsage,
      recurringInfo,
      voiceMailStatus,
      irIldDetails,
      pukCodes,
      changePhoneNumberErrorModal,
      imeiNumber,
      deviceApiError,
      brand,
      brandsArray,
      isVMApiResponse,
      wifiCallingData,
      userData,
      isAccountNumberLoading,
      isSimDetailLoading,
      isPukLoading,
      isPlanCycleLoading,
      eSimProfileState
    } = this.state;
    let overlimitPlanUsageData = _.get(
      addOnPlansUsage,
      'usage.planData.full.overlimit',
      0
    );

    const simStatusSymbol =
      simDetailData.status === 10
        ? 'status active ml-1'
        : simDetailData.status === 0
        ? 'status non-activated ml-1'
        : simDetailData.status === 99
        ? 'status paused ml-1'
        : simDetailData.status === 12
        ? 'status in-progress m1-1'
        : 'status deactivated ml-1';
    const simStatus =
      simDetailData.status === 10
        ? overlimitPlanUsageData
          ? 'Active(Data Throttled)'
          : 'Active'
        : simDetailData.status === 0
        ? 'Available SIMs'
        : simDetailData.status === 99
        ? 'Paused'
        : simDetailData.status === 12
        ? 'In Progress'
        : 'Deactivated';

    let tagsArr =
      simDetailData.tags && simDetailData.tags !== ''
        ? simDetailData.tags.split(',')
        : [];

    const usageDataArray = [['Days', 'Data Used']];
    let totalMBUsed = 0;

    rediisUsage &&
      rediisUsage.length > 0 &&
      rediisUsage.map((usageDate) => {
        usageDataArray.push([usageDate.date, usageDate.data]);
        totalMBUsed += Number(usageDate.data);
        return true;
      });
    const myTimeZone = moment.tz.guess();
    const msisdnUpdateDate = moment.utc(simDetailData.msisdnUpdateDate);
    const activationdate = moment.utc(simDetailData.activationdate);
    const irWalletBalance =
      _.get(addOnPlansUsage, 'usage.roaming.remaining', 0.0) / 100;
    const ildWalletBalance = parseFloat(
      _.get(addOnPlansUsage, 'usage.ildUsage.packageBalance2.remaining', 0.0)
    );
    return (
      <div className="layout-has-sidebar">
        <SidebarNavigation
          {...this.props}
          setSortByKeys={this.setSortByKeys}
          simCounts={this.props.simCounts}
          onTabChange={this.onSideTabChange}
        />
        <TopNavigation
          updateSimDetail={this.updateSimDetail}
          simCounts={this.props.simCounts}
          {...this.props}
        />
        <main className="dashboard-layout-content">
          <div className="row align-items-md-center mb-4">
            <div className="col-md-2">
              <button
                onClick={() => this.props.history.goBack()}
                className="btn btn-back"
              >
                <BackArrowIcon className="back-icon" /> Back
              </button>
            </div>
            {!this.state.isLoading &&
              _.get(simDetailData, 'status', true) !== 9 && (
                <div className="col-md-10 text-right">
                  {_.get(simDetailData, 'status', 0) !== 0 &&
                    _.get(simDetailData, 'status', 0) !== 12 &&
                    _.get(simDetailData, 'status', 0) !== 11 && (
                      <div className="reconnect-btn-action">
                        <button
                          disabled={reconnectNetworkDisabled}
                          onClick={this.reconnectNetwork}
                          className="btn btn-purple-outline mr-3 w-150"
                        >
                          {isReconnectLoading
                            ? 'Please Wait...'
                            : 'Reconnect to Network'}
                        </button>
                        {showTimer && (
                          <div className="reconnect-btn-action-text">
                            You can reconnect after {time} seconds
                          </div>
                        )}
                      </div>
                    )}
                  {_.get(simDetailData, 'ultraplan', '') &&
                    _.get(simDetailData, 'ultraplan', '') !== '' &&
                    _.get(simDetailData, 'ultraplan', '') === 'IOTDVS' && (
                      <button
                        data-testid="swapSimButton"
                        onClick={this.swapSimModal}
                        className="btn btn-purple-outline mr-3 w-150"
                      >
                        Swap SIM
                      </button>
                    )}
                  <div className="material-textfield select-results">
                    <select
                      className="form-control custom-select material-textfield-input"
                      id="selectAction"
                      value={this.state.selectedAction}
                      onChange={this.selectActionHandle}
                    >
                      {_.get(simDetailData, 'ultraplan', '') &&
                      _.get(simDetailData, 'ultraplan', '') !== '' &&
                      _.get(simDetailData, 'ultraplan', '') === 'IOTFREEZE' ? (
                        <>
                          <option>Select Action</option>
                          <option value="setsimname">
                            {localStorage.getItem('prevTab') === 'fixed'
                              ? 'Set Device Name'
                              : 'Set SIM Name'}
                          </option>
                          {(iAuthorization ||
                            simDetailData.status === 11 ||
                            simDetailData.ultraplan !== 'IOTFREEZE') &&
                            simStatus === 'Deactivated' && (
                              <option value="reactivate">Reactivate</option>
                            )}
                          {user &&
                            (isSPRPartner(user) || isSubUser(user)) &&
                            simDetailData.status === 99 && (
                              <>
                                <option value="inactive">Deactivate</option>
                                <option value="unpause">Unpause</option>
                              </>
                            )}
                          {(user && user.role === 'sysops') ||
                          (adminScope === 'sysops' &&
                            this.state.iAuthorization &&
                            this.state.iAuthorization !== '') ? (
                            <option value="adminOverride">
                              Admin Override
                            </option>
                          ) : (
                            ''
                          )}
                        </>
                      ) : (
                        <>
                          <option>Select Action</option>
                          <option value="pause">Pause</option>
                          <option value="inactive">Deactivate</option>
                          <option value="setsimname">
                            {localStorage.getItem('prevTab') === 'fixed'
                              ? 'Set Device Name'
                              : 'Set SIM Name'}
                          </option>
                          <option value="setZipcode">Update Zipcode</option>
                          {(user &&
                            (user.role === 'sysops' ||
                              user.role === 'superadmin')) ||
                          ((adminScope === 'sysops' ||
                            adminScope === 'superadmin') &&
                            this.state.iAuthorization &&
                            this.state.iAuthorization !== '') ? (
                            <option value="adminOverride">
                              Admin Override
                            </option>
                          ) : (
                            ''
                          )}
                        </>
                      )}
                    </select>
                    <label
                      className="material-textfield-label"
                      htmlFor="selectAction"
                    >
                      Actions
                    </label>
                  </div>
                </div>
              )}
          </div>
          <>
            {_.get(simDetailData, 'status', true) !== 9 && (
              <div className="row">
                <div className="col-md-12">
                  <div className="d-block d-lg-none mb-3">
                    <button
                      onClick={this.createTagsModal}
                      className="btn btn-purple mr-3 mb-3 w-150"
                    >
                      Add or Edit Tags
                    </button>
                  </div>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header d-flex align-items-center">
                    <div className="">
                      <h5 className="card-title">
                        {_.get(simDetailData, 'simname', '') !== '' &&
                        _.get(simDetailData, 'simname', '') !== 'undefined'
                          ? _.get(simDetailData, 'simname', '')
                          : _.get(simDetailData, 'simnumber', '')}{' '}
                        {this.state.isLoading ? (
                          'Loading...'
                        ) : (
                          <span className={simStatusSymbol}>{simStatus}</span>
                        )}
                      </h5>
                      <div className="mt-2">
                        {_.get(simDetailData, 'tags', '') &&
                          _.get(simDetailData, 'tags', '') !== '' &&
                          _.size(tagsArr) > 0 &&
                          tagsArr.map((tag, index) => {
                            return (
                              <span key={index} className="tags-label mr-2">
                                {tag}
                              </span>
                            );
                          })}
                      </div>
                    </div>
                    {_.get(simDetailData, 'status', true) !== 9 && (
                      <div className="details-inner-cta d-none d-lg-block">
                        <button
                          onClick={this.createTagsModal}
                          className="btn btn-purple mr-3"
                        >
                          Add or Edit Tags
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="card-body card-body-sm">
                    <div className="row">
                      <div className="col-md-5">
                        <div className="info-block">
                          <label className="label-text">ICCID</label>
                          <p className="text-desc">
                            {isSimDetailLoading
                              ? 'Loading...'
                              : _.get(simDetailData, 'simnumber', '-')}
                          </p>
                        </div>
                        <div className="info-block">
                          <label className="label-text">UserID</label>
                          <p className="text-desc">
                            {isSimDetailLoading
                              ? 'Loading...'
                              : simDetailData &&
                                simDetailData.ultraUserId &&
                                simDetailData.ultraUserId !== '' &&
                                simDetailData.ultraUserId !== null
                              ? simDetailData.ultraUserId
                              : 'N/A'}
                          </p>
                        </div>
                        <div className="info-block">
                          <label className="label-text">Account Number</label>
                          <p className="text-desc">
                            {isAccountNumberLoading
                              ? 'Loading...'
                              : accountNumber !== ''
                              ? accountNumber
                              : 'N/A'}
                          </p>
                        </div>
                        <div className="info-block">
                          <label className="label-text">IMEI Number</label>
                          {!isSimDetailLoading && (
                            <span
                              onClick={() => {
                                document.body.style.overflow = 'hidden';
                                this.setState({ viewApnDrawer: true });
                              }}
                              className="btn-view-apn ml-3"
                            >
                              View APN Settings
                            </span>
                          )}
                          <p className="text-desc">
                            {isSimDetailLoading
                              ? 'Loading...'
                              : simDetailData && simDetailData.imeinumber
                              ? simDetailData.imeinumber
                              : 'N/A'}
                          </p>
                        </div>
                        <div className="info-block">
                          <label className="label-text">MSISDN</label>
                          {user &&
                            (isSPRPartner(user) ||
                              (isSubUser(user) &&
                                checkPermission(user, '/change-msisdn'))) &&
                            simDetailData.status === 10 &&
                            _.get(simDetailData, 'simtype', '') === 'spr' && (
                              <span
                                onClick={() =>
                                  this.setState({
                                    changePhoneNumberModal: true,
                                  })
                                }
                                className="btn-view-apn ml-3"
                              >
                                Change Phone Number
                              </span>
                            )}
                          <p className="text-desc">
                            {isSimDetailLoading
                              ? 'Loading...'
                              : _.get(simDetailData, 'msisdn', '') &&
                                _.get(simDetailData, 'msisdn', '') !== ''
                              ? this.formatPhoneNumber(
                                  _.get(simDetailData, 'msisdn', '-')
                                )
                              : _.get(activePortInReqData, 'msisdn', '') &&
                                _.get(activePortInReqData, 'msisdn', '') !== ''
                              ? this.formatPhoneNumber(
                                  _.get(activePortInReqData, 'msisdn', '-')
                                )
                              : 'N/A'}
                            <span className="text-desc text-muted font-14">
                              {_.get(simDetailData, 'msisdnUpdateDate', '') &&
                              _.get(simDetailData, 'msisdnUpdateDate', '') !==
                                ''
                                ? ` Last updated on - ${moment(msisdnUpdateDate)
                                    .tz(myTimeZone)
                                    .format('MMMM DD, YYYY')} at ${moment(
                                    msisdnUpdateDate
                                  )
                                    .tz(myTimeZone)
                                    .format('hh:mm A')}`
                                : ''}
                            </span>
                            {portInRequestStatus !== '' && (
                              <>
                                &nbsp;&nbsp;|
                                <span
                                  className={
                                    portInRequestStatus.toLowerCase() ===
                                    'cancelled'
                                      ? 'btn btn-link-danger'
                                      : 'btn btn-green'
                                  }
                                >
                                  Port is {this.showStatus(portInRequestStatus)}
                                </span>
                              </>
                            )}
                          </p>
                        </div>
                        <div className="info-block">
                          <label className="label-text">APN</label>
                          <p className="text-desc">
                            {isSimDetailLoading
                              ? 'Loading...'
                              : _.get(simDetailData, 'APN', '') &&
                                _.get(simDetailData, 'APN', '') !== ''
                              ? _.get(simDetailData, 'APN', 'N/A')
                              : 'N/A'}
                          </p>
                        </div>
                        {_.get(simDetailData, 'simtype', '') === 'spr' && (
                          <div className="info-block">
                            <label className="label-text">Voicemail</label>
                            {isVMApiResponse && (
                              <span
                                onClick={() =>
                                  this.setState({
                                    voicemailStatusUpdateModal: true,
                                  })
                                }
                                className="btn-view-apn ml-3"
                              >
                                {voiceMailStatus ? 'Disable' : 'Enable'}{' '}
                                Voicemail
                              </span>
                            )}
                            <p className="text-desc">
                              {isVMApiResponse
                                ? voiceMailStatus
                                  ? 'Enabled'
                                  : 'Disabled'
                                : 'Loading...'}
                            </p>
                          </div>
                        )}
                      </div>
                      <div className="col-md-4">
                        <div className="info-block">
                          <label className="label-text">Activation Date </label>
                          <p className="text-desc">
                            {isSimDetailLoading
                              ? 'Loading...'
                              : _.get(simDetailData, 'activationdate', '') &&
                                _.get(simDetailData, 'activationdate', '') !==
                                  ''
                              ? `${moment(activationdate)
                                  .tz(myTimeZone)
                                  .format('MMMM DD, YYYY')} at ${moment(
                                  activationdate
                                )
                                  .tz(myTimeZone)
                                  .format('hh:mm A')}`
                              : 'N/A'}
                          </p>
                        </div>
                        {_.get(simDetailData, 'status', true) !== 9 &&
                          _.get(simDetailData, 'status', true) !== 11 && (
                            <div className="info-block">
                              <label className="label-text">
                                Renewal Date{' '}
                              </label>
                              <p className="text-desc">
                                {isPlanCycleLoading
                                  ? 'Loading...'
                                  : _.get(planCycle, 'renewalDate', '') &&
                                    _.get(planCycle, 'renewalDate', '') !== ''
                                  ? `${moment(planCycle.renewalDate).format(
                                      'MMMM DD, YYYY'
                                    )}`
                                  : 'N/A'}
                              </p>
                            </div>
                          )}
                        {!hidePlanCycle && (
                          <div className="info-block">
                            <label className="label-text">Plan Cycle </label>
                            <p className="text-desc">
                              {!_.isEmpty(planCycle) &&
                              _.get(planCycle, 'startOfCycle', '') &&
                              _.get(planCycle, 'startOfCycle', '') !== ''
                                ? moment(
                                    _.get(planCycle, 'startOfCycle', '')
                                  ).format('MMMM DD, YYYY') +
                                  ' - ' +
                                  moment(
                                    _.get(planCycle, 'endOfCycle', '')
                                  ).format('MMMM DD, YYYY')
                                : 'N/A'}
                            </p>
                          </div>
                        )}
                        <div className="info-block">
                          <label className="label-text">Plan </label>
                          {!isSimDetailLoading &&
                            simDetailData.status !== 11 &&
                            simDetailData.status !== 0 &&
                            simDetailData.status !== 9 && (
                              <span
                                onClick={this.openModal}
                                className="btn-view-apn ml-3"
                              >
                                Change Plan
                              </span>
                            )}
                          {_.get(simDetailData, 'simtype', '') === 'spr' ? (
                            <p className="text-desc">
                              {isSimDetailLoading
                                ? 'Loading...'
                                : _.get(simDetailData, 'title', '') &&
                                  _.get(simDetailData, 'title', '') !== ''
                                ? _.get(simDetailData, 'title', 'N/A')
                                : _.get(activePortInReqData, 'title', '') &&
                                  _.get(activePortInReqData, 'title', '') !== ''
                                ? _.get(activePortInReqData, 'title', '-')
                                : 'N/A'}
                              <br />
                              {!_.isEmpty(simDetailData) &&
                                (simDetailData.title
                                  .toLowerCase()
                                  .includes('just data') ||
                                  simDetailData.title
                                    .toLowerCase()
                                    .includes('everything')) &&
                                '$' +
                                  simDetailData.monthlyprice.toLocaleString(
                                    undefined,
                                    { maximumFractionDigits: 2 }
                                  ) +
                                  ' per 12 Months = ' +
                                  simDetailData.unit +
                                  ' units'}
                            </p>
                          ) : (
                            <p className="text-desc">
                              {isSimDetailLoading
                                ? 'Loading...'
                                : _.get(simDetailData, 'offername', '') &&
                                  _.get(simDetailData, 'offername', '') !== ''
                                ? _.get(simDetailData, 'offername', 'N/A')
                                : _.get(activePortInReqData, 'title', '') &&
                                  _.get(activePortInReqData, 'title', '') !== ''
                                ? _.get(activePortInReqData, 'title', '-')
                                : 'N/A'}
                              <br />
                              {!_.isEmpty(simDetailData) &&
                                (simDetailData.title
                                  .toLowerCase()
                                  .includes('just data') ||
                                  simDetailData.title
                                    .toLowerCase()
                                    .includes('everything')) &&
                                '$' +
                                  simDetailData.monthlyprice.toLocaleString(
                                    undefined,
                                    { maximumFractionDigits: 2 }
                                  ) +
                                  ' per 12 Months = ' +
                                  simDetailData.unit +
                                  ' units'}
                            </p>
                          )}
                        </div>
                        {_.get(simDetailData, 'orderid', '') &&
                          _.get(simDetailData, 'orderid', '') !== '' && (
                            <div className="info-block">
                              <label className="label-text">
                                Order Number{' '}
                              </label>
                              <p
                                onClick={this.checkOrderHistory}
                                className="btn-view-apn"
                              >
                                {_.get(simDetailData, 'ordernumber', 'N/A')}
                              </p>
                            </div>
                          )}
                        {_.get(simDetailData, 'simtype', '') === 'spr' && (
                          <div className="info-block">
                            <label className="label-text">e911 Address</label>
                            <span
                              onClick={() =>
                                this.setState({ wifiCallingModalOpen: true })
                              }
                              className="btn-view-apn ml-3"
                            >
                              {wifiCallingData.enabled
                                ? 'Edit Address'
                                : 'Add Address'}
                            </span>
                            {wifiCallingData.enabled ? (
                              <p className="text-desc">
                                {_.get(
                                  wifiCallingData,
                                  'e911Address.street1',
                                  ''
                                )}{' '}
                                {_.get(
                                  wifiCallingData,
                                  'e911Address.street2',
                                  ''
                                )}
                                <br />
                                {_.get(
                                  wifiCallingData,
                                  'e911Address.city',
                                  ''
                                )}{' '}
                                {_.get(
                                  wifiCallingData,
                                  'e911Address.state',
                                  ''
                                )}
                                ,{' '}
                                {_.get(wifiCallingData, 'e911Address.zip', '')}
                              </p>
                            ) : (
                              <p className="text-desc">N/A</p>
                            )}
                          </div>
                        )}
                        {_.get(simDetailData, 'simtype', '') === 'spr' &&
                          !!(
                            this.checkIldIrForAdminUser(user.role) ||
                            user.isILDEnabled === 1
                          ) &&
                          !!irIldDetails.isILD && (
                            <>
                              {simDetailData.isILDEnabled === 1 && (
                                <div className="info-block">
                                  <label className="label-text">
                                    ILD Balance
                                  </label>
                                  {ildBalanceInfo &&
                                  ildBalanceInfo.remaining ? (
                                    <p className="text-desc">
                                      {ildWalletBalance === 0
                                        ? 'N/A'
                                        : `${ildWalletBalance} minutes`}
                                    </p>
                                  ) : (
                                    <p className="text-desc">N/A</p>
                                  )}
                                  {recurringInfo.ildRecurring &&
                                    ildWalletBalance !== 0 && (
                                      <p className="text-desc text-muted font-14">
                                        Recurring Subscription:{' '}
                                        <span className="text-green">
                                          Active
                                        </span>{' '}
                                        <button
                                          onClick={() =>
                                            this.setState({
                                              recurringType: 'ild',
                                              cancelRecurringPlanModal: true,
                                            })
                                          }
                                          className="btn btn-danger-link btn-xs p-0 ml-2"
                                        >
                                          Cancel
                                        </button>
                                      </p>
                                    )}
                                </div>
                              )}
                            </>
                          )}
                        {_.get(simDetailData, 'simtype', '') === 'spr' &&
                          !!(
                            this.checkIldIrForAdminUser(user.role) ||
                            user.isIREnabled === 1
                          ) &&
                          !!irIldDetails.isIR && (
                            <>
                              {simDetailData.isIREnabled === 1 && (
                                <div className="info-block">
                                  <label className="label-text">
                                    IR Wallet Available
                                  </label>
                                  {irBalanceInfo &&
                                  irBalanceInfo.data &&
                                  irBalanceInfo.data.wallet &&
                                  irBalanceInfo.data.wallet.remaining ? (
                                    <p
                                      className="text-desc"
                                      data-testid="irBalanceInfo"
                                    >
                                      {irWalletBalance === 0
                                        ? 'N/A'
                                        : `$${irWalletBalance.toFixed(
                                            2
                                          )} balance`}
                                    </p>
                                  ) : (
                                    <p className="text-desc">N/A</p>
                                  )}
                                  {recurringInfo.irRecurring &&
                                    irWalletBalance !== 0 && (
                                      <p className="text-desc text-muted font-14">
                                        Recurring Subscription:{' '}
                                        <span className="text-green">
                                          Active
                                        </span>{' '}
                                        <button
                                          onClick={() =>
                                            this.setState({
                                              recurringType: 'ir',
                                              cancelRecurringPlanModal: true,
                                            })
                                          }
                                          className="btn btn-danger-link btn-xs p-0 ml-2"
                                        >
                                          Cancel
                                        </button>
                                      </p>
                                    )}
                                </div>
                              )}
                            </>
                          )}
                        {simDetailData?.isEsim === 1 && simDetailData.status === 10 && (
                          <div className="info-block">
                            <span id="esim-label" className="label-text">
                              eSIM Status
                            </span>
                            <p
                              aria-labelledby="esim-label"
                              style={
                                eSimProfileState === 'INSTALLED'
                                  ? {}
                                  : { color: 'red', fontWeight: 'bold' }
                              }
                              className="text-desc"
                            >
                              {eSimProfileState === 'INSTALLED' ? 'Installed' : eSimProfileState === 'RELEASED' ? 'Not Installed' : eSimProfileState === 'UNAVAILABLE' ? 'Deleted' : 'N/A'}
                            </p>
                          </div>
                        )}
                      </div>
                      <div className="col-md-3">
                        {_.get(simDetailData, 'simtype', '') === 'spr' &&
                          !!(
                            this.checkIldIrForAdminUser(user.role) ||
                            user.isILDEnabled === 1
                          ) &&
                          !!irIldDetails.isILD && (
                            <div className="info-block">
                              <label className="label-text" htmlFor="addILD">
                                Add ILDs
                              </label>
                              <Switch
                                checked={simDetailData.isILDEnabled}
                                id="addILD"
                                onChange={() =>
                                  this.onChangeSwitch(
                                    'addIldConfirmationModal',
                                    'isILDEnabled'
                                  )
                                }
                                className="ml-3"
                              />
                              {simDetailData.isILDEnabled === 1 && (
                                <span
                                  className="btn-view-apn ml-3"
                                  onClick={this.openAddMinutesModel}
                                >
                                  Add Minutes
                                </span>
                              )}
                            </div>
                          )}
                        {_.get(simDetailData, 'simtype', '') === 'spr' &&
                          !!(
                            this.checkIldIrForAdminUser(user.role) ||
                            user.isIREnabled === 1
                          ) &&
                          !!irIldDetails.isIR && (
                            <div className="info-block">
                              <label className="label-text" htmlFor="addIR">
                                Add IR{' '}
                              </label>
                              <Switch
                                checked={simDetailData.isIREnabled}
                                id="addIR"
                                onChange={() =>
                                  this.onChangeSwitch(
                                    'addIrConfirmationModal',
                                    'isIREnabled'
                                  )
                                }
                                className="ml-3"
                              />
                              {simDetailData.isIREnabled === 1 && (
                                <span
                                  className="btn-view-apn ml-3"
                                  onClick={this.openAddBalanceModel}
                                >
                                  Add Balance
                                </span>
                              )}
                            </div>
                          )}

                        <div className="info-block">
                          <label className="label-text">Puk code 1</label>
                          <p className="text-desc">
                            {isPukLoading
                              ? 'Loading...'
                              : pukCodes && pukCodes.puk1
                              ? pukCodes.puk1
                              : 'N/A'}
                          </p>
                        </div>
                        <div className="info-block">
                          <label className="label-text">Puk code 2</label>
                          <p className="text-desc">
                            {isPukLoading
                              ? 'Loading...'
                              : pukCodes && pukCodes.puk2
                              ? pukCodes.puk2
                              : 'N/A'}
                          </p>
                        </div>
                        {simDetailData.isEsim === 1 &&
                          simDetailData.status !== 0 &&
                          simDetailData.status !== 12 && (
                            <>
                              <span className="eSim-detail-text">
                                eSIM Installation Details
                              </span>

                              <div className="info-block">
                                <label className="label-text">
                                  SM-DP+ Address
                                </label>
                                <p className="text-desc">
                                  {isSimDetailLoading
                                    ? 'Loading..'
                                    : _.get(simDetailData, 'SMDPAddress', '') &&
                                      _.get(
                                        simDetailData,
                                        'SMDPAddress',
                                        ''
                                      ) !== ''
                                    ? _.get(simDetailData, 'SMDPAddress', 'N/A')
                                    : 'N/A'}
                                </p>
                              </div>

                              <div className="info-block">
                                <label className="label-text">
                                  Activation code
                                </label>
                                <p className="text-desc">
                                  {isSimDetailLoading
                                    ? 'Loading..'
                                    : _.get(
                                        simDetailData,
                                        'ActivationCode',
                                        ''
                                      ) &&
                                      _.get(
                                        simDetailData,
                                        'ActivationCode',
                                        ''
                                      ) !== ''
                                    ? _.get(
                                        simDetailData,
                                        'ActivationCode',
                                        'N/A'
                                      )
                                    : 'N/A'}
                                </p>
                              </div>

                              <div className="info-block">
                                <label className="label-text">
                                  eSim QR Code
                                </label>
                                <p className="text-desc">
                                  {isSimDetailLoading
                                    ? 'Loading..'
                                    : _.get(
                                        simDetailData,
                                        'QRActivationCode',
                                        ''
                                      ) &&
                                      _.get(
                                        simDetailData,
                                        'QRActivationCode',
                                        ''
                                      ) !== ''
                                    ? _.get(
                                        simDetailData,
                                        'QRActivationCode',
                                        'N/A'
                                      )
                                    : 'N/A'}
                                </p>
                              </div>

                              <div
                                className="pdf-download-button"
                                onClick={(e) => {
                                  handleEsimPdfDownload(
                                    this.state.simDetailData.simnumber,
                                    this.props.downloadEsimPDF,
                                    this.props.generatePDF
                                  );
                                }}
                              >
                                <span className="pdf-download">
                                  <DownloadIcon />
                                  Download PDF
                                </span>
                              </div>
                            </>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                {activePortInReqData &&
                  activePortInReqData.status &&
                  activePortInReqData.status.toLowerCase() !== 'completed' && (
                    <div className="card">
                      <div className="card-header d-flex align-items-center">
                        <div className="">
                          <h5 className="card-title">Port-In Details</h5>
                        </div>
                        {String(activePortInReqData.status).toLowerCase() ===
                          'inprogress' ||
                        String(activePortInReqData.status).toLowerCase() ===
                          'resolutionrequired' ? (
                          <>
                            <div className="details-inner-cta">
                              {String(
                                activePortInReqData.status
                              ).toLowerCase() === 'resolutionrequired' && (
                                <button
                                  onClick={this.openUpdatePortInModel}
                                  className="btn btn-dark"
                                  disabled={this.state.portInDisabled}
                                >
                                  Update Port-In
                                </button>
                              )}
                              {activePortInReqData.reason !== '' && (
                                <button
                                  onClick={this.openCancelPortInModel}
                                  className="btn btn-danger ml-3"
                                  disabled={this.state.portInDisabled}
                                >
                                  Cancel Port-In
                                </button>
                              )}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="details-inner-cta">
                              <button
                                disabled="disabled"
                                className="btn btn-dark pull-right"
                              >
                                Update Port-In
                              </button>
                              <button
                                disabled="disabled"
                                className="btn btn-danger pull-right ml-3"
                              >
                                Cancel Port-In
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="card-body card-body-sm">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="info-block">
                              <label className="label-text">
                                Port-In Start Date
                              </label>
                              <p className="text-desc">
                                {moment(
                                  _.get(activePortInReqData, 'datecreated', '')
                                )
                                  .utc()
                                  .format('MMMM DD, YYYY')}
                              </p>
                            </div>
                            <div className="info-block">
                              <label className="label-text">
                                Current Port-In Status
                              </label>
                              <span
                                onClick={() =>
                                  this.refreshPortInStatus(activePortInReqData)
                                }
                                className="btn-view-apn ml-3"
                              >
                                {refreshPortIn ? 'Please Wait...' : 'Refresh'}
                              </span>
                              <p className="text-desc">
                                {this.showStatus(
                                  _.get(activePortInReqData, 'status', '')
                                )}
                              </p>
                              {this.showStatus(
                                _.get(activePortInReqData, 'status', '')
                              ) === ('Resolution Required' || 'In-Progress') &&
                                (_.get(activePortInReqData, 'reason', '') !==
                                ('' || null) ? (
                                  <p className="btn-link-danger">
                                    <strong>Reason: </strong>
                                    {_.get(activePortInReqData, 'reason', '')}
                                    <span className="btn-view-apn ml-3">
                                      <a
                                        href="https://docs.google.com/document/d/e/2PACX-1vQQ58E847L1HdD6Ci0AH4JsaETRza9P0WQHDkYvEZgeZCMSBkMy7bk8vRpG-P2fChyPkj2QPkVPzcvZ/pub"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        Click here for next steps
                                      </a>
                                    </span>
                                  </p>
                                ) : (
                                  ''
                                ))}
                            </div>
                            <div className="info-block">
                              <label className="label-text">
                                Last Update Date
                              </label>
                              <p className="text-desc">
                                {moment(
                                  _.get(activePortInReqData, 'datemodified', '')
                                )
                                  .tz(moment.tz.guess())
                                  .format('MMM DD, YYYY h:mm a z')}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </>
          <UsageLogs
            simDetailData={simDetailData}
            planCycle={planCycle}
            user={user}
            usage={usage}
            totalMBUsed={totalMBUsed}
            usageDataArray={usageDataArray}
          />

          <ActivityLogs getActivitiesLogs={this.props.getActivitiesLogs} />
        </main>

        {/* Add International Roaming */}
        <ReactModal
          isOpen={this.state.AddBalanceModal}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Add International Roaming"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">
                Add International Roaming Balance
              </h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="form-group material-textfield">
                <select
                  className="form-control form-control-lg custom-select material-textfield-input"
                  id="selectIRBalance"
                  onChange={(e) =>
                    this.setState({
                      addIRBalance: parseFloat(e.target.value),
                      errorMessage: '',
                    })
                  }
                >
                  <option value={0}>Select</option>
                  {_.size(irPlans) > 0 &&
                    irPlans.map((plan) => {
                      return (
                        <option key={plan.titleValue} value={plan.titleValue}>
                          {_.get(user, 'consumeOfferAPI', false)
                            ? `$${plan.titleValue.toFixed(2)}`
                            : `$${plan.titleValue.toFixed(2)}`}
                        </option>
                      );
                    })}
                </select>
                <label
                  className="material-textfield-label"
                  htmlFor="selectIRBalance"
                >
                  Select balance amount{' '}
                </label>
                {errorMessage && errorMessage !== '' && (
                  <div className="invalid-feedback">{errorMessage}</div>
                )}
              </div>
              <div className="form-group">
                <div className="select-btn-choice">
                  <button
                    className={
                      addIrPayType === 'oneTime' ? 'btn active' : 'btn'
                    }
                    onClick={(e) => this.setState({ addIrPayType: 'oneTime' })}
                  >
                    One Time
                  </button>
                  <button
                    className={
                      addIrPayType === 'recurring' ? 'btn active' : 'btn'
                    }
                    onClick={(e) =>
                      this.setState({ addIrPayType: 'recurring' })
                    }
                  >
                    Recurring
                  </button>
                </div>
              </div>
              <div className="form-group">
                <div className="font-16 font-700 mb-2">
                  The current balance is:
                  <span className="font-400" data-testid="currentBalanceInfo">
                    ${irWalletBalance.toFixed(2)}
                  </span>
                </div>
                <div className="font-16 font-700">
                  The balance after this addition will become:{' '}
                  <span className="font-400">
                    $
                    {addIRBalance &&
                      parseFloat(
                        parseFloat(
                          _.get(addOnPlansUsage, 'usage.roaming.remaining', 0.0)
                        ) /
                          100 +
                          parseFloat(addIRBalance)
                      ).toFixed(2)}
                  </span>
                </div>
              </div>
              <div className="form-group">
                <label className="custom-checkbox custom-checkbox-line custom-checkbox-center">
                  Are you sure you would like to add ${addIRBalance.toFixed(2)}{' '}
                  International Roaming balance to this number{' '}
                  {this.formatPhoneNumber(_.get(simDetailData, 'msisdn', '-'))}{' '}
                  with this SIM: {simDetailData.simnumber}?
                  <input
                    className="custom-checkbox--input"
                    type="checkbox"
                    onChange={() =>
                      this.setState({ sureBtnDisabled: !sureBtnDisabled })
                    }
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <button
                type="submit"
                className="btn btn-dark btn-lg w-100"
                disabled={addIRBalanceConfirm || sureBtnDisabled}
                onClick={(e) => this.updateAddOnPlans('ir')}
              >
                {!addIRBalanceConfirm ? "I'm sure, add it!" : 'Please wait'}
              </button>
            </div>
          </div>
        </ReactModal>

        {/* Add International Long Distance Minutes */}
        <ReactModal
          isOpen={this.state.AddMinutesModal}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Add International Long Distance Minutes"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">
                Add International Long Distance Minutes
              </h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="form-group material-textfield">
                <select
                  className="form-control form-control-lg custom-select material-textfield-input"
                  onChange={(e) =>
                    this.setState({
                      addIldMinutes: parseFloat(e.target.value),
                      errorMessage: '',
                    })
                  }
                >
                  <option value={0}>Select</option>
                  {_.size(ildPlans) > 0 &&
                    ildPlans.map((plan) => {
                      return (
                        <option key={plan.titleValue} value={plan.titleValue}>
                          {_.get(user, 'consumeOfferAPI', false)
                            ? `${plan.titleValue} minutes`
                            : `${plan.titleValue} minutes`}
                        </option>
                      );
                    })}
                </select>
                <label className="material-textfield-label">
                  Select Minutes{' '}
                </label>
                {errorMessage && errorMessage !== '' && (
                  <div className="invalid-feedback">{errorMessage}</div>
                )}
              </div>
              <div className="form-group">
                <div className="select-btn-choice">
                  <button
                    className={
                      addIldMinsPayType === 'oneTime' ? 'btn active' : 'btn'
                    }
                    onClick={(e) =>
                      this.setState({ addIldMinsPayType: 'oneTime' })
                    }
                  >
                    One Time
                  </button>
                  <button
                    className={
                      addIldMinsPayType === 'recurring' ? 'btn active' : 'btn'
                    }
                    onClick={(e) =>
                      this.setState({ addIldMinsPayType: 'recurring' })
                    }
                  >
                    Recurring
                  </button>
                </div>
              </div>
              <div className="form-group">
                <div className="font-16 font-700 mb-2">
                  The current balance is:{' '}
                  <span className="font-400">
                    {parseFloat(
                      _.get(
                        addOnPlansUsage,
                        'usage.ildUsage.packageBalance2.remaining',
                        0.0
                      )
                    )}{' '}
                    minutes
                  </span>
                </div>
                <div className="font-16 font-700">
                  The balance after this addition will become:{' '}
                  <span className="font-400">
                    {addIldMinutes &&
                      parseFloat(
                        _.get(
                          addOnPlansUsage,
                          'usage.ildUsage.packageBalance2.remaining',
                          0.0
                        )
                      ) + parseFloat(addIldMinutes)}{' '}
                    minutes
                  </span>
                </div>
              </div>
              <div className="form-group">
                <label className="custom-checkbox custom-checkbox-line custom-checkbox-center">
                  Are you sure you would like to add {addIldMinutes}{' '}
                  International Long Distance minutes to this number{' '}
                  {this.formatPhoneNumber(_.get(simDetailData, 'msisdn', '-'))}{' '}
                  with this SIM: {simDetailData.simnumber}?
                  <input
                    className="custom-checkbox--input"
                    type="checkbox"
                    onChange={() =>
                      this.setState({ sureBtnDisabled: !sureBtnDisabled })
                    }
                    required
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <button
                type="submit"
                className="btn btn-dark btn-lg w-100"
                disabled={addIldMinutesConfirm || sureBtnDisabled}
                onClick={(e) => this.updateAddOnPlans('ild')}
              >
                {!addIldMinutesConfirm ? "I'm sure, add it!" : 'Please wait'}
              </button>
            </div>
          </div>
        </ReactModal>

        <ReactModal
          isOpen={this.state.isEsimAvailable}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Success"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-xxs modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="connection-message-header failed failedEsim">
              <h6 className="no-esim-title">Warning</h6>
            </div>
            <div className="react-modal-body">
              <div className="connection-message form-group">
                <p className="no-esim-text">
                  There are no eSIMs available to activate. Please visit the{' '}
                  <Link to={'/eSIM-orders'}>eSIM Order Page</Link> to request
                  more.
                </p>
              </div>
              <button
                type="submit"
                className="btn btn-purple btn-lg w-100"
                onClick={this.closeModal}
              >
                Ok
              </button>
            </div>
          </div>
        </ReactModal>

        <ReactModal
          isOpen={this.state.successModalOpen}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Success"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-xxs modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="connection-message-header success">
              <div>
                <SuccessIcon />
              </div>
              <h6>Success</h6>
            </div>
            <div className="react-modal-body">
              <div className="connection-message form-group">
                <p> SIM's connection was reset successfully!</p>
              </div>

              <button
                type="submit"
                className="btn btn-purple btn-lg w-100"
                onClick={this.closeModal}
              >
                Ok
              </button>
            </div>
          </div>
        </ReactModal>

        <ReactModal
          isOpen={this.state.failedModalOpen}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Success"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-xxs modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="connection-message-header failed">
              <div>
                <FailedIcon />
              </div>
              <h6>Failed</h6>
            </div>
            <div className="react-modal-body">
              <div className="connection-message form-group">
                <p>
                  {' '}
                  Sorry, the connection could not be reset. Please try again in
                  a bit.
                </p>
              </div>

              <button
                type="submit"
                className="btn btn-purple btn-lg w-100"
                onClick={this.closeModal}
              >
                Ok
              </button>
            </div>
          </div>
        </ReactModal>

        {/* Plan select Modal */}
        <ReactModal
          isOpen={this.state.swapSimModalOpen}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Swap SIM"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Swap SIM</h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="update-plan-message form-group">
                <h4>Current SIM</h4>
                <p>{get(simDetailData, 'simnumber', '-')}</p>
              </div>
              <div className="form-group material-textfield">
                <DebounceInput
                  minLength={2}
                  debounceTimeout={500}
                  className="form-control form-control-lg material-textfield-input"
                  value={searchVal}
                  onChange={this.onSearch}
                  required
                />
                <label className="material-textfield-label">
                  New SIM ICCID
                </label>
                {this.state.showSearchDropdown &&
                this.state.searchVal !== '' ? (
                  <div
                    className={
                      searchData && searchData.length
                        ? 'search-wrapper-open-container swap-sim-list open'
                        : 'search-wrapper-open-container'
                    }
                  >
                    {searchData &&
                      searchData.map((d, i) => (
                        <ul key={i} className="search-wrapper-options-menu">
                          <li
                            className="search-wrapper-options-menu-item"
                            onClick={() =>
                              this.setState({
                                searchVal: d.simnumber,
                                showSearchDropdown: false,
                              })
                            }
                          >
                            <div className="search-bar-info">
                              <div className="sim-icon">
                                <SimsColorIcon />
                              </div>
                              <div className="user-info">
                                <div className="number">{d.simnumber}</div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      ))}
                  </div>
                ) : (
                  ''
                )}
                <div className="invalid-feedback">
                  {this.state.swapSimError}
                </div>
                <div className="form-group invalid-feedback">
                  {this.state.errorMessage}
                </div>
              </div>
              <button
                type="submit"
                disabled={this.state.updateLoading}
                onClick={this.swapSim}
                className="btn btn-dark btn-lg w-100"
              >
                {this.state.updateLoading ? 'Please wait...' : 'Save & Swap'}
              </button>
            </div>
          </div>
        </ReactModal>

        {/* Plan select Modal */}
        <ReactModal
          isOpen={this.state.swapSimConfirmingModalOpen}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Swap SIM"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Swap SIM</h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="update-plan-message form-group">
                <h4>{`Would you like to swap ICCID ${get(
                  simDetailData,
                  'simnumber',
                  '-'
                )} for a Physical SIM or eSIM?`}</h4>
              </div>
              <ul className="swap-sim-list">
                <li>
                  <label className="custom-radio">
                    <input
                      className="custom-radio--input unCheckAll"
                      type="radio"
                      name="pSIM"
                      checked={this.state.pSimFilter}
                      onChange={(e) =>
                        this.setState({
                          pSimFilter: e.target.checked,
                          eSimFilter: false,
                          fieldName: e.target.name,
                        })
                      }
                    />
                    Physical SIM
                    <span className="custom-radio--check "></span>
                  </label>
                </li>
                <li>
                  <label className="custom-radio">
                    <input
                      data-testid="eSimRadio"
                      className="custom-radio--input unCheckAll"
                      type="radio"
                      name="eSIM"
                      checked={this.state.eSimFilter}
                      onChange={(e) =>
                        this.setState({
                          eSimFilter: e.target.checked,
                          pSimFilter: false,
                          fieldName: e.target.name,
                        })
                      }
                    />
                    eSIM
                    <span className="custom-radio--check "></span>
                  </label>
                </li>
              </ul>
              <div className="form-group invalid-feedback">
                {this.state.errorMessage}
              </div>
              <div className="text-right mt-3">
                <button
                  type="submit"
                  disabled={
                    (!this.state.eSimFilter && !this.state.pSimFilter) ||
                    this.state.updateLoading
                  }
                  onClick={this.onSwapSIMConfirmation}
                  className="btn btn-dark w-150"
                >
                  {this.state.updateLoading ? 'Please wait...' : 'CONFIRM'}
                </button>
                <button
                  type="submit"
                  disabled={!this.state.eSimFilter && !this.state.pSimFilter}
                  onClick={this.closeModal}
                  className="btn btn-dark w-150 ml-2"
                >
                  CANCEL
                </button>
              </div>
            </div>
          </div>
        </ReactModal>

        {/* Plan Reactivate Sim Modal */}
        <ReactModal
          isOpen={this.state.reactivateSimModalOpen}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Reactivate"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">
                Reactivate SIM {_.get(simDetailData, 'simnumber', '')}?{' '}
              </h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="update-plan-message form-group">
                <p>
                  Changing plans 3 times will lock your sim onto the new plan
                  until ({this.state.nextChangeDate}), after which you will be
                  able to re-select a different plan.
                </p>
              </div>
              <div className="form-group material-textfield">
                <select
                  disabled={this.state.isDisabledPlanUpdate}
                  className="form-control form-control-lg custom-select material-textfield-input"
                  defaultValue={plansData.findIndex(
                    (item) => item.title === simDetailData.title
                  )}
                  onChange={this.handlePlanChange}
                >
                  <option>Select</option>
                  {_.size(plansData) > 0 &&
                    plansData.map((plan, index) => {
                      return (
                        <option key={index} value={index}>
                          {simDetailData.simtype === 'fixed'
                            ? plan.offername
                            : plan.title}
                        </option>
                      );
                    })}
                </select>
                <label className="material-textfield-label">Select Plan</label>
                {this.state.planError && (
                  <div className="invalid-feedback">Plan is required</div>
                )}
              </div>
              {this.state.isPlanDetail && !_.isEmpty(selectedPlan) && (
                <div className="info-block">
                  <label className="label-text">
                    {selectedPlan.title}{' '}
                    {selectedPlan.title.toLowerCase().includes('just data')
                      ? 'Plan'
                      : ''}{' '}
                    Details
                  </label>
                  <p className="text-desc">
                    ${selectedPlan.monthlyprice}/month <br />$
                    {selectedPlan.dataprice}/mb <br />
                    {selectedPlan.title.toLowerCase().includes('just data')
                      ? '(Data)'
                      : '(Data, Voice, SMS)'}
                    <br />
                  </p>
                </div>
              )}
              <div className="form-group invalid-feedback">
                {this.state.changePlanError}
              </div>
              <button
                type="submit"
                disabled={
                  this.state.updateLoading || this.state.isDisabledPlanUpdate
                }
                onClick={this.activateSimPlan}
                className="btn btn-dark btn-lg w-100"
              >
                {this.state.updateLoading
                  ? 'Please wait...'
                  : 'Yes, Reactivate Now'}
              </button>
            </div>
          </div>
        </ReactModal>

        <ReactModal
          isOpen={this.state.reactivateSimToDeactivateModalOpen}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Reactivate"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">
                Reactivate SIM {_.get(simDetailData, 'simnumber', '')}?{' '}
              </h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="form-group material-textfield">
                <select
                  disabled={this.planChangedCount >= 3}
                  className="form-control form-control-lg custom-select material-textfield-input"
                  defaultValue={plansData.findIndex(
                    (item) => item.title === simDetailData.title
                  )}
                  onChange={this.handlePlanChange}
                >
                  <option>Select</option>
                  {_.size(plansData) > 0 &&
                    plansData.map((plan, index) => {
                      return (
                        <option key={index} value={index}>
                          {simDetailData.simtype === 'fixed'
                            ? plan.offername
                            : plan.title}
                        </option>
                      );
                    })}
                </select>
                <label className="material-textfield-label">Select Plan</label>
                {this.state.planError && (
                  <div className="invalid-feedback">Plan is required</div>
                )}
              </div>
              {this.state.isPlanDetail && !_.isEmpty(selectedPlan) && (
                <div className="info-block">
                  <label className="label-text">
                    {selectedPlan.title}{' '}
                    {selectedPlan.title.toLowerCase().includes('just data')
                      ? 'Plan'
                      : ''}{' '}
                    Details
                  </label>
                  <p className="text-desc">
                    ${selectedPlan.monthlyprice}/month <br />$
                    {selectedPlan.dataprice}/mb <br />
                    {selectedPlan.title.toLowerCase().includes('just data')
                      ? '(Data)'
                      : '(Data, Voice, SMS)'}
                    <br />
                  </p>
                </div>
              )}
              <div className="form-group invalid-feedback">
                {_.get(this.state, 'reactiveErrorMessage', '')}
              </div>
              <button
                type="submit"
                disabled={
                  this.state.updateLoading || this.state.isDisabledPlanUpdate
                }
                onClick={this.reactivateSim}
                className="btn btn-dark btn-lg w-100"
              >
                {this.state.updateLoading
                  ? 'Please wait...'
                  : 'Yes, Reactivate Now'}
              </button>
            </div>
          </div>
        </ReactModal>

        {/* Edit SIM Details Modal */}
        <ReactModal
          isOpen={this.state.editSimModalOpen}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Edit SIM Details"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Change Plan</h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="update-plan-message form-group">
                <h4>Change Plan?</h4>
                <p>
                  Changing plans 3 times will lock your sim onto the new plan
                  until ({moment(planCycle.endOfCycle).format('MMMM DD, YYYY')}
                  ), after which you will be able to re-select a different plan.
                </p>
              </div>
              <div className="form-group material-textfield">
                <select
                  disabled={this.state.isDisabledPlanUpdate}
                  className="form-control form-control-lg custom-select material-textfield-input"
                  defaultValue={plansData.findIndex(
                    (item) => item.title === simDetailData.title
                  )}
                  onChange={this.handlePlanChange}
                  id="selectPlanChange"
                >
                  <option>Select</option>
                  {_.size(plansData) > 0 &&
                    plansData.map((plan, index) => {
                      return (
                        <option key={index} value={index}>
                          {simDetailData.simtype === 'fixed'
                            ? plan.offername
                            : plan.title}
                        </option>
                      );
                    })}
                </select>
                <label
                  className="material-textfield-label"
                  htmlFor="selectPlanChange"
                >
                  Select Plan
                </label>
                {this.state.planError && (
                  <div className="invalid-feedback">Plan is required</div>
                )}
                {!_.isEmpty(selectedPlan) && (
                  <ul className="toggle__list">
                    {_.get(selectedPlan, 'isILD', false) === true &&
                    _.get(userData, 'isILDEnabled', false) ? (
                      <li>
                        <div className="d-flex align-items-center justify-content-between">
                          <label className="label-text">Add ILDs</label>
                          <Switch
                            disabled={this.state.isDisabledPlanUpdate}
                            checked={this.state.isILDEnabled}
                            onChange={() => this.onChangeSwitch('isILDEnabled')}
                            className="ml-3"
                          />
                        </div>
                        {this.state.isILDEnabled && (
                          <div className="toggle__list--wrap">
                            <div className="form-group material-textfield">
                              <select
                                className="form-control form-control-lg custom-select material-textfield-input"
                                onChange={(e) =>
                                  this.setState({
                                    ildPlanValue: parseFloat(e.target.value),
                                    ildPlanValueRequired: false,
                                  })
                                }
                              >
                                <option value={0}>Select</option>
                                {_.size(ildPlans) > 0 &&
                                  ildPlans.map((plan) => {
                                    return (
                                      <option
                                        key={plan.titleValue}
                                        value={plan.titleValue}
                                      >
                                        {`${plan.titleValue} minutes`}
                                      </option>
                                    );
                                  })}
                              </select>
                              <label className="material-textfield-label">
                                Select balance amount
                              </label>
                              {this.state.ildPlanValueRequired && (
                                <div className="invalid-feedback">
                                  Please select ILD minutes.
                                </div>
                              )}
                            </div>
                            <div className="form-group">
                              <div className="select-btn-choice">
                                <button
                                  className={
                                    this.state.ildPlanType === 'oneTime'
                                      ? 'btn active'
                                      : 'btn'
                                  }
                                  onClick={(e) =>
                                    this.setState({ ildPlanType: 'oneTime' })
                                  }
                                >
                                  One Time
                                </button>
                                <button
                                  className={
                                    this.state.ildPlanType === 'recurring'
                                      ? 'btn active'
                                      : 'btn'
                                  }
                                  onClick={(e) =>
                                    this.setState({ ildPlanType: 'recurring' })
                                  }
                                >
                                  Recurring
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </li>
                    ) : (
                      ''
                    )}
                    {_.get(selectedPlan, 'isIR', false) &&
                    _.get(userData, 'isIREnabled', false) ? (
                      <li>
                        <div className="d-flex align-items-center justify-content-between">
                          <label className="label-text" htmlFor="toggleIR">
                            Add IR
                          </label>
                          <Switch
                            disabled={this.state.isDisabledPlanUpdate}
                            checked={this.state.isIREnabled}
                            onChange={() => this.onChangeSwitch('isIREnabled')}
                            className="ml-3"
                            id="toggleIR"
                          />
                        </div>

                        {this.state.isIREnabled && (
                          <div className="toggle__list--wrap">
                            <div className="form-group material-textfield">
                              <select
                                className="form-control form-control-lg custom-select material-textfield-input"
                                onChange={(e) =>
                                  this.setState({
                                    addIRBalance: parseFloat(e.target.value),
                                    irPlanValue: parseFloat(e.target.value),
                                    irPlanValueRequired: false,
                                  })
                                }
                                id="selectIRBalance-cp"
                              >
                                <option value={0}>Select</option>
                                {_.size(irPlans) > 0 &&
                                  irPlans.map((plan) => {
                                    return (
                                      <option
                                        key={plan.titleValue}
                                        value={plan.titleValue}
                                      >
                                        {`$${plan.titleValue.toFixed(2)}`}
                                      </option>
                                    );
                                  })}
                              </select>
                              <label
                                className="material-textfield-label"
                                htmlFor="selectIRBalance-cp"
                              >
                                Select balance amount
                              </label>
                              {this.state.irPlanValueRequired && (
                                <div className="invalid-feedback">
                                  Please select IR balance.
                                </div>
                              )}
                              {this.state.remainingIRBalanceError && (
                                <div className="invalid-feedback">
                                  You have insufficient balance
                                </div>
                              )}
                            </div>
                            <div className="form-group">
                              <div className="select-btn-choice">
                                <button
                                  className={
                                    this.state.irPlanType === 'oneTime'
                                      ? 'btn active'
                                      : 'btn'
                                  }
                                  onClick={(e) =>
                                    this.setState({ irPlanType: 'oneTime' })
                                  }
                                >
                                  One Time
                                </button>
                                <button
                                  className={
                                    this.state.irPlanType === 'recurring'
                                      ? 'btn active'
                                      : 'btn'
                                  }
                                  onClick={(e) =>
                                    this.setState({ irPlanType: 'recurring' })
                                  }
                                >
                                  Recurring
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </li>
                    ) : (
                      ''
                    )}
                  </ul>
                )}
              </div>
              {this.state.isPlanDetail && !_.isEmpty(selectedPlan) && (
                <div className="info-block">
                  <label className="label-text">
                    {selectedPlan.title}{' '}
                    {selectedPlan.title.toLowerCase().includes('just data')
                      ? 'Plan'
                      : ''}{' '}
                    Details
                  </label>
                  <p className="text-desc">
                    ${selectedPlan.monthlyprice}/month <br />$
                    {selectedPlan.dataprice}/mb <br />
                    {selectedPlan.title.toLowerCase().includes('just data')
                      ? '(Data)'
                      : '(Data, Voice, SMS)'}
                    <br />
                  </p>
                </div>
              )}
              <div className="form-group invalid-feedback">
                {this.state.changePlanError}
              </div>
              {this.state.samePlanError && (
                <div className="form-group invalid-feedback">
                  This plan is already activated. Please select other plan.
                </div>
              )}
              <button
                type="submit"
                disabled={
                  this.state.updateLoading || this.state.isDisabledPlanUpdate
                }
                onClick={
                  this.state.isDisabledPlanUpdate
                    ? this.updateSimName.bind(this)
                    : this.activateSimPlan
                }
                className="btn btn-dark btn-lg w-100"
              >
                {this.state.updateLoading ? 'Please wait...' : 'Save'}
              </button>
            </div>
          </div>
        </ReactModal>

        {/* Can't Pause Sim Modal */}
        <ReactModal
          isOpen={this.state.isCantPauseModal}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Are you sure?"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-body">
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
              <h5 className="react-modal-title">Pause sim?</h5>
              <p className="react-modal-subtitle mt-2">
                Seems you already reactivated or switched the plan of this sim.
                If you want to pause this sim, the earliest you can pause will
                be {this.state.nextChangeDate}.
              </p>
              <div className="text-right mt-3">
                <button
                  type="button"
                  onClick={this.closeModal}
                  className="btn btn-dark w-150"
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </ReactModal>

        {/* Deactivate & Pause MODAL */}
        <ReactModal
          isOpen={this.state.isActionModal}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Are you sure?"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-body">
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
              <h5 className="react-modal-title">
                {this.state.selectedAction === 'pause'
                  ? 'Are you sure you want to pause this SIM?'
                  : 'Are you sure you want to deactivate this SIM?'}
              </h5>
              {this.state.selectedAction === 'pause' ? (
                <p className="react-modal-subtitle mt-2">
                  SIM(s) are paused immediately. Once paused, the SIM(s) will no
                  longer be connected to data, voice, or SMS service. <br />
                  <br /> You can un-pause it any time by changing the plan of
                  the SIM.
                </p>
              ) : (
                <p className="react-modal-subtitle mt-2">
                  Once this sim is deactivated, you will only have 30 days to
                  reactivate, otherwise the sim will be cancelled and the number
                  will be released.
                </p>
              )}
              <div className="text-right mt-3">
                <button
                  disabled={this.state.updateLoading}
                  type="button"
                  onClick={
                    this.state.selectedAction === 'pause'
                      ? this.pauseSim
                      : this.deactivateSim
                  }
                  className="btn btn-dark w-150"
                >
                  {this.state.updateLoading
                    ? 'Please wait...'
                    : "Yes, I'm sure"}
                </button>
              </div>
            </div>
          </div>
        </ReactModal>

        {/* Set Sim Name MODAL */}
        <ReactModal
          isOpen={this.state.isUpdateSimModal}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Set SIMs Name"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">
                {localStorage.getItem('prevTab') === 'fixed'
                  ? 'Set Device Name'
                  : 'Set SIM Name'}
              </h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="form-group material-textfield">
                <input
                  type="text"
                  className="form-control form-control-lg material-textfield-input"
                  name="newSimName"
                  value={this.state.newSimName}
                  onChange={(e) =>
                    this.setState({
                      newSimName: e.target.value,
                      requireName: false,
                      newSimNameSize: false,
                    })
                  }
                  required
                />
                <label className="material-textfield-label">
                  {localStorage.getItem('prevTab') === 'fixed'
                    ? 'Device Name'
                    : 'SIM Name'}
                </label>
                {this.state.requireName && (
                  <div className="invalid-feedback">
                    {localStorage.getItem('prevTab') === 'fixed'
                      ? 'Device Name is required'
                      : 'SIM Name is required'}
                  </div>
                )}
                {this.state.newSimNameSize && (
                  <div className="invalid-feedback">
                    Please enter at least three characters to save.
                  </div>
                )}
              </div>
              <button
                disabled={this.state.updateLoading}
                type="button"
                onClick={() => this.updateSimName('simname', 'newSimName')}
                className="btn btn-dark btn-lg w-100"
              >
                {this.state.updateLoading ? 'Please wait...' : 'Save'}
              </button>
            </div>
          </div>
        </ReactModal>

        {/* Set Sim Name MODAL */}
        <ReactModal
          isOpen={this.state.isUpdateZipcode}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Update Zipcode"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Update Zipcode </h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="form-group material-textfield">
                <input
                  type="text"
                  className="form-control form-control-lg material-textfield-input"
                  name="newSimName"
                  value={this.state.newZipcode}
                  onChange={(e) =>
                    this.setState({
                      newZipcode: e.target.value,
                      requireName: false,
                      errorMessage: '',
                    })
                  }
                  required
                />
                <label className="material-textfield-label">Zipcode</label>
                {this.state.requireName && (
                  <div className="invalid-feedback">Zipcode is required</div>
                )}
                {this.state.newSimNameSize && (
                  <div className="invalid-feedback">
                    Please enter at least three characters to save.
                  </div>
                )}
                {this.state.errorMessage && (
                  <div className="invalid-feedback">
                    {this.state.errorMessage}
                  </div>
                )}
              </div>
              <button
                disabled={this.state.updateLoading}
                type="button"
                onClick={() => this.updateSimName('zipcode', 'newZipcode')}
                className="btn btn-dark btn-lg w-100"
              >
                {this.state.updateLoading ? 'Please wait...' : 'Save'}
              </button>
            </div>
          </div>
        </ReactModal>
        {/* CREATE TAG MODAL */}
        <ReactModal
          isOpen={this.state.isCreateTagModal}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Create tags"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered modal-create-tag"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Add Tag </h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="form-group material-textfield">
                <Select
                  autoFocus
                  mode="tags"
                  size="large"
                  bordered={false}
                  defaultValue={selectedListTags}
                  className="multiple__tags__textfield"
                  dropdownClassName="multiple__tags__textfield__overlay"
                  style={{ width: '100%' }}
                  placeholder="Tag Name"
                  onChange={this.handleTagChange}
                >
                  {_.size(this.state.filterTags) > 0 &&
                    this.state.filterTags.map((tag, index) => {
                      return (
                        <Option key={index} value={tag}>
                          {tag}
                        </Option>
                      );
                    })}
                </Select>
                {this.state.newTagSize && (
                  <div className="invalid-feedback">
                    Please enter at least three characters to save.
                  </div>
                )}
              </div>
              <button
                disabled={
                  this.state.updateLoading ||
                  this.state.newTagSize ||
                  !this.state.isTagChanged
                }
                type="button"
                onClick={this.saveTag.bind(this)}
                className="btn btn-dark btn-lg w-100"
              >
                {this.state.updateLoading ? 'Please wait...' : 'Save'}
              </button>
            </div>
          </div>
        </ReactModal>

        {/* Open thank You modal */}
        <ReactModal
          isOpen={this.state.openThankYouModal}
          onRequestClose={() => this.setState({ openThankYouModal: false })}
          ariaHideApp={false}
          contentLabel="Thank You - Info"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-body">
              <h5 className="react-modal-title react-modal-title-md">
                {this.state.titleText}
              </h5>
              <p className="react-modal-subtitle mt-2">
                {this.state.descriptionText}
              </p>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={() =>
                  this.setState({
                    openThankYouModal: false,
                    descriptionText: '',
                  })
                }
              >
                <CloseIcon />
              </button>
              <div className="text-right mt-3">
                <button
                  onClick={() =>
                    this.setState({
                      openThankYouModal: false,
                      descriptionText: '',
                    })
                  }
                  className="btn btn-dark w-150"
                >
                  {'OK'}
                </button>
              </div>
            </div>
          </div>
        </ReactModal>

        {/* Update Port-In Request Modal */}
        <ReactModal
          isOpen={this.state.updatePortInRequestModal}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="PORT SIM"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Update Port-In Request</h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="form-group material-textfield">
                <InputMask
                  guide={false}
                  type="text"
                  keepCharPositions={false}
                  mask={phoneNumberMask}
                  className="form-control form-control-lg material-textfield-input"
                  name="portInPhoneNumber"
                  value={phoneNumberPortIn}
                  onChange={(e) =>
                    this.setState({
                      phoneNumberPortIn: e.target.value,
                      requirePortInPhoneNumber: false,
                    })
                  }
                  required
                />
                <label className="material-textfield-label">
                  Port-in Phone Number*
                </label>
                {this.state.requirePortInPhoneNumber && (
                  <div className="invalid-feedback">
                    Port-in number is required
                  </div>
                )}
              </div>

              <div className="form-group material-textfield">
                <select
                  className="form-control form-control-lg custom-select material-textfield-input"
                  value={carrierPortIn}
                  onChange={(e) =>
                    this.setState({
                      carrierPortIn: e.target.value,
                      carrierError: false,
                    })
                  }
                >
                  <option>Select</option>
                  <option value={'Verizon'}>Verizon</option>
                  <option value={'Sprint'}>Sprint</option>
                  <option value={'AT&T'}>AT&T</option>
                  <option value={'T-Mobile'}>T-Mobile</option>
                  <option value={'Other'}>Other</option>
                </select>
                <label className="material-textfield-label">
                  Select Carrier*
                </label>
                {this.state.carrierError && (
                  <div className="invalid-feedback">Carrier is required</div>
                )}
              </div>

              <div className="form-group material-textfield">
                <input
                  type="text"
                  className="form-control form-control-lg material-textfield-input"
                  name="carrierACNumber"
                  value={carrierACNumberPortIn}
                  onChange={(e) => this.restrictSpecialCharacters(e)}
                  required
                />
                <label className="material-textfield-label">
                  Carrier Account Number*
                </label>
                {this.state.requireCarrierACNumber && (
                  <div className="invalid-feedback">
                    Carrier account number is required
                  </div>
                )}
              </div>

              <div className="form-group material-textfield">
                <input
                  type="text"
                  className="form-control form-control-lg material-textfield-input"
                  name="carrierAssignPassword"
                  value={carrierAssignPasswordportIn}
                  onChange={(e) => this.restrictSpecialCharacters(e)}
                  required
                />
                <label className="material-textfield-label">
                  Carrier Assigned Password*
                </label>
                {this.state.requireCarrierAssignPassword && (
                  <div className="invalid-feedback">
                    Carrier assigned password is required
                  </div>
                )}
              </div>
              <div className="form-group material-textfield">
                <input
                  type="text"
                  className="form-control form-control-lg material-textfield-input"
                  name="name"
                  value={name}
                  onChange={(e) =>
                    this.setState({
                      name: e.target.value,
                    })
                  }
                  required
                />
                <label className="material-textfield-label">Name</label>
                {this.state.nameError && (
                  <div className="invalid-feedback">
                    Name should not be longer than 25 characters.
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group material-textfield">
                    <PlacesAutocomplete
                      type="text"
                      name="street1"
                      value={street1}
                      className="form-control form-control-lg material-textfield-input"
                      handleAddressChange={({ address, city, state, zip }) =>
                        this.setState({
                          street1: address,
                          city,
                          state,
                          zip: zip,
                        })
                      }
                      setValue={(street1) => this.setState({ street1 })}
                      address={street1}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group material-textfield">
                    <input
                      type="text"
                      className="form-control form-control-lg material-textfield-input"
                      name="street2"
                      value={street2}
                      onChange={(e) =>
                        this.setState({
                          street2: e.target.value,
                          street2Error: false,
                        })
                      }
                    />
                    <label className="material-textfield-label">
                      Address Line 2
                    </label>
                    {this.state.street2Error && (
                      <div className="invalid-feedback">
                        Address Line 2 is required.
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group material-textfield">
                    <input
                      type="text"
                      className="form-control form-control-lg material-textfield-input"
                      name="city"
                      value={city}
                      onChange={(e) =>
                        this.setState({
                          city: e.target.value,
                          cityError: false,
                        })
                      }
                    />
                    <label className="material-textfield-label">City</label>
                    {this.state.cityError && (
                      <div className="invalid-feedback">City is required.</div>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group material-textfield">
                    <input
                      type="text"
                      className="form-control form-control-lg material-textfield-input"
                      name="state"
                      value={state}
                      onChange={(e) =>
                        this.setState({
                          state: e.target.value,
                          stateError: false,
                        })
                      }
                    />
                    <label className="material-textfield-label">State</label>
                    {this.state.stateError && (
                      <div className="invalid-feedback">State is required.</div>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group material-textfield">
                    <input
                      type="text"
                      className="form-control form-control-lg material-textfield-input"
                      name="zip"
                      value={zip}
                      onChange={(e) =>
                        this.setState({
                          zip: e.target.value,
                          zipError: false,
                        })
                      }
                    />
                    <label className="material-textfield-label">Zipcode</label>
                    {this.state.zipError && (
                      <div className="invalid-feedback">
                        Zip code is required.
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group material-textfield">
                    <input
                      type="text"
                      className="form-control form-control-lg material-textfield-input"
                      name="zipcodePortIn"
                      value={zipcodePortIn}
                      maxLength="5"
                      onChange={(e) =>
                        this.setState({
                          zipcodePortIn: e.target.value,
                          requireZipcodePortIn: false,
                          zipcodeErrorMessage: '',
                        })
                      }
                      required
                    />
                    <label className="material-textfield-label">
                      Zipcode of Port-In Phone Number*
                    </label>
                    {this.state.requireZipcodePortIn && (
                      <div className="invalid-feedback">
                        Zipcode of port-in phone number is required
                      </div>
                    )}
                    {this.state.zipcodeErrorMessage && (
                      <div className="invalid-feedback">
                        {this.state.zipcodeErrorMessage}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group material-textfield">
                    <input
                      type="text"
                      className="form-control form-control-lg material-textfield-input"
                      name="zip4"
                      value={this.state.zip4}
                      maxLength="5"
                      onChange={(e) => this.setState({ zip4: e.target.value })}
                      required
                    />
                    <label className="material-textfield-label">
                      Zipcode 4
                    </label>
                  </div>
                </div>
              </div>

              <div className="form-group material-textfield">
                <InputMask
                  guide={false}
                  type="text"
                  keepCharPositions={false}
                  mask={phoneNumberMask}
                  className="form-control form-control-lg material-textfield-input"
                  name="altContactNumber"
                  value={alternateContactPortIn}
                  onChange={(e) =>
                    this.setState({
                      alternateContactPortIn: e.target.value,
                      requireAlternateContactPortIn: false,
                    })
                  }
                  required
                />
                <label className="material-textfield-label">
                  Alternate Contact Number*
                </label>
                {this.state.requireAlternateContactPortIn && (
                  <div className="invalid-feedback">
                    Alternate contact number is required
                  </div>
                )}
                <div style={{ color: '#979797' }}>
                  We need this in case we need to contact you during the port in
                  process.
                </div>
              </div>

              <div className="form-group material-textfield">
                <select
                  className="form-control form-control-lg custom-select material-textfield-input"
                  value={selectedSimPlanPortIn}
                  onChange={this.managePortInPlanChange}
                >
                  <option value="0">Select Plan</option>
                  {_.size(plansDataPortIn) > 0 &&
                    plansDataPortIn.map((plan, index) => {
                      if (_.get(user, 'consumeOfferAPI', false)) {
                        return (
                          <option key={index} value={plan.offerId}>
                            {plan.title}
                          </option>
                        );
                      } else {
                        return (
                          <option key={index} value={plan.id}>
                            {plan.title}
                          </option>
                        );
                      }
                    })}
                </select>
                <label className="material-textfield-label">Select Plan*</label>
                {this.state.planError && (
                  <div className="invalid-feedback">Plan is required</div>
                )}
              </div>
              <div className="invalid-feedback">{this.state.errorMessage}</div>
              <button
                disabled={this.state.updatePortInLoading}
                type="button"
                onClick={this.updatePortIn}
                className="btn btn-dark btn-lg w-100"
              >
                {this.state.updatePortInLoading
                  ? 'Please wait...'
                  : 'Update Port-In Request'}
              </button>
            </div>
          </div>
        </ReactModal>

        {/* Cancel Port-In Request Modal */}
        <ReactModal
          isOpen={this.state.cancelPortInRequestModal}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Are you sure?"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-body">
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
              <h5 className="react-modal-title">Confirmation</h5>

              {simDetailData.isEsim === 0 ? (
                <p className="react-modal-subtitle mt-2">
                  Are you sure you want to cancel this port-in request? This SIM
                  will be moved to your "Available SIMs" list.
                </p>
              ) : (
                <p className="react-modal-subtitle mt-2">
                  Are you sure you want to cancel this port-in request? This sim
                  is no longer available in your account.
                </p>
              )}

              <div className="text-right">
                <button
                  className="btn btn-dark w-150"
                  onClick={this.cancelSimPortIn}
                  disabled={this.state.portInDisabled}
                >
                  {cancelPortInLoader ? 'Please wait ...' : "Yes, I'm sure"}
                </button>
              </div>
              <div className="text-left invalid-feedback">
                {this.state.errorMessage}
              </div>
            </div>
          </div>
        </ReactModal>

        {/* Wifi-calling Modal */}
        <ReactModal
          isOpen={wifiCallingModalOpen}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Wifi-Calling"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">e911 Address</h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <Formik
                enableReinitialize
                initialValues={wificallingInitialValues}
                validationSchema={wifiCallingSchema}
                onSubmit={(values, formikProps) =>
                  this.handleSubmit(values, formikProps)
                }
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setValues,
                }) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group material-textfield">
                            <PlacesAutocomplete
                              type="text"
                              name="addressline1"
                              value={(values && values.addressline1) || ''}
                              onChange={handleChange}
                              className="form-control form-control-lg material-textfield-input"
                              handleAddressChange={({
                                address,
                                city,
                                state,
                                zip,
                              }) =>
                                setValues({
                                  ...values,
                                  addressline1: address,
                                  city,
                                  state,
                                  zipcode: zip,
                                })
                              }
                              setValue={(address) =>
                                this.setState({ address }, () => {
                                  setValues({ ...values, address });
                                })
                              }
                              address={values.address}
                              isRequired={true}
                            />
                            <ErrorMessage
                              component="span"
                              name="address"
                              className="invalid-feedback d-block"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group material-textfield">
                            <input
                              type="text"
                              className="form-control form-control-lg material-textfield-input"
                              name="addressline2"
                              value={(values && values.addressline2) || ''}
                              onChange={handleChange}
                              required
                            />
                            <label className="material-textfield-label">
                              Address Line 2
                            </label>
                            <ErrorMessage
                              component="span"
                              name="addressline2"
                              className="invalid-feedback d-block"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group material-textfield">
                            <input
                              type="text"
                              className="form-control form-control-lg material-textfield-input"
                              name="city"
                              onChange={handleChange}
                              value={(values && values.city) || ''}
                              required
                            />
                            <label className="material-textfield-label">
                              City <span className="text-danger">*</span>
                            </label>
                            <ErrorMessage
                              component="span"
                              name="city"
                              className="invalid-feedback d-block"
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group material-textfield">
                            <input
                              type="text"
                              className="form-control form-control-lg material-textfield-input"
                              name="state"
                              onChange={handleChange}
                              value={(values && values.state) || ''}
                              required
                            />
                            <label className="material-textfield-label">
                              State <span className="text-danger">*</span>
                            </label>
                            <ErrorMessage
                              component="span"
                              name="state"
                              className="invalid-feedback d-block"
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group material-textfield">
                            <input
                              type="text"
                              className="form-control form-control-lg material-textfield-input"
                              name="zipcode"
                              onChange={handleChange}
                              value={(values && values.zipcode) || ''}
                              required
                            />
                            <label className="material-textfield-label">
                              Zip <span className="text-danger">*</span>
                            </label>
                            <ErrorMessage
                              component="span"
                              name="zipcode"
                              className="invalid-feedback d-block"
                            />
                          </div>
                        </div>
                      </div>
                      {errorMessage && (
                        <div className="invalid-feedback d-block text-center mb-3">
                          {errorMessage}
                        </div>
                      )}
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="btn btn-dark btn-lg w-100"
                      >
                        {isSubmitting ? 'Please wait...' : 'Submit'}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </ReactModal>

        {/* Admin Override functionality modal */}
        <ReactModal
          isOpen={this.state.adminOverrrideModal}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Admin Override"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Admin Override</h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body p-0">
              <div className="table-admin-overide-responsive">
                <table className="table table-admin-overide">
                  <thead>
                    <tr>
                      <th>Current</th>
                      <th>Update</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        SIM Status:{' '}
                        {parseInt(simDetailData.status) === 10
                          ? 'Active'
                          : parseInt(simDetailData.status) === 11
                          ? 'Deactivated'
                          : parseInt(simDetailData.status) === 99
                          ? 'Paused'
                          : parseInt(simDetailData.status) === 12
                          ? 'In Progress'
                          : 'Available SIMs'}
                      </td>
                      <td>
                        <div className="material-textfield">
                          <select
                            name="status"
                            className="form-control form-control-lg custom-select material-textfield-input"
                            require
                            value={simStatusNew}
                            onChange={(e) =>
                              this.setState({ simStatusNew: e.target.value })
                            }
                          >
                            <option value="">Select</option>
                            <option value="10">Active</option>
                            <option value="0">Available SIMs</option>
                            <option value="99">Paused</option>
                            <option value="11">Deactivated</option>
                          </select>
                          <label className="material-textfield-label">
                            New Sim Status
                          </label>
                          <div className="invalid-feedback">
                            {orderrideError && orderrideError.simStatusNew}
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {parseInt(simStatusNew) === 10
                          ? 'Activation Date'
                          : parseInt(simStatusNew) === 99
                          ? 'Paused Date'
                          : parseInt(simStatusNew) === 11
                          ? 'Deactivation Date'
                          : 'Activation Date'}{' '}
                        :{' '}
                        {parseInt(simStatusNew) === 10 &&
                        _.get(simDetailData, 'activationdate', '') &&
                        moment(
                          _.get(simDetailData, 'activationdate', '')
                        ).isValid() &&
                        _.get(simDetailData, 'activationdate', '') !== ''
                          ? moment(
                              _.get(simDetailData, 'activationdate', '')
                            ).format('MMMM DD, YYYY')
                          : 'N/A'}
                      </td>
                      <td>
                        <div className="form-group material-textfield">
                          <DatePicker
                            format={'MM/DD/YYYY'}
                            className="custom-datepicker"
                            dropdownClassName="custom-datepicker-overlay"
                            value={datePickerValue}
                            onChange={this.onChangeDate.bind(this)}
                            size="large"
                            showToday={true}
                            allowClear={false}
                            suffixIcon={<CalenderIcon />}
                          />
                          <label className="datepicker-textfield-label">
                            {parseInt(simStatusNew) === 10
                              ? 'Activation Date'
                              : parseInt(simStatusNew) === 99
                              ? 'Paused Date'
                              : parseInt(simStatusNew) === 11
                              ? 'Deactivation Date'
                              : 'Activation Date'}
                          </label>
                          <div className="invalid-feedback">
                            {orderrideError && orderrideError.datePickerValue}
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        ICCID:{' '}
                        {_.get(simDetailData, 'simnumber', '') &&
                        _.get(simDetailData, 'simnumber', '') !== ''
                          ? _.get(simDetailData, 'simnumber', '-')
                          : 'N/A'}
                      </td>
                      <td>
                        <div className="material-textfield">
                          <InputMask
                            guide={false}
                            type="text"
                            keepCharPositions={false}
                            mask={iccidMask}
                            className="form-control form-control-lg material-textfield-input"
                            name="iccid"
                            value={iccidNew}
                            onChange={(e) =>
                              this.setState({
                                iccidNew: e.target.value,
                                'orderrideError.iccidNew': '',
                              })
                            }
                          />
                          <label className="material-textfield-label">
                            New ICCID
                          </label>
                          <div className="invalid-feedback">
                            {orderrideError && orderrideError.iccidNew}
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        MSISDN:{' '}
                        {_.get(simDetailData, 'msisdn', '') &&
                        _.get(simDetailData, 'msisdn', '') !== ''
                          ? this.formatPhoneNumber(
                              _.get(simDetailData, 'msisdn', '-')
                            )
                          : 'N/A'}
                      </td>
                      <td>
                        <div className="material-textfield">
                          <InputMask
                            guide={false}
                            type="text"
                            keepCharPositions={false}
                            mask={phoneNumberMask}
                            className="form-control form-control-lg material-textfield-input"
                            name="msisdn"
                            value={msisdnNew}
                            onChange={(e) =>
                              this.setState({
                                msisdnNew: e.target.value,
                                orderrideError: {
                                  ...this.state.orderrideError,
                                  msisdnNew: '',
                                },
                              })
                            }
                          />
                          <label className="material-textfield-label">
                            New MSISDN
                          </label>
                          <div className="invalid-feedback">
                            {orderrideError && orderrideError.msisdnNew}
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Username:{' '}
                        {_.get(simDetailData, 'username', '') &&
                        _.get(simDetailData, 'username', '') !== ''
                          ? _.get(simDetailData, 'username', '-')
                          : 'N/A'}
                      </td>
                      <td>
                        <div className="material-textfield">
                          <input
                            type="text"
                            className="form-control form-control-lg material-textfield-input"
                            name="username"
                            required
                            value={usernameNew}
                            autoComplete="OFF"
                            onChange={(e) =>
                              this.setState({
                                usernameNew: e.target.value,
                                orderrideError: {
                                  ...this.state.orderrideError,
                                  usernameNew: '',
                                },
                              })
                            }
                          />
                          <label className="material-textfield-label">
                            New Username
                          </label>
                          <div className="invalid-feedback">
                            {orderrideError && orderrideError.usernameNew}
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Password:{' '}
                        {_.get(simDetailData, 'password', '') &&
                        _.get(simDetailData, 'password', '') !== ''
                          ? _.get(simDetailData, 'password', '-')
                          : 'N/A'}
                      </td>
                      <td>
                        <div className="material-textfield">
                          <input
                            type="password"
                            className="form-control form-control-lg material-textfield-input"
                            name="password"
                            required
                            value={passwordNew}
                            autoComplete="new-password"
                            onChange={(e) =>
                              this.setState({
                                passwordNew: e.target.value,
                                orderrideError: {
                                  ...this.state.orderrideError,
                                  passwordNew: '',
                                },
                              })
                            }
                          />
                          <label className="material-textfield-label">
                            New Password
                          </label>
                          <div className="invalid-feedback">
                            {orderrideError && orderrideError.passwordNew}
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Plan:{' '}
                        {_.get(simDetailData, 'title', '') &&
                        _.get(simDetailData, 'title', '') !== ''
                          ? _.get(simDetailData, 'title', '')
                          : 'N/A'}
                      </td>
                      <td>
                        <div className="material-textfield">
                          <select
                            className="form-control form-control-lg custom-select material-textfield-input"
                            value={planIdNew > 0 ? planIdNew : 0}
                            onChange={(e) =>
                              this.setState({
                                planIdNew: e.target.value,
                                orderrideError: {
                                  ...this.state.orderrideError,
                                  planIdNew: '',
                                },
                              })
                            }
                          >
                            <option value="0">Select Plan</option>
                            {_.size(plansDataPortIn) > 0 &&
                              plansDataPortIn.map((plan, index) => {
                                if (_.get(user, 'consumeOfferAPI', false)) {
                                  return (
                                    <option key={index} value={plan.offerId}>
                                      {plan.title}
                                    </option>
                                  );
                                } else {
                                  return (
                                    <option key={index} value={plan.id}>
                                      {plan.title}
                                    </option>
                                  );
                                }
                              })}
                          </select>
                          <label className="material-textfield-label">
                            New Plan
                          </label>
                          <div className="invalid-feedback">
                            {orderrideError && orderrideError.planIdNew}
                          </div>
                        </div>
                      </td>
                    </tr>
                    {simDetailData &&
                      parseInt(simDetailData.status) === 12 &&
                      simDetailData.requestId > 0 && (
                        <>
                          <tr>
                            <td>
                              Port-in Request Number:{' '}
                              {phoneNumberPortIn && phoneNumberPortIn !== ''
                                ? phoneNumberPortIn
                                : 'N/A'}
                            </td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>
                              Current Port-in status:-{' '}
                              {portInRequestStatus && portInRequestStatus !== ''
                                ? portInRequestStatus.toLocaleLowerCase()
                                : 'N/A'}
                            </td>
                            <td>
                              <div className="material-textfield">
                                <select
                                  name="state"
                                  className="form-control form-control-lg custom-select material-textfield-input"
                                  require
                                  value={portInStatusNew}
                                  onChange={(e) =>
                                    this.setState({
                                      portInStatusNew: e.target.value,
                                    })
                                  }
                                >
                                  <option value="">Select</option>
                                  <option value="pending">Pending</option>
                                  <option value="in-progress">
                                    In-progress
                                  </option>
                                  <option value="resolutionrequired">
                                    Resolution required
                                  </option>
                                  <option value="cancelled">Cancelled</option>
                                  <option value="failed">Failed</option>
                                  <option value="complete">Complete</option>
                                </select>
                                <label className="material-textfield-label">
                                  New Status
                                </label>
                              </div>
                            </td>
                          </tr>
                        </>
                      )}
                  </tbody>
                </table>
              </div>
              <div className="form-group invalid-feedback ml-3">
                {this.state.errorMessage}
              </div>
              <div className="admin-overide-footer text-right">
                <button
                  type="submit"
                  className="btn btn-link"
                  onClick={(e) => this.cancelOverrideModal()}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={updateLoading}
                  className="btn btn-dark w-150"
                  onClick={(e) => this.overrideFun()}
                >
                  {updateLoading ? 'Please Wait...' : 'Submit'}
                </button>
              </div>
            </div>
          </div>
        </ReactModal>

        {/* Admin Override Succcess(response) modal*/}
        <ReactModal
          isOpen={this.state.adminOverrideSuccess}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Success"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-xxs modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="connection-message-header success">
              <div>
                <SuccessIcon />
              </div>
              <h6>Success</h6>
            </div>
            <div className="react-modal-body">
              <div className="connection-message form-group">
                <p>
                  <b> Here are the fields that you have updated:</b>
                </p>
              </div>
              {overwrittenSimDetails != null &&
              overwrittenSimDetails !== undefined
                ? this.renderOverrideResult(overwrittenSimDetails)
                : ''}
              <button
                type="submit"
                className="btn btn-purple btn-lg w-100"
                onClick={this.closeModal}
              >
                Ok
              </button>
            </div>
          </div>
        </ReactModal>

        {/* Change Phone Number Modal */}
        <ReactModal
          isOpen={this.state.changePhoneNumberModal}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Change Phone Number"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">
                Change Phone Number for{' '}
                {this.formatPhoneNumber(_.get(simDetailData, 'msisdn', '-'))}
              </h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="update-plan-message">
                <p>
                  Current Phone Number -{' '}
                  {this.formatPhoneNumber(_.get(simDetailData, 'msisdn', '-'))}
                </p>
                <div className="form-group material-textfield">
                  <input
                    type="text"
                    className="form-control form-control-lg material-textfield-input"
                    name="zipcode"
                    onChange={(e) =>
                      this.setState({
                        newNumberZipcode: e.target.value,
                        changePhoneNumberError: '',
                      })
                    }
                    value={this.state.newNumberZipcode || ''}
                    required
                  />
                  <label className="material-textfield-label">
                    Zipcode for the New Phone Number{' '}
                    <span className="text-danger">*</span>
                  </label>
                </div>
              </div>
              <div className="invalid-feedback">
                {this.state.changePhoneNumberError}
              </div>
              <div className="update-plan-message text-right">
                <button
                  type="submit"
                  className="btn btn-link"
                  onClick={(e) => this.closeModal()}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={this.state.changePhoneNumberLoading}
                  onClick={(e) => this.changePhoneNumber()}
                  className="btn btn-dark w-150"
                >
                  {this.state.changePhoneNumberLoading
                    ? 'Please wait...'
                    : 'Submit'}
                </button>
              </div>
            </div>
          </div>
        </ReactModal>

        {/* Change Phone Number Succcess(response) Modal*/}
        <ReactModal
          isOpen={this.state.changePhoneNumberSuccessModal}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Success"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-xxs modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="connection-message-header success">
              <div>
                <SuccessIcon />
              </div>
              <h6>Success</h6>
            </div>
            <div className="react-modal-body">
              <div className="connection-message form-group">
                <p>
                  <b> Phone Number changed successfully.</b>
                </p>
              </div>
              <button
                type="submit"
                className="btn btn-purple btn-lg w-100"
                onClick={this.closeModal}
              >
                Ok
              </button>
            </div>
          </div>
        </ReactModal>
        <AddIldConfirmationModal
          closeModal={this.closeModal}
          updateIldIrInfo={this.updateIldIrInfo}
          {...this.state}
        />
        <AddIrConfirmationModal
          closeModal={this.closeModal}
          updateIldIrInfo={this.updateIldIrInfo}
          {...this.state}
        />
        <EligiblePlansModal {...this.state} closeModal={this.closeModal} />
        <CancelRecurringPlanModal
          {...this.state}
          closeModal={this.closeModal}
          cancelRecurringPayment={this.cancelRecurringPayment}
        />
        {/* Update voicemail status Modal */}
        <ReactModal
          isOpen={this.state.voicemailStatusUpdateModal}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Are you sure?"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">
                Are you sure you would like to{' '}
                {voiceMailStatus ? 'Disable' : 'Enable'} Voicemail?
              </h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="update-plan-message text-right">
                <button
                  type="submit"
                  className="btn btn-link"
                  onClick={(e) => this.closeModal()}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={this.state.voicemailLoading}
                  onClick={(e) => this.UpdateVoicemailStatus()}
                  className="btn btn-dark w-150"
                >
                  {this.state.voicemailLoading
                    ? 'Please wait...'
                    : "Yes, I'm Sure"}
                </button>
              </div>
            </div>
          </div>
        </ReactModal>

        {/* Unpause MODAL */}

        <ReactModal
          isOpen={this.state.isUnpauseModal}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Unpause"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">
                Unpause SIM {_.get(simDetailData, 'simnumber', '')}?{' '}
              </h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="form-group material-textfield">
                <select
                  className="form-control form-control-lg custom-select material-textfield-input"
                  id="selectPlan"
                  defaultValue={plansData.findIndex(
                    (item) => item.title === simDetailData.title
                  )}
                  onChange={this.handlePlanChange}
                >
                  <option>Select</option>
                  {_.size(plansData) > 0 &&
                    plansData.map((plan, index) => {
                      return (
                        <option key={index} value={index}>
                          {simDetailData.simtype === 'fixed'
                            ? plan.offername
                            : plan.title}
                        </option>
                      );
                    })}
                </select>
                <label
                  className="material-textfield-label"
                  htmlFor="selectPlan"
                >
                  Select Plan
                </label>
                {this.state.planError && (
                  <div className="invalid-feedback">Plan is required</div>
                )}
              </div>
              {user?.isIREnabled === 1 || user?.isILDEnabled === 1 ? (
                <div className="form-group">
                  {selectedPlan?.isILD && user?.isILDEnabled === 1 && (
                    <p className="react-modal-subtitle mt-2 d-flex align-items-center justify-content-between">
                      Turn on ILD functionality for this SIM
                      <Switch
                        checked={this.state.isILDEnabled}
                        onChange={() =>
                          this.onChangeSwitchForUnpause('isILDEnabled')
                        }
                        className="ml-3"
                      />
                    </p>
                  )}
                  {this.state.isILDEnabled && (
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <select
                            className="form-control form-control-lg custom-select material-textfield-input"
                            onChange={(e) =>
                              this.setState({
                                ildPlanValue: parseFloat(e.target.value),
                                ildPlanValueRequired: false,
                              })
                            }
                          >
                            <option value={0}>Select</option>
                            {_.size(ildPlans) > 0 &&
                              ildPlans.map((plan) => {
                                return (
                                  <option
                                    key={plan.titleValue}
                                    value={plan.titleValue}
                                  >
                                    {`${plan.titleValue} minutes`}
                                  </option>
                                );
                              })}
                          </select>
                          <label className="material-textfield-label">
                            Select Minutes{' '}
                          </label>
                          {this.state.ildPlanValueRequired && (
                            <div className="invalid-feedback">
                              Please select ILD minutes.
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <select
                            className="form-control form-control-lg custom-select material-textfield-input"
                            onChange={(e) =>
                              this.setState({
                                ildPlanType: e.target.value,
                                ildPlanTypeRequired: false,
                              })
                            }
                          >
                            <option value="">Select</option>
                            <option value="oneTime"> One Time </option>
                            <option value="recurring"> Recurring </option>
                          </select>
                          <label className="material-textfield-label">
                            Select Subscription Type{' '}
                          </label>
                          {this.state.ildPlanTypeRequired && (
                            <div className="invalid-feedback">
                              Please select ILD subscription type.
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {selectedPlan?.isIR && user?.isIREnabled === 1 && (
                    <p className="react-modal-subtitle mt-2 d-flex align-items-center justify-content-between">
                      Turn on IR functionality for this SIM
                      <Switch
                        checked={this.state.isIREnabled}
                        onChange={() =>
                          this.onChangeSwitchForUnpause('isIREnabled')
                        }
                        className="ml-3"
                      />
                    </p>
                  )}
                  {this.state.isIREnabled && (
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <select
                            className="form-control form-control-lg custom-select material-textfield-input"
                            id="selectBalance"
                            onChange={(e) =>
                              this.setState({
                                irPlanValue: parseFloat(e.target.value),
                                irPlanValueRequired: false,
                              })
                            }
                          >
                            <option value={0}>Select</option>
                            {_.size(irPlans) > 0 &&
                              irPlans.map((plan) => {
                                return (
                                  <option
                                    key={plan.titleValue}
                                    value={plan.titleValue}
                                  >
                                    {`$${plan.titleValue.toFixed(2)}`}
                                  </option>
                                );
                              })}
                          </select>
                          <label
                            className="material-textfield-label"
                            htmlFor="selectBalance"
                          >
                            Select balance amount{' '}
                          </label>
                          {this.state.irPlanValueRequired && (
                            <div className="invalid-feedback">
                              Please select IR balance.
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <div className="form-group material-textfield">
                            <select
                              className="form-control form-control-lg custom-select material-textfield-input"
                              onChange={(e) =>
                                this.setState({
                                  irPlanType: e.target.value,
                                  irPlanTypeRequired: false,
                                })
                              }
                            >
                              <option value="">Select</option>
                              <option value="oneTime"> One Time </option>
                              <option value="recurring"> Recurring </option>
                            </select>
                            <label className="material-textfield-label">
                              Select Subscription Type{' '}
                            </label>
                            {this.state.irPlanTypeRequired && (
                              <div className="invalid-feedback">
                                Please select IR subscription type.
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="invalid-feedback">
                    {_.get(this.state, 'unPauseSimError', '')}
                  </div>
                </div>
              ) : (
                ''
              )}
              <button
                type="submit"
                disabled={this.state.updateLoading}
                onClick={this.unPauseSim}
                className="btn btn-dark btn-lg w-100"
              >
                {this.state.updateLoading
                  ? 'Please wait...'
                  : 'Yes, unpause now'}
              </button>
            </div>
          </div>
        </ReactModal>

        {/* Convert to eSIM Modal */}
        <ReactModal
          isOpen={
            this.state.convertesimModal && this.state.isEsimAvailable === false
          }
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Swap to eSIM"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog modal-dialog-centered"
        >
          <div className="modal-content" data-testid="swapToESimModal">
            <div className="react-modal-header">
              <h5 className="react-modal-title" data-testid="swapToEsimModalHeader">Swap to eSIM</h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="form-group">
                <label className="label-text">Current SIM</label>
                <p>{_.get(simDetailData, 'simnumber', '-')}</p>
              </div>

              {/* Optional New Sim ICCID start*/}
              <div className="form-group material-textfield">
                <DebounceInput
                  data-testid="eSimOptionalICCIDInput"
                  minLength={2}
                  debounceTimeout={500}
                  className="form-control form-control-lg material-textfield-input"
                  value={searchVal}
                  onChange={this.onSearch}
                  required
                />
                <label className="material-textfield-label">
                  New SIM ICCID (optional)
                </label>
                {this.state.showSearchDropdown &&
                this.state.searchVal !== '' ? (
                  <div
                    data-testid="autocomplete-searchResult"
                    className={
                      searchData && searchData.length
                        ? 'search-wrapper-open-container swap-sim-list open'
                        : 'search-wrapper-open-container'
                    }
                  >
                    {searchData &&
                      searchData.map((d, i) => (
                        <ul key={i} className="search-wrapper-options-menu">
                          <li
                            className="search-wrapper-options-menu-item"
                            onClick={() =>
                              this.setState({
                                searchVal: d.simnumber,
                                showSearchDropdown: false,
                              })
                            }
                          >
                            <div className="search-bar-info">
                              <div className="sim-icon">
                                <SimsColorIcon />
                              </div>
                              <div className="user-info">
                                <div className="number">{d.simnumber}</div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      ))}
                  </div>
                ) : (
                  ''
                )}
                <div className="invalid-feedback">
                  {this.state.swapSimError}
                </div>
                <div className="form-group invalid-feedback">
                  {this.state.errorMessage}
                </div>
              </div>
              {/* Optional New Sim ICCID end*/}

              <div className="form-group">
                <label className="label-text mb-3">Check by IMEI number</label>
                <div className="form-group material-textfield stacked-label-block">
                  <input
                    type="text"
                    maxLength="17"
                    className="form-control form-control-lg material-textfield-input"
                    placeholder="Enter your phone’s 15-17 digit IMEI"
                    required
                    onChange={(e) => {
                      this.handleImeiChange(e);
                    }}
                    onFocus={() => {
                      this.setState({ isCheckCoverage: true });
                      this.setState({ isCheckDeviceCompatibility: true });
                      this.setState({ isSelectPlan: false });
                      this.setState({ isInstalleSIM: false });
                    }}
                  />
                  <label className="material-textfield-label">
                    IMEI Number
                  </label>
                  {deviceApiError !== '' ? (
                    <div className="invalid-feedback mb-2">
                      {deviceApiError}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <button
                  type="button"
                  className={
                    imeiNumber !== ''
                      ? 'btn btn-dark btn-lg w-100'
                      : 'btn btn-gray btn-lg w-100'
                  }
                  onClick={this.checkImeiNumber}
                  disabled={!(imeiNumber !== '') || this.state.imeiLoding}
                >
                  {this.state.imeiLoding
                    ? 'Please wait...'
                    : 'Check Device Compatibility'}
                </button>
              </div>
              {!this.state.eSIMCompatible &&
                this.state.deviceName !== '' &&
                this.state.isImeiSection === true && (
                  <div className="card">
                    <div className="card-body">
                      <div className="activate-esim-aleart error">
                        <span>
                          <ErrorIcon />
                        </span>{' '}
                        <span>
                          {this.state.deviceName} is not compatible with eSIM.
                          You may proceed however with swapping to an eSIM for
                          this device or cancel and swap to a physical SIM.
                        </span>
                      </div>
                      <div className="esim-activation-title">
                        Are you sure you want to continue swapping to an eSIM?
                      </div>
                      <button
                        type="button"
                        class="btn btn-dark btn-lg w-200 mr-3"
                        onClick={this.handleErrorBox}
                      >
                        Yes, Proceed
                      </button>
                      <button
                        type="button"
                        class="btn btn-dark btn-lg w-200"
                        onClick={() => {
                          this.setState({
                            convertesimModal: false,
                            eSIMCompatible: true,
                            deviceName: '',
                            imeiNumber: '',
                            brand: '',
                            model: '',
                            swapESimError: '',
                            eSimFilter: false,
                            pSimFilter: false,
                          });
                          this.props.history.push(
                            `/detail/${simDetailData.simnumber}`
                          );
                        }}
                      >
                        No, Cancel
                      </button>
                    </div>
                  </div>
                )}

              {this.state.eSIMCompatible &&
                this.state.deviceName !== '' &&
                this.state.proceed === false &&
                this.state.isImeiSection === true && (
                  <div className="activate-esim-aleart success center-text">
                    <eSimSuccessIcon /> Get excited! Your{' '}
                    {this.state.deviceName} is compatible with eSIMs!
                  </div>
                )}
              <div className="activate-sim-col-divider ">Or</div>
              <div className="form-group">
                <label className="label-text mb-3">
                  Quick check by brand and model
                </label>
                <div className="form-group material-textfield">
                  <Select
                    showSearch
                    optionFilterProp="children"
                    name="brand"
                    value={brand}
                    onChange={(e) => {
                      this.handleChangeBrand(e);
                    }}
                    required
                    onFocus={() => {
                      this.setState({ isCheckCoverage: true });
                      this.setState({ isCheckDeviceCompatibility: true });
                      this.setState({ isSelectPlan: false });
                      this.setState({ isInstalleSIM: false });

                      this.setState({ deviceApiError: '' });
                    }}
                    className="search-with-select"
                    showArrow={false}
                    dropdownClassName="search-with-select-overlay"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value="">Select</Option>
                    {brandsArray &&
                      brandsArray.map((data, i) => {
                        return (
                          <Option key={i} value={_.get(data, 'name', '')}>
                            {_.get(data, 'name', '')}
                          </Option>
                        );
                      })}
                  </Select>
                  <label className="material-textfield-label search-with-select-label">
                    Brand{' '}
                  </label>
                </div>

                <div className="form-group material-textfield">
                  <select
                    className="form-control form-control-lg custom-select material-textfield-input"
                    name="model"
                    value={this.state.model}
                    onChange={(e) => {
                      this.handleChangeModel(e);
                    }}
                    required
                  >
                    <option value="select">Select</option>
                    {this.state.modelArray &&
                      this.state.modelArray.map((data, i) => {
                        return (
                          <option key={i} value={_.get(data, 'name', '')}>
                            {_.get(data, 'name', '')}
                          </option>
                        );
                      })}
                  </select>
                  <label className="material-textfield-label">Model </label>
                </div>
                <button
                  type="button"
                  className={
                    this.state.model !== '' && this.state.brand !== ''
                      ? 'btn btn-dark btn-lg w-100'
                      : 'btn btn-gray btn-lg w-100'
                  }
                  onClick={this.submitDeviceDetail}
                  disabled={
                    !(this.state.model !== '' && this.state.brand !== '') ||
                    this.state.brandLoading
                  }
                >
                  {this.state.brandLoading
                    ? 'Please wait...'
                    : 'Check Device Compatibility'}
                </button>
              </div>
              {!this.state.eSIMCompatible &&
                this.state.deviceName !== '' &&
                this.state.isCheckByBrandModal === true && (
                  <div className="card">
                    <div className="card-body">
                      <div className="activate-esim-aleart error">
                        <span>
                          <ErrorIcon />
                        </span>{' '}
                        <span>
                          {this.state.deviceName} is not compatible with eSIM.
                          You may proceed however with swapping to an eSIM for
                          this device or cancel and swap to a physical SIM.
                        </span>
                      </div>
                      <div className="esim-activation-title">
                        Are you sure you want to continue swapping to an eSIM?
                      </div>
                      <button
                        type="button"
                        class="btn btn-dark btn-lg w-200 mr-3"
                        onClick={this.handleErrorBox}
                      >
                        Yes, Proceed
                      </button>
                      <button
                        type="button"
                        class="btn btn-dark btn-lg w-200"
                        onClick={() => {
                          this.setState({
                            convertesimModal: false,
                            eSIMCompatible: true,
                            deviceName: '',
                            imeiNumber: '',
                            brand: '',
                            model: '',
                            swapESimError: '',
                            eSimFilter: false,
                            pSimFilter: false,
                          });
                          this.props.history.push(
                            `/detail/${simDetailData.simnumber}`
                          );
                        }}
                      >
                        No, Cancel
                      </button>
                    </div>
                  </div>
                )}

              {this.state.eSIMCompatible &&
                this.state.deviceName !== '' &&
                this.state.proceed === false &&
                this.state.isCheckByBrandModal === true && (
                  <div className="activate-esim-aleart success center-text">
                    <eSimSuccessIcon /> Get excited! Your{' '}
                    {this.state.deviceName} is compatible with eSIMs!
                  </div>
                )}

              <div className="form-group mb-4">
                <label className="label-text">Current Plan</label>
                {_.get(simDetailData, 'simtype', '') === 'spr' ? (
                  <p className="text-desc">
                    {_.get(simDetailData, 'title', '') &&
                    _.get(simDetailData, 'title', '') !== ''
                      ? _.get(simDetailData, 'title', 'N/A')
                      : _.get(activePortInReqData, 'title', '') &&
                        _.get(activePortInReqData, 'title', '') !== ''
                      ? _.get(activePortInReqData, 'title', '-')
                      : 'N/A'}
                    <br />
                    {!_.isEmpty(simDetailData) &&
                      (simDetailData.title
                        .toLowerCase()
                        .includes('just data') ||
                        simDetailData.title
                          .toLowerCase()
                          .includes('everything')) &&
                      '$' +
                        simDetailData.monthlyprice.toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                        }) +
                        ' per 12 Months = ' +
                        simDetailData.unit +
                        ' units'}
                  </p>
                ) : (
                  <p className="text-desc">
                    {_.get(simDetailData, 'offername', '') &&
                    _.get(simDetailData, 'offername', '') !== ''
                      ? _.get(simDetailData, 'offername', 'N/A')
                      : _.get(activePortInReqData, 'title', '') &&
                        _.get(activePortInReqData, 'title', '') !== ''
                      ? _.get(activePortInReqData, 'title', '-')
                      : 'N/A'}
                    <br />
                    {!_.isEmpty(simDetailData) &&
                      (simDetailData.title
                        .toLowerCase()
                        .includes('just data') ||
                        simDetailData.title
                          .toLowerCase()
                          .includes('everything')) &&
                      '$' +
                        simDetailData.monthlyprice.toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                        }) +
                        ' per 12 Months = ' +
                        simDetailData.unit +
                        ' units'}
                  </p>
                )}
                <button
                  type="button"
                  className="btn btn-purple btn-lg w-100"
                  disabled={
                    this.state.updateEsimLoading || this.state.enableQRCodeModal
                  }
                  onClick={this.swapESim}
                >
                  {this.state.updateEsimLoading
                    ? 'Please wait...'
                    : 'Save & Swap'}
                </button>
                <div className="invalid-feedback">
                  {this.state.swapESimError}
                </div>
              </div>
              {this.state.enableQRCodeModal && (
                <div>
                  <div className="convert-esim-modal-wrapper">
                    <div className="install-by-qr-code is-background">
                      <div className="install-by-qr-code-header">
                        <h5 className="install-by-qr-code-title mb-3">
                          Install by eSIM by scanning code
                        </h5>
                        <button className="btn btn-recommended">
                          Recommended
                        </button>
                      </div>
                      <div className="install-by-qr-code-body text-center">
                        {this.state.eSimObj.QRActivationCode && (
                          <QR value={this.state.eSimObj.QRActivationCode} />
                        )}
                      </div>
                    </div>
                    <div className="activate-sim-col-divider ">Or</div>
                    <div className="card mb-0 manually-install-esim">
                      <div className="manually-install-esim-header">
                        <h5 className="manually-install-esim-title">
                          Manually install eSIM
                        </h5>
                      </div>
                      <div className="manually-install-esim-body">
                        <ul className="manually-install-esim-list">
                          <li>
                            <h4>SM-DP+ Address</h4>
                            <p>{this.state.eSimObj.SMDPAddress}</p>
                          </li>
                          <li>
                            <h4>Activation code</h4>
                            <p>{this.state.eSimObj.ActivationCode}</p>
                          </li>
                          <li>
                            <h4>eSim QR Code</h4>
                            <p>{this.state.eSimObj.QRActivationCode}</p>
                          </li>
                          <li>
                            <h4>MSISDN</h4>
                            <p>
                              {this.formatPhoneNumber(
                                _.get(this.state.eSimObj, 'MSISDN', '-')
                              )}
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div
                    className="e-sim-donwload"
                    onClick={(e) => {
                      handleEsimPdfDownload(
                        this.state.eSimObj.iccid,
                        this.props.downloadEsimPDF,
                        this.props.generatePDF
                      );
                    }}
                  >
                    <span className="download-pdf download-pdf-sm">
                      <DownloadIcon />
                      Download PDF
                    </span>
                  </div>
                  <div className="">
                    <button
                      type="button"
                      className="btn btn-lg btn-dark w-100"
                      onClick={() => {
                        this.setState({
                          convertesimModal: false,
                          swapSimConfirmingModalOpen: false,
                        });
                        this.props.history.push(
                          `/detail/${this.state.eSimObj.iccid}`
                        );
                        this.setState({ isLoading: true });
                        this.getSimDetails();
                      }}
                    >
                      Done
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </ReactModal>

        {/* APN Settings Drawer */}
        <Drawer
          placement="right"
          width="680px"
          getContainer={false}
          closable={false}
          onClose={this.onClose}
          className="drawer-main"
          visible={this.state.viewApnDrawer}
        >
          <div className="drawer-header">
            <div className="drawer-title">APN Settings</div>
            <button className="btn btn-link" onClick={this.onClose}>
              <CloseIcon />
            </button>
          </div>
          <div className="drawer-body">
            <h4 className="apn-title">
              How to access the APN settings on your Android:
            </h4>
            <ul className="apn-list">
              <li>1. Ensure you are connected to the wifi.</li>
              <li>2. Select settings.</li>
              <li>3. Search Mobile Network.</li>
              <li>4. Select Access Point Names.</li>
              <li>5. Select the Add (+) at the top of the screen.</li>
              <li>6. Input the settings.</li>
              <li>7. Save the settings.</li>
              <li>
                8. Ensure that the new Access point is selected as the preferred
                APN.
              </li>
              <li>9. Power cycle the device.</li>
            </ul>
            <h4 className="apn-title">APN Setup values:</h4>
            <ul className="apn-list">
              <li>1. Name: Wireless</li>
              <li>2. APN: wholesale</li>
              <li>3. Proxy: {'<leave blank>'}</li>
              <li>4. Port: {'<leave blank>'}</li>
              <li>5. Username: {'<leave blank>'}</li>
              <li>6. Password: {'<leave blank>'}</li>
              <li>7. Server: {'<leave blank>'}</li>
              <li>
                8. MMSC:{' '}
                <a
                  href="http://wholesale.mmsmvno.com/mms/wapenc"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  http://wholesale.mmsmvno.com/mms/wapenc
                </a>
              </li>
              <li>9. MMS Proxy: {'<leave blank>'}</li>
              <li>10. MMS Port: 8080</li>
              <li>11. MCC: 310</li>
              <li>12. MNC: 260</li>
              <li>13. Authentication Type: {'<leave blank>'}</li>
              <li>14. APN Type: default,supl,mms,ia</li>
              <li>15. APN Protocol: IPv4 / IPv6</li>
              <li>16. APN Roaming Protocol: IPv4</li>
            </ul>
          </div>
        </Drawer>

        {/* MSISDN Fail Modal */}
        <ReactModal
          isOpen={changePhoneNumberErrorModal}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Success"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-xxs modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="connection-message-header failed">
              <div>
                <FailedIcon />
              </div>
              <h6>Failed</h6>
            </div>
            <div className="react-modal-body">
              <div className="connection-message form-group">
                <p>Unable to complete your request please file a ticket.</p>
              </div>
              <button
                type="submit"
                className="btn btn-purple btn-lg w-100"
                onClick={this.closeModal}
              >
                Ok
              </button>
            </div>
          </div>
        </ReactModal>

        {/* Esim port-In Fails modal */}
        <ReactModal
          isOpen={this.state.portInFailsPopup}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Esim Port-In Fails"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-body">
              <h5 className="react-modal-title react-modal-title-md">
                Esim Port-In Fails
              </h5>
              <p className="react-modal-subtitle mt-3">
                Port-in failed due to {this.state.errorMessage}. This eSIM will
                no longer be available to activate. Please start a new
                activation with port-in to try again.
              </p>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={() => {
                  this.closeModal();
                  this.setState({ errorMessage: '' });
                }}
              >
                <CloseIcon />
              </button>
              <div className="text-right mt-3">
                <button
                  className="btn btn-dark w-150"
                  onClick={() => {
                    this.closeModal();
                    this.setState({ errorMessage: '' });
                  }}
                >
                  {'OK'}
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}
