import React from 'react';
import ReactDOM from 'react-dom';
import App from './App/container';
import { CookiesProvider } from 'react-cookie';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import store from './store';
import './index.scss';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const history = createBrowserHistory();
const queryClient = new QueryClient();

ReactDOM.render(
  <Provider store={store}>
    <CookiesProvider>
      <QueryClientProvider client={queryClient}>
        <Router history={history}>
          <App />
        </Router>
      </QueryClientProvider>
    </CookiesProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
